import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { AxiosError } from 'axios';
import { message, Typography } from 'antd';
import SuccessNotificationProvider from '@marrlab-app-shared/components/successNotification';

import CreateHumanTemplateModal from '../../components/createHumanTemplateModal';
import { CreateHumanTemplateParams, HumanTemplate } from '../../../../api/humanTemplates/types';
import { createHumanTemplate, editHumanTemplate } from '../../../../api/humanTemplates';

interface CreateHumanTemplateContextType {
  open: () => void;
  openOnEdit: (humanTemplate: HumanTemplate) => void;
}

const initialState: CreateHumanTemplateContextType = {
  open: () => {
  },
  openOnEdit: () => {
  },
};

const CreateHumanTemplateContext = createContext<CreateHumanTemplateContextType>(initialState);

interface CreateHumanTemplateContextProps extends PropsWithChildren {
  fetchHumanTemplates: () => Promise<void>;
}

const CreateHumanTemplateProvider = ({
  children,
  fetchHumanTemplates,
}: CreateHumanTemplateContextProps) => {
  const successNotification = SuccessNotificationProvider.useSuccessNotification();

  const [isOpen, setIsOpen] = useState(false);
  const [humanTemplateToEdit, setHumanTemplateToEdit] = useState<HumanTemplate | null>(null);

  const close = useCallback(() => {
    setIsOpen(false);
    setHumanTemplateToEdit(null);
  }, []);

  const open = useCallback(() => {
    setIsOpen(true);
  }, []);

  const openOnEdit = useCallback((humanTemplate: HumanTemplate) => {
    setHumanTemplateToEdit(humanTemplate);
    setIsOpen(true);
  }, []);

  const onConfirm = useCallback(async (params: CreateHumanTemplateParams) => {
    try {
      const response = humanTemplateToEdit
        ? await editHumanTemplate(humanTemplateToEdit.templateId, params)
        : await createHumanTemplate(params);

      successNotification.open({
        key: response.data.templateId.toString(),
        title: humanTemplateToEdit ? 'Changes saved' : 'Human template created successfully',
        message: (
          <>
            <Typography.Text strong>Human template ID: </Typography.Text>
            <Typography.Text>{response.data.templateId}</Typography.Text>
            <br />
            <Typography.Text strong>Human template name: </Typography.Text>
            <Typography.Text>{response.data.templateName}</Typography.Text>
          </>
        ),
      });

      await fetchHumanTemplates();

      close();
    } catch (error) {
      if (error instanceof AxiosError) {
        message.open({ content: error.response?.data?.message, type: 'error', duration: 10 });
      }
    }
  }, [close, humanTemplateToEdit, fetchHumanTemplates, successNotification]);

  const value: CreateHumanTemplateContextType = useMemo(
    () => ({
      open,
      openOnEdit,
    }),
    [open, openOnEdit],
  );

  return (
    <CreateHumanTemplateContext.Provider value={value}>
      {children}
      <CreateHumanTemplateModal
        isOpen={isOpen}
        initialHumanTemplate={humanTemplateToEdit}
        onCancel={close}
        onConfirm={onConfirm}
      />
    </CreateHumanTemplateContext.Provider>
  );
};

CreateHumanTemplateProvider.useCreateHumanTemplate = () => useContext(CreateHumanTemplateContext);

export default CreateHumanTemplateProvider;
