import { useCallback } from 'react';
import { Button, Tooltip } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import CreateAsrConfigProvider from '../../providers/createAsrConfigProvider';
import { AsrConfig } from '../../../../api/asrConfigs/types';

interface EditAsrConfigActionProps {
  asrConfig: AsrConfig;
}

const EditAsrConfigAction = ({ asrConfig }: EditAsrConfigActionProps) => {
  const createAsrConfig = CreateAsrConfigProvider.useCreateAsrConfig();

  const open = useCallback(() => {
    createAsrConfig.openOnEdit(asrConfig);
  }, [createAsrConfig, asrConfig]);

  return (
    <Tooltip title="Edit ASR config">
      <Button
        type="primary"
        ghost
        icon={<EditOutlined />}
        size="middle"
        onClick={open}
      />
    </Tooltip>
  );
};

export default EditAsrConfigAction;
