import { ChangeEvent, useCallback } from 'react';
import { Form, Input } from 'antd';

import { FieldProps } from '../../types';

const PronunciationDictionaryField = ({
  state,
  disabled,
  error,
  onUpdate,
}: FieldProps) => {
  const onChange = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
    onUpdate('pronunciationDictionary', event.target.value);
  }, [onUpdate]);

  return (
    <Form.Item
      className="field-with-info-tooltip"
      label="Pronunciation dictionary"
      tooltip="A list of words / phrases and their pronunciation to be used to guide the audio synthesize for consistent pronunciation. Currently only supported for English & 11labs voices. Set to null to remove pronunciation dictionary from this agent."
      validateStatus={error ? 'error' : undefined}
      help={error}
    >
      <Input.TextArea
        placeholder='[{"word": "actually", "alphabet": "ipa", "phoneme": "ˈæktʃuəli"}]'
        value={state.pronunciationDictionary}
        onChange={onChange}
        disabled={disabled}
        autoSize={{ minRows: 2, maxRows: 8 }}
      />
    </Form.Item>
  );
};

export default PronunciationDictionaryField;
