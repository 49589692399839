import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { TableColumnsType } from 'antd';

import { mapColumnsToColumnsSettings } from '../utils/mappers';
import { ColumnSetting } from '../types';
import { AgentProvider } from '../../../../../api/agentProviders/types';

interface UseColumnsModalStateArgs {
  isOpen: boolean;
  initialColumns: TableColumnsType<AgentProvider>;
}

const useColumnsModalState = ({
  isOpen,
  initialColumns,
}: UseColumnsModalStateArgs) => {
  const [columnsSettings, setColumnsSettings] = useState<ColumnSetting[]>([]);

  const allVisible = useMemo(() => (
    columnsSettings.every((column) => column.visible)
  ), [columnsSettings]);

  useEffect(() => {
    if (isOpen) {
      setColumnsSettings(mapColumnsToColumnsSettings(initialColumns));
    } else {
      setColumnsSettings([]);
    }
  }, [initialColumns, isOpen]);

  const onVisibilityUpdate = useCallback((key: string, visible: boolean) => {
    setColumnsSettings((state) => state.map((column) => {
      if (column.key === key) {
        return { ...column, visible };
      }
      return column;
    }));
  }, []);

  const onAllVisibleUpdate = useCallback(() => {
    setColumnsSettings((state) => state.map((column) => ({
      ...column,
      visible: !allVisible,
    })));
  }, [allVisible]);

  return {
    allVisible,
    columnsSettings,
    onVisibilityUpdate,
    onAllVisibleUpdate,
  };
};

export default useColumnsModalState;
