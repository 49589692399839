import { ChangeEvent, useCallback } from 'react';
import { Form, Input } from 'antd';

import { FieldProps } from '../../types';

const DataModelConfigField = ({
  state,
  disabled,
  error,
  onUpdate,
}: FieldProps) => {
  const onChange = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
    onUpdate('dataModelConfig', event.target.value);
  }, [onUpdate]);

  return (
    <Form.Item
      className="field-with-info-tooltip"
      label="Data model config"
      tooltip="Accepts only valid json object"
      validateStatus={error ? 'error' : undefined}
      help={error}
    >
      <Input.TextArea
        value={state.dataModelConfig}
        onChange={onChange}
        disabled={disabled}
        autoSize={{ minRows: 2, maxRows: 8 }}
      />
    </Form.Item>
  );
};

export default DataModelConfigField;
