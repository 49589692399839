import axios from 'axios';
import { Page } from '@marrlab-app-shared/types';

import {
  CreatePersonaParams,
  Persona,
  GetPersonasParams,
} from './types';
import config from '../../utils/config';

export const getPersonas = async (params: GetPersonasParams) => (
  axios.get<Page<Persona>>(`${config.CALLS_HISTORY_API_BASE_URL}/api/personas/`, { params })
);

export const getPersonaById = async (personaId: string) => (
  axios.get<Persona>(`${config.CALLS_HISTORY_API_BASE_URL}/api/personas/${personaId}`)
);

export const createPersona = async (params: CreatePersonaParams) => (
  axios.post<Persona>(`${config.CALLS_HISTORY_API_BASE_URL}/api/personas`, params)
);

export const editPersona = async (id: number, params: CreatePersonaParams) => (
  axios.put<Persona>(`${config.CALLS_HISTORY_API_BASE_URL}/api/personas/${id}`, params)
);

export const deletePersona = async (id: number) => (
  axios.delete<void>(`${config.CALLS_HISTORY_API_BASE_URL}/api/personas/${id}`)
);
