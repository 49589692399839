import { Flex } from 'antd';

import EditReferenceDataFieldAction from '../editReferenceDataFieldAction';
import DeleteReferenceDataFieldAction from '../deleteReferenceDataFieldAction';
import { ReferenceDataField } from '../../../../api/referenceDataFields/types';

interface ActionsCellProps {
  referenceDataField: ReferenceDataField;
}

const ActionsCell = ({ referenceDataField }: ActionsCellProps) => (
  <Flex gap={8}>
    <EditReferenceDataFieldAction referenceDataField={referenceDataField} />
    <DeleteReferenceDataFieldAction referenceDataField={referenceDataField} />
  </Flex>
);

export default ActionsCell;
