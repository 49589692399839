import { Button, Empty, Typography } from 'antd';

import CreateAgentProvider from '../../../agentProviders/providers/createAgentProvider';
import { Persona } from '../../../../api/personas/types';
import { AgentType } from '../../../agentProviders/components/createAgentModal/types';
import { fillTemplateWithPlaceholders } from '../../utils/persona';
import usePlaceholdersList from '../../../personaManagement/hooks/usePlaceholdersList';

interface NoAgentStateProps {
  persona: Persona | null;
}

const NoAgentState = ({ persona }: NoAgentStateProps) => {
  const { open } = CreateAgentProvider.useCreateAgent();

  const {
    loading,
    placeholders,
  } = usePlaceholdersList({ templateId: persona?.templateId ?? null });

  const openHumanAgentCreationModal = () => {
    if (loading) {
      return;
    }

    if (!persona) {
      return;
    }

    open({
      providerType: 'azure',
      apiVersion: '2024-02-15-preview',
      class: 'AzureOpenAI',
      agentType: AgentType.humanAgent,
      personaId: persona.personaId,
      campaignId: persona.campaign?.campaignId,
      llmPrompt: fillTemplateWithPlaceholders({
        placeholderValues: persona.placeholderValues,
        template: persona.template?.template,
        placeholders,
      }),
      orchestratorProviderType: 'retell',
      reminderTrigger: 3000,
      reminderMaxCount: 2,
    });
  };

  return (
    <Empty
      description={(
        <Typography.Text strong>
          No human agent associated to the persona
        </Typography.Text>
      )}
    >
      <Button
        type="primary"
        onClick={openHumanAgentCreationModal}
      >
        Create human agent
      </Button>
    </Empty>
  );
};

export default NoAgentState;
