import { TableColumnsType } from 'antd';

import ActionsCell from '../components/actionsCell';
import { ReferenceDataField } from '../../../api/referenceDataFields/types';
import LongTextCell from '../../../components/longTextCell';
import { referenceFieldTypeNames } from './index';

export const idColumnKey = 'referenceFieldId';

export const columnsInitialState: TableColumnsType<ReferenceDataField> = [
  {
    title: 'ID',
    dataIndex: idColumnKey,
    key: idColumnKey,
    fixed: 'left',
    sorter: true,
    sortOrder: 'descend',
    width: 80,
  },
  {
    title: 'Name',
    dataIndex: 'referenceFieldName',
    key: 'referenceFieldName',
    sorter: true,
  },
  {
    title: 'Campaign name',
    dataIndex: 'campaignName',
    key: 'campaignName',
    render: (_, record) => record.campaign.displayName,
  },
  {
    title: 'Type',
    dataIndex: 'referenceFieldType',
    key: 'referenceFieldType',
    sorter: true,
    render: (value: ReferenceDataField['referenceFieldType']) => referenceFieldTypeNames[value] ?? '',
  },
  {
    title: 'Choices',
    dataIndex: 'referenceFieldChoices',
    key: 'referenceFieldChoices',
    sorter: true,
    width: 200,
    render: ((value: ReferenceDataField['referenceFieldChoices']) => (
      <LongTextCell
        value={value ?? ''}
        title="Choices"
      />
    )),
  },
  {
    title: 'Actions',
    width: 104,
    fixed: 'right',
    render: ((_, record) => <ActionsCell referenceDataField={record} />),
  },
];
