import { useCallback, useState } from 'react';
import { ReferenceDataFieldsFilters } from '../types';

interface UseFiltersProps {
  resetPage: () => void;
}

const initialFiltersState: ReferenceDataFieldsFilters = {
  campaign: undefined,
};

const useFilters = ({ resetPage }: UseFiltersProps) => {
  const [filters, setFilters] = useState<ReferenceDataFieldsFilters>(initialFiltersState);

  const onFiltersUpdate = useCallback((
    filterName: keyof ReferenceDataFieldsFilters,
    value: ReferenceDataFieldsFilters[keyof ReferenceDataFieldsFilters],
  ) => {
    resetPage();

    setFilters((state) => ({
      ...state,
      [filterName]: value,
    }));
  }, [resetPage]);

  const clearFilters = useCallback(() => {
    resetPage();
    setFilters(initialFiltersState);
  }, [resetPage]);

  return {
    filters,
    onFiltersUpdate,
    clearFilters,
  };
};

export default useFilters;
