import axios from 'axios';
import { Page } from '@marrlab-app-shared/types';

import {
  AgentProvider,
  AssignNumberParams,
  CreateAgentParams,
  DeployAgentParams,
  DuplicateAgentParams,
  GetAgentProvidersParams,
  GetSearchableDataParams,
} from './types';
import config from '../../utils/config';
import {
  Integration,
  ModelProvider,
  ModelProviderEnvironment,
  ModelProviderType,
  OrchestratorNumberAssignment,
  UniqueFilterResponse,
} from '../common/types';

export const getAgentProviders = async (params: GetAgentProvidersParams) => (
  axios.get<Page<AgentProvider>>(`${config.CALLS_HISTORY_API_BASE_URL}/api/agent-providers/`, { params })
);

export const getOneAgentProviderByPersonaId = async (agentProviderId: number) => (
  axios.get<AgentProvider>(`${config.CALLS_HISTORY_API_BASE_URL}/api/agent-providers/persona/${agentProviderId}`)
);

export const getIntegrationsRequest = async (params: GetSearchableDataParams) => (
  axios.get<Integration[]>(`${config.CALLS_HISTORY_API_BASE_URL}/api/integrations/`, { params })
);

export const getApiVersionsRequest = async (params: GetSearchableDataParams) => (
  axios.get<UniqueFilterResponse[]>(`${config.CALLS_HISTORY_API_BASE_URL}/api/agent-providers/api-versions`, { params })
);

export const getProviderTypesRequest = async (params: GetSearchableDataParams) => (
  axios.get<UniqueFilterResponse[]>(`${config.CALLS_HISTORY_API_BASE_URL}/api/agent-providers/provider-types`, { params })
);

export const getAgentNamesRequest = async (params: GetSearchableDataParams) => (
  axios.get<UniqueFilterResponse[]>(`${config.CALLS_HISTORY_API_BASE_URL}/api/orchestrator-providers/agent-names`, { params })
);

export const deleteAgent = async (id: string) => (
  axios.delete<void>(`${config.CALLS_HISTORY_API_BASE_URL}/api/agent-providers/${id}`)
);

export const duplicateAgent = async (params: DuplicateAgentParams) => (
  axios.post<AgentProvider>(`${config.CALLS_HISTORY_API_BASE_URL}/api/agent-providers/${params.agentProviderId}/duplicate`, {
    agentName: params.agentName,
    orchestratorProviderId: params.orchestratorProviderId,
  })
);

export const createAgent = async (params: CreateAgentParams) => (
  axios.post<AgentProvider>(`${config.CALLS_HISTORY_API_BASE_URL}/api/agent-providers`, {
    ...params,
    campaignId: params.campaignId?.toString(),
  })
);

export const assignNumber = async (params: AssignNumberParams) => (
  axios.post<OrchestratorNumberAssignment>(
    `${config.CALLS_HISTORY_API_BASE_URL}/api/orchestrator-number-assignment/assign-number`,
    {
      ...params,
      promptParameters: params.promptParameters
        ? JSON.stringify(params.promptParameters)
        : params.promptParameters,
    },
  )
);

export const unassignNumber = async (id: number) => (
  axios.delete<void>(`${config.CALLS_HISTORY_API_BASE_URL}/api/orchestrator-number-assignment/unassign-number/${id}`)
);

export const deployAgent = async (params: DeployAgentParams) => (
  axios.patch<OrchestratorNumberAssignment>(
    `${config.CALLS_HISTORY_API_BASE_URL}/api/orchestrator-number-assignment/${params.orchestratorNumberAssignmentId}`,
    { deploy: params.deploy },
  )
);

export const getEmbeddingModelProviders = async (params: GetSearchableDataParams) => (
  axios.get<Page<ModelProvider>>(`${config.CALLS_HISTORY_API_BASE_URL}/api/model-providers/`, {
    params: {
      ...params,
      sortBy: 'alias',
      modelType: ModelProviderType.embedding,
      environment: config.MODE === 'development'
        ? ModelProviderEnvironment.dev
        : ModelProviderEnvironment.prod,
    },
  })
);

export const getChatModelProviders = async (params: GetSearchableDataParams) => (
  axios.get<Page<ModelProvider>>(`${config.CALLS_HISTORY_API_BASE_URL}/api/model-providers/`, {
    params: {
      ...params,
      take: 50,
      sortBy: 'alias',
      modelType: ModelProviderType.chat,
      environment: config.MODE === 'development'
        ? ModelProviderEnvironment.dev
        : ModelProviderEnvironment.prod,
    },
  })
);
