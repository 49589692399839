import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { AxiosError } from 'axios';
import { message, Typography } from 'antd';
import SuccessNotificationProvider from '@marrlab-app-shared/components/successNotification';

import { AgentProvider, AssignNumberParams } from '../../../../api/agentProviders/types';
import { assignNumber } from '../../../../api/agentProviders';
import AssignNumberModal from '../../components/assignNumberModal';

interface AssignNumberContextType {
  open: (agent: AgentProvider) => void;
}

const initialState: AssignNumberContextType = {
  open: () => {
  },
};

const AssignNumberContext = createContext<AssignNumberContextType>(initialState);

interface AssignNumberContextProps extends PropsWithChildren {
  fetchAgentProviders: () => Promise<void>;
}

const AssignNumberProvider = ({ children, fetchAgentProviders }: AssignNumberContextProps) => {
  const successNotification = SuccessNotificationProvider.useSuccessNotification();

  const [isOpen, setIsOpen] = useState(false);
  const [agentToEdit, setAgentToEdit] = useState<AgentProvider | null>(null);

  const close = useCallback(() => {
    setIsOpen(false);
    setAgentToEdit(null);
  }, []);

  const open = useCallback((agent: AgentProvider) => {
    setIsOpen(true);
    setAgentToEdit(agent);
  }, []);

  const onConfirm = useCallback(async (params: AssignNumberParams) => {
    try {
      const response = await assignNumber(params);

      successNotification.open({
        key: response.data.agentProviderId,
        title: 'Number assigned successfully',
        message: (
          <>
            <Typography.Text strong>Agent provider ID: </Typography.Text>
            <Typography.Text>{response.data.agentProviderId}</Typography.Text>
            <br />
            <Typography.Text strong>Orchestrator provider ID: </Typography.Text>
            <Typography.Text>{response.data.orchestratorProviderId}</Typography.Text>
            <br />
            <Typography.Text strong>Origination number: </Typography.Text>
            <Typography.Text>{response.data.originationNumber}</Typography.Text>
            <br />
            <Typography.Text strong>Orchestrator number: </Typography.Text>
            <Typography.Text>{response.data.orchestratorNumber}</Typography.Text>
          </>
        ),
      });

      await fetchAgentProviders();

      close();
    } catch (error) {
      if (error instanceof AxiosError) {
        message.open({ content: error.response?.data?.message, type: 'error', duration: 10 });
      }
    }
  }, [close, fetchAgentProviders, successNotification]);

  const value: AssignNumberContextType = useMemo(
    () => ({
      open,
    }),
    [open],
  );

  return (
    <AssignNumberContext.Provider value={value}>
      {children}
      <AssignNumberModal
        isOpen={isOpen}
        onCancel={close}
        initialAgent={agentToEdit}
        onConfirm={onConfirm}
      />
    </AssignNumberContext.Provider>
  );
};

AssignNumberProvider.useAssignNumber = () => useContext(AssignNumberContext);

export default AssignNumberProvider;
