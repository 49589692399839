import { useCallback } from 'react';
import { Button } from 'antd';

import CreateTtsConfigProvider from '../../providers/createTtsConfigProvider';

const CreateTtsConfigButton = () => {
  const createTtsConfig = CreateTtsConfigProvider.useCreateTtsConfig();

  const open = useCallback(() => {
    createTtsConfig.open();
  }, [createTtsConfig]);

  return (
    <Button
      type="primary"
      onClick={open}
    >
      Create TTS config
    </Button>
  );
};

export default CreateTtsConfigButton;
