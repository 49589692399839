import {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
} from 'react';
import { useParams } from 'react-router-dom';

import { Persona } from '../../../../api/personas/types';
import usePersonaData from '../hooks/usePersonaData';

interface ManagePersonaContextType {
  isNew: boolean,
  loading: boolean,
  persona: Persona | null;
  hasReferenceDataRecords: boolean,
  setHasReferenceDataRecords: (state: boolean) => void,
}

const initialState: ManagePersonaContextType = {
  isNew: false,
  loading: true,
  persona: null,
  hasReferenceDataRecords: false,
  setHasReferenceDataRecords: () => {},
};

const ManagePersonaContext = createContext<ManagePersonaContextType>(initialState);

const ManagePersonaProvider = ({
  children,
}: PropsWithChildren) => {
  const { personaId } = useParams();

  const {
    isNew,
    data,
    loading,
    hasReferenceDataRecords,
    setHasReferenceDataRecords,
  } = usePersonaData({ personaId });

  const value = useMemo(() => ({
    isNew,
    persona: data,
    loading,
    hasReferenceDataRecords,
    setHasReferenceDataRecords,
  }), [
    data,
    hasReferenceDataRecords,
    isNew,
    loading,
    setHasReferenceDataRecords,
  ]);

  return (
    <ManagePersonaContext.Provider value={value}>
      {children}
    </ManagePersonaContext.Provider>
  );
};

ManagePersonaProvider.useManagePersona = () => useContext(ManagePersonaContext);

export default ManagePersonaProvider;
