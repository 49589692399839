import { TableColumnsType } from 'antd';

import { AgentProvider } from '../../../../../api/agentProviders/types';
import { ColumnSetting } from '../types';

export const mapColumnsToColumnsSettings = (
  columns: TableColumnsType<AgentProvider>,
): ColumnSetting[] => (
  columns.map((item) => ({
    key: item.key as string,
    title: item.title as string,
    visible: !item.hidden,
  }))
);

interface MapColumnsSettingsToColumnsArgs {
  columns: TableColumnsType<AgentProvider>;
  columnsSettings?: ColumnSetting[];
}

export const mapColumnsSettingsToColumns = ({
  columnsSettings,
  columns,
}: MapColumnsSettingsToColumnsArgs): TableColumnsType<AgentProvider> => {
  if (!columnsSettings) {
    return columns;
  }

  const columnsSettingsMap = columnsSettings.reduce((acc, setting, index) => {
    acc[setting.key] = {
      ...setting,
      index,
    };

    return acc;
  }, {} as Record<string, ColumnSetting & { index: number }>);

  return columns.map((column) => {
    const settings = columnsSettingsMap[column.key as string];

    return {
      ...column,
      hidden: !(settings?.visible ?? true),
    };
  });
};
