import { useCallback, useEffect, useState } from 'react';
import { message } from 'antd';
import { AxiosError } from 'axios';
import { Page } from '@marrlab-app-shared/types';

import { getHumanTemplate } from '../../../api/humanTemplates';
import { GetHumanTemplateParams, HumanTemplate } from '../../../api/humanTemplates/types';

const useHumanTemplateData = ({
  skip,
  take,
  sortBy,
  order,
  search,
  campaign,
}: GetHumanTemplateParams) => {
  const [data, setData] = useState<Page<HumanTemplate> | null>(null);
  const [loading, setLoading] = useState(false);

  const fetchHumanTemplate = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getHumanTemplate({
        skip,
        take,
        sortBy,
        order,
        search,
        campaign,
      });

      setData(response.data);
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        message.open({ content: error.message, type: 'error', duration: 10 });
      }
    }

    setLoading(false);
  }, [campaign, order, search, skip, sortBy, take]);

  useEffect(() => {
    fetchHumanTemplate();
  }, [fetchHumanTemplate]);

  return {
    data,
    loading,
    fetchHumanTemplate,
  };
};

export default useHumanTemplateData;
