import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { AxiosError } from 'axios';
import { message, Typography } from 'antd';
import SuccessNotificationProvider from '@marrlab-app-shared/components/successNotification';

import CreateTtsConfigModal from '../../components/createTtsConfigModal';
import { CreateTtsConfigParams, TtsConfig } from '../../../../api/ttsConfigs/types';
import { createTtsConfig, editTtsConfig } from '../../../../api/ttsConfigs';

interface CreateTtsConfigContextType {
  open: () => void;
  openOnEdit: (ttsConfig: TtsConfig) => void;
}

const initialState: CreateTtsConfigContextType = {
  open: () => {
  },
  openOnEdit: () => {
  },
};

const CreateTtsConfigContext = createContext<CreateTtsConfigContextType>(initialState);

interface CreateTtsConfigContextProps extends PropsWithChildren {
  fetchTtsConfigs: () => Promise<void>;
}

const CreateTtsConfigProvider = ({ children, fetchTtsConfigs }: CreateTtsConfigContextProps) => {
  const successNotification = SuccessNotificationProvider.useSuccessNotification();

  const [isOpen, setIsOpen] = useState(false);
  const [ttsConfigToEdit, setTtsConfigToEdit] = useState<TtsConfig | null>(null);

  const close = useCallback(() => {
    setIsOpen(false);
    setTtsConfigToEdit(null);
  }, []);

  const open = useCallback(() => {
    setIsOpen(true);
  }, []);

  const openOnEdit = useCallback((ttsConfig: TtsConfig) => {
    setTtsConfigToEdit(ttsConfig);
    setIsOpen(true);
  }, []);

  const onConfirm = useCallback(async (params: CreateTtsConfigParams) => {
    try {
      const response = ttsConfigToEdit
        ? await editTtsConfig(ttsConfigToEdit.ttsConfigId, params)
        : await createTtsConfig(params);

      successNotification.open({
        key: response.data.ttsConfigId.toString(),
        title: ttsConfigToEdit ? 'Changes saved' : 'TTS config created successfully',
        message: (
          <>
            <Typography.Text strong>ID: </Typography.Text>
            <Typography.Text>{response.data.ttsConfigId}</Typography.Text>
            <br />
            <Typography.Text strong>Short name: </Typography.Text>
            <Typography.Text>{response.data.shortName}</Typography.Text>
          </>
        ),
      });

      await fetchTtsConfigs();

      close();
    } catch (error) {
      if (error instanceof AxiosError) {
        message.open({ content: error.response?.data?.message, type: 'error', duration: 10 });
      }
    }
  }, [close, ttsConfigToEdit, fetchTtsConfigs, successNotification]);

  const value: CreateTtsConfigContextType = useMemo(
    () => ({
      open,
      openOnEdit,
    }),
    [open, openOnEdit],
  );

  return (
    <CreateTtsConfigContext.Provider value={value}>
      {children}
      <CreateTtsConfigModal
        isOpen={isOpen}
        initialTtsConfig={ttsConfigToEdit}
        onCancel={close}
        onConfirm={onConfirm}
      />
    </CreateTtsConfigContext.Provider>
  );
};

CreateTtsConfigProvider.useCreateTtsConfig = () => useContext(CreateTtsConfigContext);

export default CreateTtsConfigProvider;
