import { useCallback, useMemo } from 'react';
import {
  Empty,
  Flex,
  Form,
  Popover,
  Select,
  Spin,
  Typography,
} from 'antd';
import useDataFetchWithSearch from '@marrlab-app-shared/hooks/data/useDataFetchWithSearch';

import { FieldProps } from '../../types';
import { getIntegrationsRequest } from '../../../../../../api/agentProviders';

import './index.scss';

const IntegrationField = ({
  state,
  disabled,
  onUpdate,
}: FieldProps) => {
  const {
    updateSearch,
    loading,
    data,
  } = useDataFetchWithSearch({ request: getIntegrationsRequest });

  const onChange = useCallback((value?: string) => {
    onUpdate('integrationId', value);
  }, [onUpdate]);

  const options = useMemo(() => (
    data?.map((item) => {
      const id = item.integrationId;
      const names = item.config.map((config) => config.IntegrationName).join(', ');
      const preview = JSON.stringify(item, null, 2);

      return ({
        value: id,
        label: (
          <>
            #
            <Typography.Text strong>
              {id}
            </Typography.Text>
            <Typography.Text>
              . Integration names:
            </Typography.Text>
            {' '}
            <Typography.Text strong>
              {names}
            </Typography.Text>
            {' '}
            <Popover
              overlayClassName="integration-field-popover"
              content={(
                <Typography.Text className="whitespace-pre-wrap">
                  {preview}
                </Typography.Text>
              )}
            >
              <Typography.Link>
                (Hover over to see whole config)
              </Typography.Link>
            </Popover>
          </>
        ),
      });
    }) ?? []
  ), [data]);

  return (
    <Form.Item label="Integration">
      <Select
        value={state.integrationId}
        onChange={onChange}
        onSearch={updateSearch}
        loading={loading}
        options={options}
        allowClear
        showSearch
        filterOption={false}
        notFoundContent={loading ? (
          <Flex justify="center" align="center" className="h-20">
            <Spin />
          </Flex>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
        disabled={disabled}
      />
    </Form.Item>
  );
};

export default IntegrationField;
