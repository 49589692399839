import { useCallback } from 'react';
import { Form, Select } from 'antd';

import { FieldProps } from '../../types';

const options = [
  {
    value: 'azure',
    label: 'Azure',
  },
  {
    value: 'aws_bedrock',
    label: 'AWS Bedrock',
  },
  {
    value: 'openai',
    label: 'OpenAI',
  },
];

const ProviderTypeField = ({
  state,
  disabled,
  onUpdate,
  error,
}: FieldProps) => {
  const onChange = useCallback((value?: string) => {
    onUpdate('providerType', value);
  }, [onUpdate]);

  return (
    <Form.Item
      label="Provider type"
      required
      validateStatus={error ? 'error' : undefined}
      help={error}
    >
      <Select
        value={state.providerType}
        onChange={onChange}
        options={options}
        disabled={disabled}
      />
    </Form.Item>
  );
};

export default ProviderTypeField;
