import { useCallback } from 'react';
import { Button, Tooltip } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import { Persona } from '../../../../api/personas/types';
import useNavigate from '../../../../hooks/navigation/useNavigate';

interface EditPersonaActionProps {
  persona: Persona;
}

const EditPersonaAction = ({ persona }: EditPersonaActionProps) => {
  const { navigateToEditPersona } = useNavigate();

  const open = useCallback(() => {
    navigateToEditPersona(persona.personaId);
  }, [navigateToEditPersona, persona.personaId]);

  return (
    <Tooltip title="Edit persona">
      <Button
        type="primary"
        ghost
        icon={<EditOutlined />}
        size="middle"
        onClick={open}
      />
    </Tooltip>
  );
};

export default EditPersonaAction;
