import { TableColumnsType } from 'antd';

import ActionsCell from '../components/actionsCell';
import { AsrConfig } from '../../../api/asrConfigs/types';
import LongTextCell from '../../../components/longTextCell';

export const idColumnKey = 'asrConfigId';

export const columnsInitialState: TableColumnsType<AsrConfig> = [
  {
    title: 'ID',
    dataIndex: idColumnKey,
    key: idColumnKey,
    fixed: 'left',
    sorter: true,
    sortOrder: 'descend',
    width: 80,
  },
  {
    title: 'Short name',
    dataIndex: 'shortName',
    key: 'shortName',
    sorter: true,
  },
  {
    title: 'Config',
    dataIndex: 'config',
    key: 'config',
    sorter: true,
    width: 500,
    render: ((_, record) => (
      <LongTextCell
        value={record.config
          ? JSON.stringify(record.config, null, 2)
          : undefined}
        title="Config"
        width="w-2xl"
      />
    )),
  },
  {
    title: 'Actions',
    width: 84,
    fixed: 'right',
    align: 'right',
    render: ((_, record) => <ActionsCell asrConfig={record} />),
  },
];
