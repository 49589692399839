import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { AxiosError } from 'axios';
import { message, Typography } from 'antd';
import SuccessNotificationProvider from '@marrlab-app-shared/components/successNotification';

import CreateAgentModal from '../../components/createAgentModal';
import { AgentProvider, CreateAgentParams } from '../../../../api/agentProviders/types';
import { createAgent } from '../../../../api/agentProviders';
import { CreateAgentDialogPrefilledData } from '../../types';

interface CreateAgentContextType {
  open: (data?: CreateAgentDialogPrefilledData | null) => void;
  openOnEdit: (agent: AgentProvider, isPreviewMode?: boolean) => void;
}

const initialState: CreateAgentContextType = {
  open: () => {
  },
  openOnEdit: () => {
  },
};

const CreateAgentContext = createContext<CreateAgentContextType>(initialState);

interface CreateAgentContextProps extends PropsWithChildren {
  fetchAgentProviders: () => Promise<void>;
}

const CreateAgentProvider = ({ children, fetchAgentProviders }: CreateAgentContextProps) => {
  const successNotification = SuccessNotificationProvider.useSuccessNotification();

  const [isOpen, setIsOpen] = useState(false);
  const [agentToEdit, setAgentToEdit] = useState<AgentProvider | null>(null);
  const [prefilledData, setPrefilledData] = useState<CreateAgentDialogPrefilledData | null>(null);
  const [previewMode, setPreviewMode] = useState(false);

  const close = useCallback(() => {
    setIsOpen(false);
    setAgentToEdit(null);
    setPreviewMode(false);
    setPrefilledData(null);
  }, []);

  const open = useCallback((data?: CreateAgentDialogPrefilledData | null) => {
    setIsOpen(true);

    if (data) {
      setPrefilledData(data);
    }
  }, []);

  const openOnEdit = useCallback((agent: AgentProvider, isPreviewMode = false) => {
    setAgentToEdit(agent);
    setPreviewMode(isPreviewMode);
    setIsOpen(true);
  }, []);

  const onConfirm = useCallback(async (params: CreateAgentParams) => {
    try {
      const response = await createAgent(params);

      successNotification.open({
        key: response.data.agentProviderId,
        title: 'Agent created successfully',
        message: (
          <>
            <Typography.Text strong>Agent provider ID: </Typography.Text>
            <Typography.Text>{response.data.agentProviderId}</Typography.Text>
            <br />
            <Typography.Text strong>Orchestrator provider ID: </Typography.Text>
            <Typography.Text>{response.data.orchestratorProviderId}</Typography.Text>
          </>
        ),
      });

      await fetchAgentProviders();

      close();
    } catch (error) {
      if (error instanceof AxiosError) {
        message.open({ content: error.response?.data?.message, type: 'error', duration: 10 });
      }
    }
  }, [close, fetchAgentProviders, successNotification]);

  const value: CreateAgentContextType = useMemo(
    () => ({
      open,
      openOnEdit,
    }),
    [open, openOnEdit],
  );

  return (
    <CreateAgentContext.Provider value={value}>
      {children}
      <CreateAgentModal
        isOpen={isOpen}
        previewMode={previewMode}
        prefilledData={prefilledData}
        initialAgent={agentToEdit}
        onCancel={close}
        onConfirm={onConfirm}
      />
    </CreateAgentContext.Provider>
  );
};

CreateAgentProvider.useCreateAgent = () => useContext(CreateAgentContext);

export default CreateAgentProvider;
