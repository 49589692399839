import { useCallback, useState } from 'react';
import { Button, Popconfirm, Tooltip } from 'antd';
import { BulbOutlined } from '@ant-design/icons';

import { AgentProvider, OrchestratorProviderTypes } from '../../../../api/agentProviders/types';
import DeployAgentProvider from '../../providers/deployAgentProvider';

import './index.scss';

interface UndeployAgentActionProps {
  agent: AgentProvider;
}

const UndeployAgentAction = ({ agent }: UndeployAgentActionProps) => {
  const { loading, onDeployAgent } = DeployAgentProvider.useDeployAgent();

  const [open, setOpen] = useState(false);

  const isInactive = !agent.active;
  const hasNumberAssigned = !!agent.orchestratorNumberAssignment;
  const isVocode = agent.orchestratorProvider?.providerType === OrchestratorProviderTypes.vocode;

  const isDisabled = isInactive || !hasNumberAssigned || !isVocode;

  let tooltip = 'Undeploy agent';

  if (isInactive) {
    tooltip = 'The agent marked as archived';
  }

  if (!hasNumberAssigned) {
    tooltip = 'Agent cannot be undeploy without number assigned';
  }

  if (!isVocode) {
    tooltip = 'Retell agents cannot be undeployed';
  }

  const showConfirmation = useCallback(() => setOpen(true), []);

  const onConfirm = useCallback(async () => {
    if (agent.orchestratorNumberAssignment?.orchestratorNumberAssignmentId) {
      const { orchestratorNumberAssignmentId } = agent.orchestratorNumberAssignment;
      await onDeployAgent({
        orchestratorNumberAssignmentId,
        deploy: false,
      });
    }

    setOpen(false);
  }, [agent.orchestratorNumberAssignment, onDeployAgent]);

  const onCancel = useCallback(() => setOpen(false), []);

  return (
    <Popconfirm
      title={`Undeploy the agent #${agent.agentProviderId}`}
      description="Are you sure you want to undeploy the agent?"
      open={open}
      onConfirm={onConfirm}
      okButtonProps={{ loading }}
      onCancel={onCancel}
      disabled={isDisabled}
    >
      <Tooltip title={tooltip}>
        <Button
          type="primary"
          ghost
          icon={<BulbOutlined className="undeploy-agent-button-icon" />}
          size="middle"
          onClick={showConfirmation}
          disabled={isDisabled}
        />
      </Tooltip>
    </Popconfirm>
  );
};

export default UndeployAgentAction;
