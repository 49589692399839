import { useCallback } from 'react';
import PhoneInput from 'react-phone-input-2';
import cn from 'classnames';
import { Form } from 'antd';

import { FieldProps } from '../../types';

const OriginationPhoneField = ({
  state,
  disabled,
  error,
  onUpdate,
}: FieldProps) => {
  const onChange = useCallback((value: string) => {
    onUpdate('originationNumber', value ?? undefined);
  }, [onUpdate]);

  return (
    <Form.Item
      label="Origination number"
      required
      validateStatus={error ? 'error' : undefined}
      help={error}
    >
      <PhoneInput
        containerClass={cn('phone-input', { disabled })}
        value={state.originationNumber}
        onChange={onChange}
        country="us"
        disabled={disabled}
      />
    </Form.Item>
  );
};

export default OriginationPhoneField;
