import { useCallback } from 'react';
import { Button } from 'antd';

import CreateAgentProvider from '../../providers/createAgentProvider';

const CreateAgentButton = () => {
  const createAgent = CreateAgentProvider.useCreateAgent();

  const open = useCallback(() => {
    createAgent.open();
  }, [createAgent]);

  return (
    <Button
      type="primary"
      onClick={open}
    >
      Create agent
    </Button>
  );
};

export default CreateAgentButton;
