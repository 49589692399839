import { useCallback } from 'react';
import { Button, Tooltip } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import CreateQuestionProvider from '../../providers/createQuestionProvider';
import { Question } from '../../../../api/questions/types';

interface EditQuestionActionProps {
  question: Question;
}

const EditQuestionAction = ({ question }: EditQuestionActionProps) => {
  const createQuestion = CreateQuestionProvider.useCreateQuestion();

  const open = useCallback(() => {
    createQuestion.openOnEdit(question);
  }, [createQuestion, question]);

  return (
    <Tooltip title="Edit question">
      <Button
        type="primary"
        ghost
        icon={<EditOutlined />}
        size="middle"
        onClick={open}
      />
    </Tooltip>
  );
};

export default EditQuestionAction;
