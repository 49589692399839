import { useCallback } from 'react';

import usePaginatedDataFetchWithSearch from '@marrlab-app-shared/hooks/data/usePaginatedDataFetchWithSearch';
import { getReferenceData } from '../../../../../api/referenceData';
import { SortOrder } from '../../../../../constants';

interface UseInitialReferenceDataIdArgs {
  isOpen: boolean;
  personaId?: number;
}

const useInitialReferenceDataId = ({ isOpen, personaId }: UseInitialReferenceDataIdArgs) => {
  const request = useCallback(() => {
    if (!isOpen) {
      return Promise.resolve();
    }

    if (personaId) {
      return getReferenceData(personaId, {
        search: '',
        take: 1,
        sortBy: 'referenceDataId',
        order: SortOrder.descend,
      });
    }

    throw new Error('No personaId provided');
  }, [isOpen, personaId]);

  const { loading, data } = usePaginatedDataFetchWithSearch({
    request,
  });

  return {
    loading,
    initialReferenceDataId: data?.[0]?.referenceDataId ?? null,
  };
};

export default useInitialReferenceDataId;
