import axios from 'axios';
import { Page } from '@marrlab-app-shared/types';

import {
  VoiceAgentConfig,
  CreateVoiceAgentConfigParams,
  GetVoiceAgentConfigParams,
} from './types';
import config from '../../utils/config';

export const getVoiceAgentConfigs = async (params: GetVoiceAgentConfigParams) => (
  axios.get<Page<VoiceAgentConfig>>(`${config.CALLS_HISTORY_API_BASE_URL}/api/voice-agent-config/`, { params })
);

export const createVoiceAgentConfig = async (params: CreateVoiceAgentConfigParams) => (
  axios.post<VoiceAgentConfig>(`${config.CALLS_HISTORY_API_BASE_URL}/api/voice-agent-config`, params)
);

export const editVoiceAgentConfig = async (id: number, params: CreateVoiceAgentConfigParams) => (
  axios.put<VoiceAgentConfig>(`${config.CALLS_HISTORY_API_BASE_URL}/api/voice-agent-config/${id}`, params)
);
