import { useCallback } from 'react';
import { List, Switch, Typography } from 'antd';

import { ColumnSetting } from '../../types';

interface ColumnSettingRowProps {
  item: ColumnSetting;
  onVisibilityUpdate: (key: string, checked: boolean) => void;
}

const ColumnSettingRow = ({ item, onVisibilityUpdate }: ColumnSettingRowProps) => {
  const onChange = useCallback((checked: boolean) => (
    onVisibilityUpdate(item.key, checked)
  ), [item.key, onVisibilityUpdate]);

  return (
    <List.Item>
      <Typography.Text>{item.title}</Typography.Text>
      <Switch
        className="ml-auto"
        checked={item.visible}
        onChange={onChange}
      />
    </List.Item>
  );
};

export default ColumnSettingRow;
