import {
  Modal,
  Form,
  Button,
  Flex,
} from 'antd';

import NameField from './components/nameField';
import CampaignField from './components/campaignField';
import TemplateField from './components/templateField';

import useCreateHumanTemplateModalLogic from './hooks/useCreateHumanTemplateModalLogic';
import useCreateHumanTemplateModalState from './hooks/useCreateHumanTemplateModalState';
import useCreateHumanTemplateModalValidation from './hooks/useCreateHumanTemplateModalValidation';
import { HumanTemplate, CreateHumanTemplateParams } from '../../../../api/humanTemplates/types';

import './styles.scss';

interface CreateHumanTemplateModalProps {
  isOpen: boolean;
  initialHumanTemplate: HumanTemplate | null;
  onConfirm: (params: CreateHumanTemplateParams) => Promise<void>;
  onCancel: () => void;
}

const CreateHumanTemplateModal = ({
  isOpen,
  initialHumanTemplate,
  onConfirm: onConfirmBase,
  onCancel: onCancelBase,
}: CreateHumanTemplateModalProps) => {
  const {
    errors,
    isValid,
    clearErrorForField,
  } = useCreateHumanTemplateModalValidation({ isOpen });

  const {
    isEdit,
    state,
    onPropertyUpdate,
  } = useCreateHumanTemplateModalState({
    isOpen,
    initialHumanTemplate,
    clearErrorForField,
  });

  const {
    saving,
    onConfirm,
    onCancel,
  } = useCreateHumanTemplateModalLogic({
    state,
    isValid,
    onConfirm: onConfirmBase,
    onCancel: onCancelBase,
  });

  return (
    <Modal
      title={isEdit ? `Edit human template #${initialHumanTemplate?.templateId}` : 'Create human template'}
      open={isOpen}
      onOk={() => onConfirm()}
      onCancel={onCancel}
      width="600px"
      footer={(
        <Flex gap={8} justify="flex-end">
          <Button onClick={onCancel} disabled={saving}>
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={() => onConfirm()}
            disabled={saving}
          >
            {isEdit ? 'Save' : 'Create'}
          </Button>
        </Flex>
      )}
    >
      <Form
        layout="vertical"
        className="py-4"
      >
        <NameField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={saving}
          error={errors.templateName}
        />
        <CampaignField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={saving}
          error={errors.campaignId}
        />
        <TemplateField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={saving}
          error={errors.template}
        />
      </Form>
    </Modal>
  );
};

export default CreateHumanTemplateModal;
