import { useState } from 'react';
import { Typography } from 'antd';

interface ExpandableTextProps {
  children?: string;
}

const ExpandableText = ({
  children,
}: ExpandableTextProps) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Typography.Paragraph
      className="whitespace-pre-wrap"
      ellipsis={{
        rows: 4,
        symbol: (isExpanded: boolean) => (isExpanded ? '< Less' : 'More >'),
        expanded,
        expandable: 'collapsible',
        onExpand: (_, info) => setExpanded(info.expanded),
      }}
    >
      {children}
    </Typography.Paragraph>
  );
};

export default ExpandableText;
