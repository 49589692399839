export const getOptionsFromChoices = (choices: string) => (
  choices.replace(/[[\]]/gi, '').split(',').map((option) => {
    const value = option.trim().replace(/^['"]/, '').replace(/['"]$/, '');

    return ({
      label: value,
      value,
    });
  })
);

export default getOptionsFromChoices;
