import { useCallback } from 'react';
import { TableColumnsType } from 'antd';

import { mapColumnsSettingsToColumns } from '../utils/mappers';
import { columnsInitialState } from '../../../constants/columnsInitialState';
import { ColumnSetting } from '../types';
import { AgentProvider } from '../../../../../api/agentProviders/types';
import { clearColumnsSettingsInStorage, saveColumnsSettingsToStorage } from '../../../utils/columnsSettingsPersistence';

interface UseColumnsModalLogicArgs {
  initialColumns: TableColumnsType<AgentProvider>;
  columnsSettings: ColumnSetting[];
  onConfirm: (columns: TableColumnsType<AgentProvider>) => void;
}

const useColumnsModalLogic = ({
  initialColumns,
  columnsSettings,
  onConfirm: onConfirmBase,
}: UseColumnsModalLogicArgs) => {
  const onConfirm = useCallback(() => {
    saveColumnsSettingsToStorage(columnsSettings);
    onConfirmBase(mapColumnsSettingsToColumns({ columnsSettings, columns: initialColumns }));
  }, [columnsSettings, initialColumns, onConfirmBase]);

  const onResetToDefault = useCallback(() => {
    clearColumnsSettingsInStorage();
    onConfirmBase(columnsInitialState);
  }, [onConfirmBase]);

  return {
    onConfirm,
    onResetToDefault,
  };
};

export default useColumnsModalLogic;
