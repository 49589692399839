import { useCallback } from 'react';
import { Form, InputNumber } from 'antd';

import { FieldProps } from '../../types';

import './index.scss';

const ReferenceDataIdField = ({
  state,
  error,
  onUpdate,
}: FieldProps) => {
  const onChange = useCallback((value: number | null) => {
    onUpdate(state.referenceFieldId, 'referenceDataId', value ?? 0);
  }, [onUpdate, state.referenceFieldId]);

  return (
    <Form.Item
      label="Reference data ID"
      required
      validateStatus={error ? 'error' : undefined}
      help={error}
    >
      <InputNumber
        className="reference-data-id-field"
        value={state.referenceDataId}
        onChange={onChange}
        disabled
        min={0}
        step={1}
      />
    </Form.Item>
  );
};

export default ReferenceDataIdField;
