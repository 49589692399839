import { useCallback, useEffect, useState } from 'react';
import { message } from 'antd';
import { AxiosError, AxiosResponse } from 'axios';

import useSearchDebounce from '../state/useSearchDebounce';

export interface GetSearchableDataParams {
  search: string;
}

interface UseDataFetchWithSearchArgs<Data> {
  request: (params: GetSearchableDataParams) => Promise<AxiosResponse<Data[]>>;
}

const useDataFetchWithSearch = <Data>({
  request,
}: UseDataFetchWithSearchArgs<Data>) => {
  const [data, setData] = useState<Data[] | null>(null);
  const [loading, setLoading] = useState(false);

  const { search, updateSearch } = useSearchDebounce();

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await request({ search });

      setData(response.data);
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        message.open({ content: error.message, type: 'error', duration: 10 });
      }
    }

    setLoading(false);
  }, [request, search]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    data,
    loading,
    updateSearch,
  };
};

export default useDataFetchWithSearch;
