import { useCallback } from 'react';
import useDataFetch from '@marrlab-app-shared/hooks/data/useDataFetch';

import { AgentProvider } from '../../../api/agentProviders/types';
import { getOneAgentProviderByPersonaId } from '../../../api/agentProviders';

interface UseAgentDataArgs {
  personaId?: number;
}

export const useAgentData = ({ personaId }: UseAgentDataArgs) => {
  const request = useCallback(() => {
    if (!personaId) {
      return Promise.resolve();
    }

    return getOneAgentProviderByPersonaId(personaId);
  }, [personaId]);

  const { loading, data, fetchData } = useDataFetch<AgentProvider | null>({
    request,
    showError: false,
  });

  return {
    loading,
    data,
    refetch: fetchData,
  };
};

export default useAgentData;
