import { useCallback, useMemo, useState } from 'react';
import {
  Button,
  Empty,
  Flex,
  Form,
  Popover,
  Select,
  Spin,
} from 'antd';
import usePaginatedDataFetchWithSearch from '@marrlab-app-shared/hooks/data/usePaginatedDataFetchWithSearch';

import { getPersonas } from '../../../../api/personas';
import { Persona } from '../../../../api/personas/types';

import './index.scss';

interface Option {
  label: string;
  value: number;
  questionIds: Persona['questionIds'];
}

interface CopyQuestionPopoverProps {
  disabled: boolean;
  campaignId?: string;
  setQuestions: (value?: number[]) => void;
}

const CopyQuestionPopover = ({
  disabled,
  campaignId,
  setQuestions,
}: CopyQuestionPopoverProps) => {
  const request = useCallback(({ search }: { search: string }) => {
    if (!campaignId) {
      return Promise.resolve();
    }

    return getPersonas({
      search,
      take: 100,
      campaign: parseInt(campaignId, 10),
    });
  }, [campaignId]);

  const { updateSearch, loading, data } = usePaginatedDataFetchWithSearch({
    request,
  });

  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const [open, setOpen] = useState(false);

  const hide = useCallback(() => {
    setOpen(false);
  }, []);

  const handleOpenChange = useCallback((newOpen: boolean) => {
    setOpen(newOpen);
  }, []);

  const onChange = useCallback((_?: number, option?: Option | Option[]) => {
    if (!Array.isArray(option)) {
      setSelectedOption(option ?? null);
    }
  }, [setSelectedOption]);

  const onCopy = useCallback(() => {
    if (!selectedOption) {
      return;
    }

    setQuestions(selectedOption.questionIds.question_ids);
    setSelectedOption(null);
    hide();
  }, [hide, selectedOption, setQuestions]);

  const options = useMemo(() => (
    data?.map((item) => ({
      value: item.personaId,
      label: item.personaName,
      questionIds: item.questionIds,
    })) ?? []
  ), [data]);

  return (
    <Popover
      overlayClassName="copy-question-popover"
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
      content={(
        <>
          <Form.Item label="Persona">
            <Select<number | undefined, Option>
              value={selectedOption?.value}
              onChange={onChange}
              onSearch={updateSearch}
              loading={loading}
              options={options}
              allowClear
              showSearch
              filterOption={false}
              notFoundContent={loading ? (
                <Flex justify="center" align="center" className="h-20">
                  <Spin />
                </Flex>
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )}
              disabled={disabled}
            />
          </Form.Item>
          <Flex justify="flex-end">
            <Button
              type="primary"
              onClick={onCopy}
              disabled={!selectedOption}
            >
              Copy
            </Button>
          </Flex>
        </>
      )}
      title="Copy questions from another persona"
    >
      <Button
        className="add-question-button"
        type="link"
        disabled={disabled}
      >
        Copy questions from another persona
      </Button>
    </Popover>
  );
};

export default CopyQuestionPopover;
