import {
  Button,
  Card,
  Flex,
  Pagination,
  Table,
  Typography,
} from 'antd';
import { CloseOutlined, ReloadOutlined } from '@ant-design/icons';
import useSearchDebounce from '@marrlab-app-shared/hooks/state/useSearchDebounce';
import FiltersPanel from '@marrlab-app-shared/components/filtersPanel';

import Search from './components/search';
import DeletePersonaProvider from './providers/deletePersonaProvider';
import CreatePersonaButton from './components/createPersonaButton';
import CampaignFilter from './components/campaignFilter';

import usePagination from '../../hooks/table/usePagination';
import usePersonasData from './hooks/usePersonasData';
import useSorting from './hooks/useSorting';
import useTableConfiguration from './hooks/useTableConfiguration';
import useFilters from './hooks/useFilters';
import { idColumnKey } from './constants/columnsInitialState';

import './index.scss';

const Personas = () => {
  const {
    columns,
    sticky,
    scroll,
    setColumns,
  } = useTableConfiguration();
  const {
    skip,
    page,
    pageSize,
    resetPage,
    onPageChange,
    showTotal,
  } = usePagination();
  const { search, updateSearch } = useSearchDebounce({ onDebounce: resetPage });
  const { sortBy, sortDirection, onSortChange } = useSorting({ setColumns });
  const { filters, clearFilters, onFiltersUpdate } = useFilters({ resetPage });
  const { data, loading, fetchPersonas } = usePersonasData({
    skip,
    take: pageSize,
    sortBy,
    order: sortDirection,
    search,
    ...filters,
  });

  return (
    <DeletePersonaProvider fetchPersonas={fetchPersonas}>
      <Card
        title={(
          <Typography.Title
            level={4}
            className="personas-title"
          >
            Personas
          </Typography.Title>
        )}
        className="bg-white rounded shadow-lg"
      >
        <FiltersPanel
          defaultOpen
          search={(
            <Flex
              gap={16}
              justify="flex-start"
            >
              <Search updateSearch={updateSearch} />
              <Button
                icon={<ReloadOutlined />}
                onClick={fetchPersonas}
              >
                Reload
              </Button>
              <CreatePersonaButton />
            </Flex>
          )}
        >
          <Flex gap={16} vertical>
            <Flex gap={16} wrap="wrap" justify="space-between">
              <CampaignFilter filters={filters} onFiltersUpdate={onFiltersUpdate} />
              <Button
                danger
                onClick={clearFilters}
                icon={<CloseOutlined />}
              >
                Clear filters
              </Button>
            </Flex>
          </Flex>
        </FiltersPanel>
        <div className="table-wrapper">
          <Table
            rowKey={idColumnKey}
            dataSource={data?.data}
            columns={columns}
            loading={loading}
            scroll={scroll}
            pagination={false}
            sticky={sticky}
            onChange={onSortChange}
          />
        </div>
        <Flex justify="flex-end">
          <Pagination
            hideOnSinglePage
            className="mt-4"
            total={data?.total}
            showTotal={showTotal}
            pageSize={pageSize}
            current={page}
            showSizeChanger
            onChange={onPageChange}
            disabled={loading}
          />
        </Flex>
      </Card>
    </DeletePersonaProvider>
  );
};

export default Personas;
