import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { AxiosError } from 'axios';
import { message } from 'antd';

import { deleteCampaign } from '../../../../api/campaigns';

interface DeleteCampaignContextType {
  loading: boolean;
  onDeleteCampaign: (campaignId: number) => Promise<void>;
}

const initialState: DeleteCampaignContextType = {
  loading: false,
  onDeleteCampaign: () => Promise.reject(),
};

const DeleteCampaignContext = createContext<DeleteCampaignContextType>(initialState);

interface DeleteCampaignProviderProps extends PropsWithChildren {
  fetchCampaigns: () => Promise<void>;
}

const DeleteCampaignProvider = ({ children, fetchCampaigns }: DeleteCampaignProviderProps) => {
  const [loading, setLoading] = useState(false);

  const onDeleteCampaign = useCallback(async (campaignId: number) => {
    setLoading(true);

    try {
      await deleteCampaign(campaignId);

      await fetchCampaigns();
    } catch (error) {
      if (error instanceof AxiosError) {
        message.open({ content: error.response?.data?.message, type: 'error', duration: 10 });
      }
    }

    setLoading(false);
  }, [fetchCampaigns]);

  const value: DeleteCampaignContextType = useMemo(
    () => ({
      loading,
      onDeleteCampaign,
    }),
    [loading, onDeleteCampaign],
  );

  return (
    <DeleteCampaignContext.Provider value={value}>
      {children}
    </DeleteCampaignContext.Provider>
  );
};

DeleteCampaignProvider.useDeleteCampaign = () => useContext(DeleteCampaignContext);

export default DeleteCampaignProvider;
