import { useState } from 'react';
import { TableColumnsType, TableProps } from 'antd';

import { AsrConfig } from '../../../api/asrConfigs/types';
import { columnsInitialState } from '../constants/columnsInitialState';

const sticky: TableProps<AsrConfig>['sticky'] = { offsetHeader: 0 };

const scroll: TableProps<AsrConfig>['scroll'] = { x: 'max-content' };

const useTableConfiguration = () => {
  const [columns, setColumns] = useState<TableColumnsType<AsrConfig>>(columnsInitialState);

  return {
    columns,
    sticky,
    scroll,
    setColumns,
  };
};

export default useTableConfiguration;
