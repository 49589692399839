import { useNavigate as useNavigateBase } from 'react-router-dom';
import { useCallback } from 'react';

import { newPersonaId } from '../../constants';

const useNavigate = () => {
  const navigate = useNavigateBase();

  const navigateToSignIn = useCallback(() => navigate('/sign-in'), [navigate]);
  const navigateToMain = useCallback(() => navigate('/'), [navigate]);
  const navigateToPersonas = useCallback(() => navigate('/personas'), [navigate]);
  const navigateToCreatePersona = useCallback(() => navigate(`/personas/${newPersonaId}`), [navigate]);
  const navigateToEditPersona = useCallback((personaId: number) => navigate(`/personas/${personaId}`), [navigate]);

  return {
    navigateToMain,
    navigateToSignIn,
    navigateToPersonas,
    navigateToCreatePersona,
    navigateToEditPersona,
  };
};

export default useNavigate;
