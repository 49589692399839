import { useCallback, useState } from 'react';
import { AxiosError } from 'axios';
import { message } from 'antd';

import { trimCreateCustomerParams } from '../mappers';
import { CreateCustomerParams } from '../../../../../api/customers/types';

interface UseCreateCustomerModalLogicArgs {
  state: CreateCustomerParams;
  isValid: (state: CreateCustomerParams) => boolean;
  onConfirm: (params: CreateCustomerParams) => Promise<void>;
}

const useCreateCustomerModalLogic = ({
  state,
  isValid,
  onConfirm: onConfirmBase,
}: UseCreateCustomerModalLogicArgs) => {
  const [saving, setSaving] = useState(false);

  const onConfirm = useCallback(async () => {
    if (!isValid(state)) {
      return;
    }

    setSaving(true);

    try {
      await onConfirmBase(trimCreateCustomerParams(state));
    } catch (error) {
      console.error(error);

      if (error instanceof AxiosError) {
        message.open({ content: error.response?.data?.message, type: 'error', duration: 10 });
      }
    }

    setSaving(false);
  }, [isValid, state, onConfirmBase]);

  return {
    saving,
    onConfirm,
  };
};

export default useCreateCustomerModalLogic;
