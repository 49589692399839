import { useCallback } from 'react';
import { Button } from 'antd';

import CreateReferenceDataProvider from '../../providers/createReferenceDataProvider';

const CreateReferenceDataButton = () => {
  const createReferenceData = CreateReferenceDataProvider.useCreateReferenceData();

  const open = useCallback(() => {
    createReferenceData.open();
  }, [createReferenceData]);

  return (
    <Button
      className="ml-auto"
      type="primary"
      onClick={open}
    >
      Create reference data
    </Button>
  );
};

export default CreateReferenceDataButton;
