import { useCallback } from 'react';
import { Button, Tooltip } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import CreateReferenceDataFieldProvider from '../../providers/createReferenceDataFieldProvider';
import { ReferenceDataField } from '../../../../api/referenceDataFields/types';

interface EditReferenceDataFieldActionProps {
  referenceDataField: ReferenceDataField;
}

const EditReferenceDataFieldAction = ({
  referenceDataField,
}: EditReferenceDataFieldActionProps) => {
  const createReferenceDataField = CreateReferenceDataFieldProvider.useCreateReferenceDataField();

  const open = useCallback(() => {
    createReferenceDataField.openOnEdit(referenceDataField);
  }, [createReferenceDataField, referenceDataField]);

  return (
    <Tooltip title="Edit reference data field">
      <Button
        type="primary"
        ghost
        icon={<EditOutlined />}
        size="middle"
        onClick={open}
      />
    </Tooltip>
  );
};

export default EditReferenceDataFieldAction;
