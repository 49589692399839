import {
  useCallback,
  useEffect,
  useState,
} from 'react';

import { getCreateModalInitialState } from '../mappers';
import { CreateQuestionParams, Question } from '../../../../../api/questions/types';
import { CreateQuestionDialogRelatedData } from '../../../types';

interface UseCreateQuestionModalStateArgs {
  clearErrorForField: (propName: string) => void;
  initialQuestion: Question | null;
  relatedData: CreateQuestionDialogRelatedData | null;
  isOpen: boolean;
}

const useCreateQuestionModalState = ({
  isOpen,
  initialQuestion,
  relatedData,
  clearErrorForField,
}: UseCreateQuestionModalStateArgs) => {
  const [state, setState] = useState<CreateQuestionParams>(getCreateModalInitialState());

  const onPropertyUpdate = useCallback((
    propName: keyof CreateQuestionParams,
    value: CreateQuestionParams[keyof CreateQuestionParams],
  ) => {
    setState((oldState) => ({ ...oldState, [propName]: value }));
    clearErrorForField(propName);
  }, [clearErrorForField]);

  useEffect(() => {
    setState(getCreateModalInitialState(
      isOpen ? initialQuestion : null,
      isOpen ? relatedData : null,
    ));
  }, [initialQuestion, isOpen, relatedData]);

  const isEdit = !!initialQuestion;

  return {
    isEdit,
    state,
    onPropertyUpdate,
  };
};

export default useCreateQuestionModalState;
