import { ReferenceFieldTypes } from '../../../api/referenceDataFields/types';

export const referenceFieldTypeNames = {
  [ReferenceFieldTypes.int]: 'Integer',
  [ReferenceFieldTypes.str]: 'String',
  [ReferenceFieldTypes.float]: 'Float',
  [ReferenceFieldTypes.list]: 'List',
};

export default referenceFieldTypeNames;
