import { Button, Flex } from 'antd';

interface ColumnsSettingsFooterProps {
  onConfirm: () => void;
  onCancel: () => void;
  onResetToDefault: () => void;
}

const ColumnsSettingsFooter = ({
  onCancel,
  onConfirm,
  onResetToDefault,
}: ColumnsSettingsFooterProps) => (
  <Flex gap={8}>
    <Button danger className="mr-auto" onClick={onResetToDefault}>
      Reset to default
    </Button>
    <Button onClick={onCancel}>
      Cancel
    </Button>
    <Button type="primary" onClick={onConfirm}>
      Save
    </Button>
  </Flex>
);

export default ColumnsSettingsFooter;
