import {
  useCallback,
  useEffect,
  useState,
} from 'react';

import { getCreateModalInitialState } from '../mappers';
import { TtsConfig } from '../../../../../api/ttsConfigs/types';
import { CreateTtsConfigModalState } from '../types';

interface UseCreateTtsConfigModalStateArgs {
  clearErrorForField: (propName: string) => void;
  initialTtsConfig: TtsConfig | null;
  isOpen: boolean;
}

const useCreateTtsConfigModalState = ({
  isOpen,
  initialTtsConfig,
  clearErrorForField,
}: UseCreateTtsConfigModalStateArgs) => {
  const [state, setState] = useState<CreateTtsConfigModalState>(getCreateModalInitialState());

  const onPropertyUpdate = useCallback((
    propName: keyof CreateTtsConfigModalState,
    value: CreateTtsConfigModalState[keyof CreateTtsConfigModalState],
  ) => {
    setState((oldState) => ({ ...oldState, [propName]: value }));
    clearErrorForField(propName);
  }, [clearErrorForField]);

  useEffect(() => {
    setState(getCreateModalInitialState(isOpen ? initialTtsConfig : null));
  }, [initialTtsConfig, isOpen]);

  const isEdit = !!initialTtsConfig;

  return {
    isEdit,
    state,
    onPropertyUpdate,
  };
};

export default useCreateTtsConfigModalState;
