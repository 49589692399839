import { CreateReferenceDataFieldParams, ReferenceDataField } from '../../../../../api/referenceDataFields/types';

export const trimCreateReferenceDataFieldParams = (
  params: CreateReferenceDataFieldParams,
): CreateReferenceDataFieldParams => ({
  name: params.name?.trim(),
  campaignId: params.campaignId,
  type: params.type,
  choices: params.choices?.trim(),
});

export const getCreateModalInitialState = (
  initialReferenceDataField?: ReferenceDataField | null,
): CreateReferenceDataFieldParams => {
  if (!initialReferenceDataField) {
    return {
      name: '',
      type: null,
      campaignId: '',
      choices: '',
    };
  }

  return {
    name: initialReferenceDataField.referenceFieldName,
    type: initialReferenceDataField.referenceFieldType,
    campaignId: initialReferenceDataField.campaignId,
    choices: initialReferenceDataField.referenceFieldChoices ?? '',
  };
};
