import { useState } from 'react';
import { TableColumnsType, TableProps } from 'antd';

import { columnsInitialState } from '../constants/columnsInitialState';
import { Persona } from '../../../api/personas/types';

const sticky: TableProps<Persona>['sticky'] = { offsetHeader: 0 };

const scroll: TableProps<Persona>['scroll'] = { x: 'max-content' };

const useTableConfiguration = () => {
  const [columns, setColumns] = useState<TableColumnsType<Persona>>(columnsInitialState);

  return {
    columns,
    sticky,
    scroll,
    setColumns,
  };
};

export default useTableConfiguration;
