import { useCallback, useMemo } from 'react';
import {
  Empty,
  Flex,
  Select,
  Spin,
  Typography,
} from 'antd';
import usePaginatedDataFetchWithSearch from '@marrlab-app-shared/hooks/data/usePaginatedDataFetchWithSearch';

import { FilterProps } from '../../types';
import { getCampaignsWithTemplates } from '../../../../api/campaigns';

const CampaignsFilter = ({ filters, onFiltersUpdate }: FilterProps) => {
  const { updateSearch, loading, data } = usePaginatedDataFetchWithSearch({
    request: getCampaignsWithTemplates,
  });

  const onChange = useCallback((value?: number) => {
    onFiltersUpdate('campaign', value);
  }, [onFiltersUpdate]);

  const options = useMemo(() => (
    data?.map((item) => ({
      value: item.campaignId,
      label: item.displayName,
    })) ?? []
  ), [data]);

  return (
    <Flex vertical gap={8} className="w-64">
      <Typography.Text>Campaign</Typography.Text>
      <Select
        placeholder="Any campaign"
        value={filters.campaign}
        onChange={onChange}
        onSearch={updateSearch}
        loading={loading}
        options={options}
        allowClear
        showSearch
        filterOption={false}
        notFoundContent={loading ? (
          <Flex justify="center" align="center" className="h-20">
            <Spin />
          </Flex>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      />
    </Flex>
  );
};

export default CampaignsFilter;
