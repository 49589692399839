import { useState } from 'react';
import { TableColumnsType, TableProps } from 'antd';

import { columnsInitialState } from '../constants/columnsInitialState';
import { HumanTemplate } from '../../../api/humanTemplates/types';

const sticky: TableProps<HumanTemplate>['sticky'] = { offsetHeader: 0 };

const scroll: TableProps<HumanTemplate>['scroll'] = { x: 'max-content' };

const useTableConfiguration = () => {
  const [columns, setColumns] = useState<TableColumnsType<HumanTemplate>>(columnsInitialState);

  return {
    columns,
    sticky,
    scroll,
    setColumns,
  };
};

export default useTableConfiguration;
