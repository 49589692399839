import { TableColumnsType } from 'antd';

import { AgentProvider, OrchestratorProviderTypes } from '../../../api/agentProviders/types';
import LongTextCell from '../../../components/longTextCell';
import WebsocketCell from '../components/websocketCell';
import ActionsCell from '../components/actionsCell';
import StatusCell from '../../../components/statusCell';
import config from '../../../utils/config';

export const idColumnKey = 'agentProviderId';

export const emptyFilterValue = -1;
export const emptyStringFilterValue = '%%empty_value';

const agentTypeColumn: TableColumnsType<AgentProvider> = config.TESTING_TOOLS_ENABLED
  ? [{
    title: 'Agent type',
    dataIndex: 'personaId',
    key: 'personaId',
    sorter: true,
    width: 160,
    render: ((value: AgentProvider['personaId']) => (value ? 'Human agent' : 'Agent')),
  }]
  : [];

export const columnsInitialState: TableColumnsType<AgentProvider> = [
  {
    title: 'ID',
    dataIndex: idColumnKey,
    key: idColumnKey,
    fixed: 'left',
    sorter: true,
    sortOrder: 'descend',
    width: 80,
  },
  {
    title: 'Orchestrator provider ID',
    dataIndex: 'orchestratorProviderId',
    key: 'orchestratorProviderId',
    sorter: true,
    width: 140,
    align: 'end',
  },
  {
    title: 'Number assignment ID',
    dataIndex: 'numberAssignmentId',
    key: 'numberAssignmentId',
    sorter: true,
    width: 160,
    align: 'end',
    render: ((_, record) => record.orchestratorNumberAssignment?.orchestratorNumberAssignmentId),
  },
  ...agentTypeColumn,
  {
    title: 'Direction',
    dataIndex: 'orchestratorProviderDirection',
    key: 'orchestratorProviderDirection',
    sorter: true,
    width: 160,
    render: ((_, record) => {
      const direction = record.orchestratorProvider?.direction;

      if (direction === 0) {
        return 'Inbound';
      }
      if (direction === 1) {
        return 'Outbound';
      }

      return direction;
    }),
  },
  {
    title: 'Orchestrator number',
    dataIndex: 'numberAssignmentOrchestratorNumber',
    key: 'numberAssignmentOrchestratorNumber',
    sorter: true,
    width: 160,
    render: ((_, record) => record.orchestratorNumberAssignment?.orchestratorNumber),
  },
  {
    title: 'Origination number',
    dataIndex: 'numberAssignmentOriginationNumber',
    key: 'numberAssignmentOriginationNumber',
    sorter: true,
    width: 160,
    render: ((_, record) => record.orchestratorNumberAssignment?.originationNumber),
  },
  {
    title: 'Prompt parameters',
    dataIndex: 'numberAssignmentPromptParameters',
    key: 'numberAssignmentPromptParameters',
    sorter: true,
    width: 200,
    hidden: true,
    render: ((_, record) => (
      <LongTextCell
        value={record.orchestratorNumberAssignment?.agentParams
          ? JSON.stringify(record.orchestratorNumberAssignment.agentParams, null, 2)
          : undefined}
        title="Prompt parameters"
      />
    )),
  },
  {
    title: 'Status',
    dataIndex: 'active',
    key: 'active',
    sorter: true,
    width: 90,
    hidden: true,
    render: ((value: AgentProvider['active']) => <StatusCell value={value} />),
  },
  {
    title: 'Provider type',
    dataIndex: 'providerType',
    key: 'providerType',
    sorter: true,
    width: 140,
    hidden: true,
  },
  {
    title: 'API version',
    dataIndex: 'apiVersion',
    key: 'apiVersion',
    sorter: true,
    width: 180,
    hidden: true,
  },
  {
    title: 'Class',
    dataIndex: 'class',
    key: 'class',
    sorter: true,
    width: 140,
    hidden: true,
  },
  {
    title: 'LLM prompt',
    dataIndex: 'llmPrompt',
    key: 'llmPrompt',
    sorter: true,
    width: 200,
    render: ((value: AgentProvider['llmPrompt']) => <LongTextCell value={value} title="LLM propmp" />),
    hidden: true,
  },
  {
    title: 'Prompt',
    dataIndex: 'prompt',
    key: 'prompt',
    sorter: true,
    width: 200,
    render: ((value: AgentProvider['prompt']) => <LongTextCell value={value} title="Prompt" />),
    hidden: true,
  },
  {
    title: 'Multiple prompts',
    dataIndex: 'multiplePrompts',
    key: 'multiplePrompts',
    sorter: true,
    width: 200,
    render: ((value: AgentProvider['multiplePrompts']) => <LongTextCell value={value} title="Multiple prompts" />),
    hidden: true,
  },
  {
    title: 'Function tools',
    dataIndex: 'functionTools',
    key: 'functionTools',
    sorter: true,
    width: 200,
    render: ((value: AgentProvider['functionTools']) => (
      <LongTextCell
        value={value ? JSON.stringify(value, null, 2) : undefined}
        title="Function tools"
      />
    )),
    hidden: true,
  },
  {
    title: 'Integration ID',
    dataIndex: 'integrationId',
    key: 'integrationId',
    sorter: true,
    width: 160,
    align: 'end',
    hidden: true,
  },
  {
    title: 'Websocket',
    dataIndex: 'websocket',
    key: 'websocket',
    sorter: true,
    width: 300,
    render: (value?: string) => <WebsocketCell value={value} />,
    hidden: true,
  },
  {
    title: 'VectorDB config',
    dataIndex: 'vectorDbConfig',
    key: 'vectorDbConfig',
    sorter: true,
    width: 200,
    render: ((value: AgentProvider['vectorDbConfig']) => (
      <LongTextCell
        value={value ? JSON.stringify(value, null, 2) : undefined}
        title="VectorDB config"
      />
    )),
    hidden: true,
  },
  {
    title: 'Data model config',
    dataIndex: 'dataModelConfig',
    key: 'dataModelConfig',
    sorter: true,
    width: 200,
    render: ((value: AgentProvider['dataModelConfig']) => (
      <LongTextCell
        value={value ? JSON.stringify(value, null, 2) : undefined}
        title="Data model config"
      />
    )),
    hidden: true,
  },
  {
    title: 'Dynamic prompts',
    dataIndex: 'dynamicPrompts',
    key: 'dynamicPrompts',
    sorter: true,
    width: 200,
    render: ((value: AgentProvider['dynamicPrompts']) => (
      <LongTextCell
        value={value ? JSON.stringify(value, null, 2) : undefined}
        title="Dynamic prompts"
      />
    )),
    hidden: true,
  },
  {
    title: 'Embedding alias',
    dataIndex: 'embeddingAlias',
    key: 'embeddingAlias',
    sorter: true,
    width: 300,
    hidden: true,
  },
  {
    title: 'Data model alias',
    dataIndex: 'dataModelAlias',
    key: 'dataModelAlias',
    sorter: true,
    width: 300,
    hidden: true,
  },
  {
    title: 'Chat model alias',
    dataIndex: 'chatModelAlias',
    key: 'chatModelAlias',
    sorter: true,
    width: 300,
    hidden: true,
  },
  {
    title: 'Campaign ID',
    dataIndex: 'campaignId',
    key: 'campaignId',
    sorter: true,
    width: 140,
    render: ((_, record) => record.orchestratorProvider?.campaign?.campaignId),
    align: 'end',
    hidden: true,
  },
  {
    title: 'Campaign name',
    dataIndex: 'campaignDisplayName',
    key: 'campaignDisplayName',
    sorter: true,
    width: 200,
    render: ((_, record) => record.orchestratorProvider?.campaign?.displayName),
  },
  {
    title: 'Orchestrator provider type',
    dataIndex: 'orchestratorProviderType',
    key: 'orchestratorProviderType',
    sorter: true,
    width: 240,
    render: ((_, record) => record.orchestratorProvider?.providerType),
    hidden: false,
  },
  {
    title: 'Agent ID',
    dataIndex: 'orchestratorProviderAgentId',
    key: 'orchestratorProviderAgentId',
    sorter: true,
    width: 120,
    render: ((_, record) => record.orchestratorProvider?.config.AgentId),
  },
  {
    title: 'Voice ID',
    dataIndex: 'orchestratorProviderVoiceId',
    key: 'orchestratorProviderVoiceId',
    sorter: true,
    width: 120,
    render: ((_, record) => record.orchestratorProvider?.config.VoiceId),
    hidden: true,
  },
  {
    title: 'Agent name',
    dataIndex: 'orchestratorProviderAgentName',
    key: 'orchestratorProviderAgentName',
    sorter: true,
    width: 440,
    render: ((_, record) => record.orchestratorProvider?.config.AgentName),
  },
  {
    title: 'TTS config',
    dataIndex: 'orchestratorProviderTtsConfigId',
    key: 'orchestratorProviderTtsConfigId',
    sorter: true,
    width: 200,
    render: ((_, record) => (
      <LongTextCell
        value={record.orchestratorProvider?.ttsConfig
          ? JSON.stringify(record.orchestratorProvider.ttsConfig, null, 2)
          : undefined}
        title={`TTS config #${record.orchestratorProvider.ttsConfigId}`}
      />
    )),
    hidden: true,
  },
  {
    title: 'ASR config',
    dataIndex: 'orchestratorProviderAsrConfigId',
    key: 'orchestratorProviderAsrConfigId',
    sorter: true,
    width: 200,
    render: ((_, record) => (
      <LongTextCell
        value={record.orchestratorProvider?.asrConfig
          ? JSON.stringify(record.orchestratorProvider.asrConfig, null, 2)
          : undefined}
        title={`ASR config #${record.orchestratorProvider.asrConfigId}`}
      />
    )),
    hidden: true,
  },
  {
    title: 'Voice agent config',
    dataIndex: 'orchestratorProviderVoiceAgentConfigId',
    key: 'orchestratorProviderVoiceAgentConfigId',
    sorter: true,
    width: 200,
    render: ((_, record) => (
      <LongTextCell
        value={record.orchestratorProvider?.voiceAgentConfig
          ? JSON.stringify(record.orchestratorProvider.voiceAgentConfig, null, 2)
          : undefined}
        title={`Voice agent config #${record.orchestratorProvider.voiceAgentConfigId}`}
      />
    )),
    hidden: true,
  },
  {
    title: 'Deployment path',
    dataIndex: 'orchestratorProviderDeploymentPath',
    key: 'orchestratorProviderDeploymentPath',
    sorter: true,
    width: 200,
    render: ((_, record) => record.orchestratorProvider?.deploymentPath),
    hidden: true,
  },
  {
    title: 'Replicas',
    dataIndex: 'orchestratorProviderReplicas',
    key: 'orchestratorProviderReplicas',
    sorter: true,
    width: 120,
    render: ((_, record) => record.orchestratorProvider?.replicas),
    hidden: true,
  },
  {
    title: 'Deployed',
    dataIndex: 'numberAssignmentDeploy',
    key: 'numberAssignmentDeploy',
    sorter: true,
    width: 120,
    render: ((_, record) => {
      if (record.orchestratorProvider.providerType !== OrchestratorProviderTypes.vocode) {
        return '';
      }

      if (!record.orchestratorNumberAssignment) {
        return '';
      }

      return record.orchestratorNumberAssignment?.deploy ? 'Yes' : 'No';
    }),
    hidden: true,
  },
  {
    title: 'Actions',
    width: 224,
    fixed: 'right',
    render: ((_, record) => <ActionsCell agent={record} />),
  },
];
