import { useCallback } from 'react';
import { Button } from 'antd';

import CreateQuestionProvider from '../../providers/createQuestionProvider';

const CreateQuestionButton = () => {
  const createQuestion = CreateQuestionProvider.useCreateQuestion();

  const open = useCallback(() => {
    createQuestion.open();
  }, [createQuestion]);

  return (
    <Button
      className="ml-auto"
      type="primary"
      onClick={open}
    >
      Create question
    </Button>
  );
};

export default CreateQuestionButton;
