import {
  Modal,
  Form,
  Button,
  Flex,
} from 'antd';

import NameField from './components/nameField';
import CustomerField from './components/customerField';
import HumanReviewField from './components/humanReviewField';

import useCreateCampaignModalLogic from './hooks/useCreateCampaignModalLogic';
import useCreateCampaignModalState from './hooks/useCreateCampaignModalState';
import useCreateCampaignModalValidation from './hooks/useCreateCampaignModalValidation';
import { CreateCampaignParams, Campaign } from '../../../../api/campaigns/types';

import './styles.scss';

interface CreateCampaignModalProps {
  isOpen: boolean;
  initialCampaign: Campaign | null;
  onConfirm: (params: CreateCampaignParams) => Promise<void>;
  onCancel: () => void;
}

const CreateCampaignModal = ({
  isOpen,
  initialCampaign,
  onConfirm: onConfirmBase,
  onCancel: onCancelBase,
}: CreateCampaignModalProps) => {
  const {
    errors,
    isValid,
    clearErrorForField,
  } = useCreateCampaignModalValidation({ isOpen });

  const {
    isEdit,
    state,
    onPropertyUpdate,
  } = useCreateCampaignModalState({
    isOpen,
    initialCampaign,
    clearErrorForField,
  });

  const {
    saving,
    onConfirm,
    onCancel,
  } = useCreateCampaignModalLogic({
    state,
    isValid,
    onConfirm: onConfirmBase,
    onCancel: onCancelBase,
  });

  return (
    <Modal
      title={isEdit ? `Edit campaign #${initialCampaign?.campaignId}` : 'Create new campaign'}
      open={isOpen}
      onOk={onConfirm}
      onCancel={onCancel}
      width="600px"
      footer={(
        <Flex gap={8} justify="flex-end">
          <Button onClick={onCancel} disabled={saving}>
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={onConfirm}
            disabled={saving}
          >
            {isEdit ? 'Save' : 'Create'}
          </Button>
        </Flex>
      )}
    >
      <Form
        layout="vertical"
        className="py-4"
      >
        <NameField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={saving}
          error={errors.name}
        />
        <CustomerField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={saving}
          error={errors.customerId}
        />
        <HumanReviewField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={saving}
        />
      </Form>
    </Modal>
  );
};

export default CreateCampaignModal;
