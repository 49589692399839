import {
  useCallback,
  useEffect,
  useState,
} from 'react';

import { AgentProvider } from '../../../../../api/agentProviders/types';
import { getCreateModalInitialState } from '../mappers';
import { SCROLLABLE_CONTAINER_ID } from '../constants';
import { CreateAgentModalState } from '../types';
import { CreateAgentDialogPrefilledData } from '../../../types';

interface UseCreateAgentModalStateArgs {
  clearErrorForField: (propName: string) => void;
  prefilledData: CreateAgentDialogPrefilledData | null;
  initialAgent: AgentProvider | null;
  isOpen: boolean;
}

const useCreateAgentModalState = ({
  isOpen,
  prefilledData,
  initialAgent,
  clearErrorForField,
}: UseCreateAgentModalStateArgs) => {
  const [state, setState] = useState<CreateAgentModalState>(getCreateModalInitialState());

  const onPropertyUpdate = useCallback((
    propName: keyof CreateAgentModalState,
    value: CreateAgentModalState[keyof CreateAgentModalState],
  ) => {
    setState((oldState) => ({ ...oldState, [propName]: value }));
    clearErrorForField(propName);
  }, [clearErrorForField]);

  useEffect(() => {
    setState(getCreateModalInitialState(
      isOpen ? initialAgent : null,
      isOpen ? prefilledData : null,
    ));

    if (!isOpen) {
      const scrollableContainer = document.getElementById(SCROLLABLE_CONTAINER_ID);
      scrollableContainer?.scrollTo({ top: 0 });
    }
  }, [initialAgent, isOpen, prefilledData]);

  const isEdit = !!initialAgent;

  return {
    isEdit,
    state,
    onPropertyUpdate,
  };
};

export default useCreateAgentModalState;
