import { useCallback, useEffect, useState } from 'react';

import { CreateCustomerParams } from '../../../../../api/customers/types';
import { isAlphaNumericText, isEmptyString } from '../../../../../utils/validators';

type Errors = Partial<Record<keyof CreateCustomerParams, string>>;

interface UseCreateCustomerModalValidation {
  isOpen: boolean;
}

const initialState: Errors = {};

const useCreateCustomerModalValidation = ({ isOpen }: UseCreateCustomerModalValidation) => {
  const [errors, setErrors] = useState<Errors>(initialState);

  const isValid = useCallback((state: CreateCustomerParams) => {
    const newState = {
      name: isEmptyString(state.name),
      shortName: isAlphaNumericText(state.shortName),
    };

    setErrors(newState);

    return Object.values(newState).every((field) => !field);
  }, []);

  const clearErrorForField = useCallback((propName: string) => {
    setErrors((state) => ({
      ...state,
      [propName]: '',
    }));
  }, []);

  useEffect(() => {
    if (!isOpen) {
      setErrors(initialState);
    }
  }, [isOpen]);

  return {
    errors,
    isValid,
    clearErrorForField,
  };
};

export default useCreateCustomerModalValidation;
