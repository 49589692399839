import axios from 'axios';
import { Page } from '@marrlab-app-shared/types';

import {
  ReferenceDataField,
  CreateReferenceDataFieldParams,
  GetReferenceDataFieldsParams,
} from './types';
import config from '../../utils/config';

export const getReferenceDataFields = async (params: GetReferenceDataFieldsParams) => (
  axios.get<Page<ReferenceDataField>>(`${config.CALLS_HISTORY_API_BASE_URL}/api/reference-data-fields/`, { params })
);

export const createReferenceDataField = async (params: CreateReferenceDataFieldParams) => (
  axios.post<ReferenceDataField>(`${config.CALLS_HISTORY_API_BASE_URL}/api/reference-data-fields/`, params)
);

export const editReferenceDataField = async (
  id: number,
  params: CreateReferenceDataFieldParams,
) => (
  axios.put<ReferenceDataField>(`${config.CALLS_HISTORY_API_BASE_URL}/api/reference-data-fields/${id}`, params)
);

export const deleteReferenceDataField = async (id: number) => (
  axios.delete<void>(`${config.CALLS_HISTORY_API_BASE_URL}/api/reference-data-fields/${id}`)
);
