import { AgentProvider, CreateAgentParams, OrchestratorProviderTypes } from '../../../../../api/agentProviders/types';
import { doesAgentHasLlm, getLlmIdFromWebsocketUrl } from '../../../utils/websocket';
import { replaceAgentIdInLlmWebsocket } from '../utils/webhooks';
import { AgentType, CreateAgentModalState } from '../types';
import { CreateAgentDialogPrefilledData } from '../../../types';

export const trimCreateAgentParams = async (
  params: CreateAgentModalState,
): Promise<CreateAgentParams> => {
  const isHumanAgent = params.agentType === AgentType.humanAgent;
  const isVocodeProvider = params.orchestratorProviderType === OrchestratorProviderTypes.vocode;

  return ({
    apiVersion: params.apiVersion?.trim(),
    class: params.class?.trim(),
    functionTools: params.functionTools?.trim(),
    integrationId: params.integrationId?.trim(),
    personaId: isHumanAgent ? params.personaId : undefined,
    llmPrompt: params.llmPrompt?.trim(),
    multiplePrompts: params.multiplePrompts?.map((prompt) => prompt.trim()),
    prompt: params.prompt?.trim(),
    providerType: params.providerType?.trim(),
    vectorDbConfig: params.vectorDbConfig?.trim(),
    dataModelConfig: params.dataModelConfig?.trim(),
    dynamicPrompts: params.dynamicPrompts?.trim(),
    chatModelAlias: params.chatModelAlias?.trim(),
    dataModelAlias: params.dataModelAlias?.trim(),
    embeddingAlias: params.embeddingAlias?.trim(),

    campaignId: params.campaignId,
    orchestratorProviderType: params.orchestratorProviderType?.trim(),
    direction: params.direction,
    websocketBaseUrl: params.websocketBaseUrl?.trim(),
    agentName: params.agentName?.trim(),
    voiceId: params.voiceId?.trim(),
    voiceTemperature: params.voiceTemperature ?? undefined,
    voiceSpeed: params.voiceSpeed ?? undefined,
    responsiveness: params.responsiveness ?? undefined,
    interruptionSensitivity: params.interruptionSensitivity ?? undefined,
    enableBackchannel: params.enableBackchannel ?? undefined,
    backchannelFrequency: params.enableBackchannel
      ? params.backchannelFrequency ?? undefined
      : undefined,
    backchannelWords: params.enableBackchannel
      ? params.backchannelWords?.map((word) => word.trim())
      : undefined,
    reminderTrigger: params.reminderTrigger ?? undefined,
    reminderMaxCount: params.reminderMaxCount ?? undefined,
    boostedKeywords: params.boostedKeywords?.map((word) => word.trim()),
    pronunciationDictionary: params.pronunciationDictionary?.trim(),
    asrConfigId: isVocodeProvider ? params.asrConfigId : undefined,
    ttsConfigId: isVocodeProvider ? params.ttsConfigId : undefined,
    voiceAgentConfigId: isVocodeProvider ? params.voiceAgentConfigId : undefined,
    deploymentPath: isVocodeProvider ? params.deploymentPath?.trim() : undefined,
    replicas: isVocodeProvider ? params.replicas : undefined,

    copyRetellLlm: params.copyRetellLlm,
    llmId: params.llmId?.trim(),
  });
};

export const getCreateModalInitialState = (
  initialAgent?: AgentProvider | null,
  prefilledData?: CreateAgentDialogPrefilledData | null,
): CreateAgentModalState => {
  if (!initialAgent) {
    const isHumanAgent = prefilledData && prefilledData.agentType === AgentType.humanAgent;

    return {
      apiVersion: isHumanAgent ? prefilledData?.apiVersion : undefined,
      class: isHumanAgent ? prefilledData?.class : undefined,
      functionTools: undefined,
      integrationId: undefined,
      personaId: isHumanAgent ? prefilledData?.personaId : undefined,
      llmPrompt: isHumanAgent ? prefilledData?.llmPrompt : undefined,
      multiplePrompts: undefined,
      prompt: undefined,
      providerType: isHumanAgent ? prefilledData?.providerType : undefined,
      vectorDbConfig: undefined,
      dataModelConfig: undefined,
      dynamicPrompts: undefined,
      chatModelAlias: undefined,
      dataModelAlias: undefined,
      embeddingAlias: undefined,

      agentType: isHumanAgent ? AgentType.humanAgent : AgentType.agent,

      campaignId: isHumanAgent ? prefilledData?.campaignId : undefined,
      orchestratorProviderType: isHumanAgent ? prefilledData?.orchestratorProviderType : undefined,
      direction: undefined,
      websocketBaseUrl: undefined,
      agentName: undefined,
      voiceId: undefined,
      voiceTemperature: undefined,
      voiceSpeed: undefined,
      responsiveness: undefined,
      interruptionSensitivity: undefined,
      enableBackchannel: undefined,
      pronunciationDictionary: undefined,
      backchannelFrequency: undefined,
      backchannelWords: undefined,
      reminderTrigger: isHumanAgent ? prefilledData?.reminderTrigger : undefined,
      reminderMaxCount: isHumanAgent ? prefilledData?.reminderMaxCount : undefined,
      boostedKeywords: undefined,
      asrConfigId: undefined,
      ttsConfigId: undefined,
      voiceAgentConfigId: undefined,
      deploymentPath: undefined,
      replicas: undefined,

      copyRetellLlm: false,
      llmId: '',
    };
  }

  const { config } = initialAgent.orchestratorProvider;

  const hasLlm = doesAgentHasLlm(config.LlmWebsocket);

  return {
    apiVersion: initialAgent.apiVersion,
    class: initialAgent.class,
    functionTools: initialAgent.functionTools
      ? JSON.stringify(initialAgent.functionTools)
      : undefined,
    integrationId: initialAgent.integrationId,
    personaId: initialAgent.personaId ?? undefined,
    llmPrompt: initialAgent.llmPrompt,
    multiplePrompts: initialAgent.multiplePrompts,
    prompt: initialAgent.prompt,
    providerType: initialAgent.providerType,
    vectorDbConfig: initialAgent.vectorDbConfig
      ? JSON.stringify(initialAgent.vectorDbConfig)
      : undefined,
    dataModelConfig: initialAgent.dataModelConfig
      ? JSON.stringify(initialAgent.dataModelConfig)
      : undefined,
    dynamicPrompts: initialAgent.dynamicPrompts
      ? JSON.stringify(initialAgent.dynamicPrompts)
      : undefined,
    chatModelAlias: initialAgent.chatModelAlias,
    dataModelAlias: initialAgent.dataModelAlias,
    embeddingAlias: initialAgent.embeddingAlias,

    agentType: initialAgent.personaId ? AgentType.humanAgent : AgentType.agent,

    campaignId: initialAgent.orchestratorProvider.campaign?.campaignId,
    orchestratorProviderType: initialAgent.orchestratorProvider.providerType,
    direction: initialAgent.orchestratorProvider.direction,
    websocketBaseUrl: replaceAgentIdInLlmWebsocket(config.LlmWebsocket),
    agentName: config.AgentName,
    voiceId: config.VoiceId,
    voiceTemperature: config.voice_temperature,
    voiceSpeed: config.voice_speed,
    responsiveness: config.responsiveness,
    interruptionSensitivity: config.interruption_sensitivity,
    enableBackchannel: config.enable_backchannel,
    pronunciationDictionary: config.pronunciation_dictionary
      ? JSON.stringify(config.pronunciation_dictionary)
      : undefined,
    backchannelFrequency: config.backchannel_frequency,
    backchannelWords: config.backchannel_words,
    reminderTrigger: config.reminder_trigger_ms,
    reminderMaxCount: config.reminder_max_count,
    boostedKeywords: config.boosted_keywords,
    asrConfigId: initialAgent.orchestratorProvider.asrConfigId,
    ttsConfigId: initialAgent.orchestratorProvider.ttsConfigId,
    voiceAgentConfigId: initialAgent.orchestratorProvider.voiceAgentConfigId,
    deploymentPath: initialAgent.orchestratorProvider.deploymentPath,
    replicas: initialAgent.orchestratorProvider.replicas,

    copyRetellLlm: hasLlm,
    llmId: hasLlm ? getLlmIdFromWebsocketUrl(config.LlmWebsocket) : '',
  };
};
