import { useEffect, useRef } from 'react';
import { createJSONEditor, JSONEditorPropsOptional } from 'vanilla-jsoneditor';
import cn from 'classnames';

import './index.scss';

type JsonEditorType = ReturnType<typeof createJSONEditor>;

interface JsonEditorProps extends JSONEditorPropsOptional {
  className?: string;
}

const JsonEditor = ({
  className,
  ...jsonEditorProps
}: JsonEditorProps) => {
  const refContainer = useRef<HTMLDivElement | null>(null);
  const refEditor = useRef<JsonEditorType | null>(null);

  useEffect(() => {
    if (refContainer.current) {
      refEditor.current = createJSONEditor({
        target: refContainer.current,
        props: {},
      });
    }

    return () => {
      if (refEditor.current) {
        refEditor.current.destroy();
        refEditor.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (refEditor.current) {
      refEditor.current.updateProps(jsonEditorProps);
    }
  }, [jsonEditorProps]);

  return (
    <div
      className={cn('vanilla-json-editor-wrapper', className)}
      ref={refContainer}
    />
  );
};

export default JsonEditor;
