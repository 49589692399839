import { ChangeEvent, useCallback } from 'react';
import { Form, Input, Typography } from 'antd';

import { FieldProps } from '../../types';

const WebsocketBaseUrlField = ({
  state,
  disabled,
  error,
  onUpdate,
}: FieldProps) => {
  const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    onUpdate('websocketBaseUrl', event.target.value || undefined);
  }, [onUpdate]);

  return (
    <Form.Item
      className="field-with-info-tooltip"
      label="LLM websocket base URL"
      tooltip={{
        rootClassName: 'wide-tooltip',
        title: (
          <Typography.Text className="text-current">
            Accepts baseUrl
            {' '}
            <Typography.Text strong className="text-current">without</Typography.Text>
            {' '}
            agentID and
            {' '}
            <Typography.Text strong className="text-current">with</Typography.Text>
            {' '}
            ending slash. Example:
            <br />
            <Typography.Text code className="code-on-black-background">
              ws://marrlabs-server.eastus.cloudapp.azure.com/llm-websocket/
            </Typography.Text>
          </Typography.Text>
        ),
      }}
      validateStatus={error ? 'error' : undefined}
      help={error}
    >
      <Input
        value={state.websocketBaseUrl}
        onChange={onChange}
        disabled={disabled}
      />
    </Form.Item>
  );
};

export default WebsocketBaseUrlField;
