import { useCallback } from 'react';
import { Button } from 'antd';

import CreateCampaignProvider from '../../providers/createCampaignsProvider';

const CreateCampaignButton = () => {
  const createCampaign = CreateCampaignProvider.useCreateCampaign();

  const open = useCallback(() => {
    createCampaign.open();
  }, [createCampaign]);

  return (
    <Button
      type="primary"
      onClick={open}
    >
      Create campaign
    </Button>
  );
};

export default CreateCampaignButton;
