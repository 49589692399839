import { SortOrder } from '../../constants';
import { Campaign } from '../campaigns/types';

export enum ReferenceFieldTypes {
  str = 'str',
  int = 'int',
  float = 'float',
  list = 'list',
}

export interface ReferenceDataField {
  referenceFieldId: number;
  referenceFieldName: string;
  referenceFieldType: ReferenceFieldTypes;
  referenceFieldChoices: string | null;
  campaignId: string;
  campaign: Campaign;
}

export interface GetReferenceDataFieldsParams {
  take?: number;
  skip?: number;
  sortBy?: keyof ReferenceDataField;
  order?: SortOrder;
  search?: string;
  campaign?: string;
}

export interface CreateReferenceDataFieldParams {
  name: string;
  type: ReferenceFieldTypes | null;
  choices?: string;
  campaignId: string;
}
