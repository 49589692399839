import { useCallback } from 'react';
import { Form, InputNumber } from 'antd';

import { FieldProps } from '../../types';

const AreaCodeField = ({
  state,
  disabled,
  error,
  onUpdate,
}: FieldProps) => {
  const onChange = useCallback((value: number | null) => {
    onUpdate('areaCode', value ?? undefined);
  }, [onUpdate]);

  return (
    <Form.Item
      label="Area code"
      required
      validateStatus={error ? 'error' : undefined}
      help={error}
    >
      <InputNumber
        placeholder="415"
        value={state.areaCode}
        onChange={onChange}
        disabled={disabled}
        min={100}
        max={999}
      />
    </Form.Item>
  );
};

export default AreaCodeField;
