import { useCallback } from 'react';
import { Form, InputNumber } from 'antd';

import { FieldProps } from '../../types';

const ReplicasField = ({
  state,
  disabled,
  onUpdate,
}: FieldProps) => {
  const onChange = useCallback((value: number | null) => {
    onUpdate('replicas', value ?? undefined);
  }, [onUpdate]);

  return (
    <Form.Item label="Replicas">
      <InputNumber
        placeholder="1"
        value={state.replicas}
        onChange={onChange}
        disabled={disabled}
        min={0}
        step={1}
      />
    </Form.Item>
  );
};

export default ReplicasField;
