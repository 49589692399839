import {
  useCallback,
  useEffect,
  useState,
} from 'react';

import { getCreateModalInitialState } from '../mappers';
import { CreateCustomerParams, Customer } from '../../../../../api/customers/types';

interface UseCreateCustomerModalStateArgs {
  clearErrorForField: (propName: string) => void;
  initialCustomer: Customer | null;
  isOpen: boolean;
}

const useCreateCustomerModalState = ({
  isOpen,
  initialCustomer,
  clearErrorForField,
}: UseCreateCustomerModalStateArgs) => {
  const [state, setState] = useState<CreateCustomerParams>(getCreateModalInitialState());

  const onPropertyUpdate = useCallback((
    propName: keyof CreateCustomerParams,
    value: CreateCustomerParams[keyof CreateCustomerParams],
  ) => {
    setState((oldState) => ({ ...oldState, [propName]: value }));
    clearErrorForField(propName);
  }, [clearErrorForField]);

  useEffect(() => {
    setState(getCreateModalInitialState(isOpen ? initialCustomer : null));
  }, [initialCustomer, isOpen]);

  const isEdit = !!initialCustomer;

  return {
    isEdit,
    state,
    onPropertyUpdate,
  };
};

export default useCreateCustomerModalState;
