import { ChangeEvent, useCallback } from 'react';
import { Form, Input } from 'antd';

import { FieldProps } from '../../types';

const NameField = ({
  state,
  disabled,
  error,
  onUpdate,
}: FieldProps) => {
  const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    onUpdate('templateName', event.target.value);
  }, [onUpdate]);

  return (
    <Form.Item
      label="Template name"
      required
      validateStatus={error ? 'error' : undefined}
      help={error}
    >
      <Input
        value={state.templateName}
        onChange={onChange}
        disabled={disabled}
        maxLength={80}
        showCount
      />
    </Form.Item>
  );
};

export default NameField;
