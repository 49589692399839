import { Flex } from 'antd';

import EditQuestionAction from '../editQuestionAction';
import DeleteQuestionAction from '../deleteQuestionAction';
import { Question } from '../../../../api/questions/types';

interface ActionsCellProps {
  question: Question;
}

const ActionsCell = ({ question }: ActionsCellProps) => (
  <Flex gap={8}>
    <EditQuestionAction question={question} />
    <DeleteQuestionAction question={question} />
  </Flex>
);

export default ActionsCell;
