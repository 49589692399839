import { useCallback, useEffect, useState } from 'react';
import { message } from 'antd';
import { AxiosError } from 'axios';
import { Page } from '@marrlab-app-shared/types';

import { AsrConfig, GetAsrConfigParams } from '../../../api/asrConfigs/types';
import { getAsrConfigs } from '../../../api/asrConfigs';

const useAsrConfigsData = ({
  skip,
  take,
  sortBy,
  order,
  search,
}: GetAsrConfigParams) => {
  const [data, setData] = useState<Page<AsrConfig> | null>(null);
  const [loading, setLoading] = useState(false);

  const fetchAsrConfigs = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getAsrConfigs({
        skip,
        take,
        sortBy,
        order,
        search,
      });

      setData(response.data);
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        message.open({ content: error.message, type: 'error', duration: 10 });
      }
    }

    setLoading(false);
  }, [order, search, skip, sortBy, take]);

  useEffect(() => {
    fetchAsrConfigs();
  }, [fetchAsrConfigs]);

  return {
    data,
    loading,
    fetchAsrConfigs,
  };
};

export default useAsrConfigsData;
