import { CreatePersonaModalState, PlaceholderValuesRawError } from '../types';
import { Placeholder } from '../../../api/common/types';

export const validatePlaceholderValues = (
  placeholders: Placeholder[],
  placeholderValues: CreatePersonaModalState['placeholderValuesRaw'],
): PlaceholderValuesRawError | undefined => {
  const errors = placeholders.reduce((acc, item) => {
    const key = item.placeholderId.toString();
    const value = placeholderValues[key]?.trim();

    if (!value) {
      acc[key] = 'This field is mandatory.';
    }

    return acc;
  }, {} as PlaceholderValuesRawError);

  const isValid = Object.values(errors).every((field) => !field);

  if (isValid) {
    return undefined;
  }

  return errors;
};

export default validatePlaceholderValues;
