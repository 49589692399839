import { ChangeEvent, useCallback } from 'react';
import { Form, Input, Typography } from 'antd';

import { FieldProps } from '../../types';

const AgentNameField = ({
  state,
  disabled,
  error,
  onUpdate,
}: FieldProps) => {
  const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    onUpdate('agentName', event.target.value);
  }, [onUpdate]);

  return (
    <Form.Item
      className="field-with-info-tooltip"
      label="Agent name"
      tooltip={{
        rootClassName: 'wide-tooltip',
        title: (
          <Typography.Text className="text-current">
            The agent name must be different from the original one and should have the
            following format:
            <br />
            <Typography.Text code className="code-on-black-background">
              [Dev|Prod]-Month_Day(Optional)_Year-Inbd|Outbd-CustomerName-DescriptiveName
            </Typography.Text>
          </Typography.Text>
        ),
      }}
      required
      validateStatus={error ? 'error' : undefined}
      help={error}
    >
      <Input
        placeholder="[Dev|Prod]-Month_Day(Optional)_Year-Inbd|Outbd-CustomerName-DescriptiveName"
        value={state.agentName}
        onChange={onChange}
        disabled={disabled}
        maxLength={80}
        showCount
      />
    </Form.Item>
  );
};

export default AgentNameField;
