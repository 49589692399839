import { useCallback, useMemo } from 'react';
import {
  Empty,
  Flex,
  Form,
  Select,
  Spin,
} from 'antd';
import usePaginatedDataFetchWithSearch from '@marrlab-app-shared/hooks/data/usePaginatedDataFetchWithSearch';

import { FieldProps } from '../../types';
import { getCampaignsWithTemplates } from '../../../../api/campaigns';

const CampaignField = ({
  state,
  disabled,
  error,
  onUpdate,
}: FieldProps) => {
  const { updateSearch, loading, data } = usePaginatedDataFetchWithSearch({
    request: getCampaignsWithTemplates,
  });

  const onChange = useCallback((value: string) => {
    onUpdate('campaignId', value);
  }, [onUpdate]);

  const options = useMemo(() => (
    data?.map((item) => ({
      value: item.campaignId,
      label: item.displayName,
    })) ?? []
  ), [data]);

  return (
    <Form.Item
      label="Campaign"
      required
      validateStatus={error ? 'error' : undefined}
      help={error}
    >
      <Select
        value={state.campaignId}
        onChange={onChange}
        onSearch={updateSearch}
        loading={loading}
        options={options}
        allowClear
        showSearch
        filterOption={false}
        notFoundContent={loading ? (
          <Flex justify="center" align="center" className="h-20">
            <Spin />
          </Flex>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
        disabled={disabled}
      />
    </Form.Item>
  );
};

export default CampaignField;
