import { useCallback } from 'react';
import { Form, InputNumber } from 'antd';

import { FieldProps } from '../../types';

const ResponsivenessField = ({
  state,
  disabled,
  onUpdate,
}: FieldProps) => {
  const onChange = useCallback((value: number | null) => {
    onUpdate('responsiveness', value ?? undefined);
  }, [onUpdate]);

  return (
    <Form.Item
      className="field-with-info-tooltip"
      label="Responsiveness"
      tooltip="Controls how responsive is the agent. Value ranging from [0,1]. Lower value means less responsive agent (wait more, respond slower), while higher value means faster exchanges (respond when it can). If unset, default value 1 will apply."
    >
      <InputNumber
        placeholder="1.00"
        value={state.responsiveness}
        onChange={onChange}
        disabled={disabled}
        min={0}
        max={1}
        precision={2}
        step={0.1}
      />
    </Form.Item>
  );
};

export default ResponsivenessField;
