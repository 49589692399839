import { useCallback, useState } from 'react';
import { AxiosError } from 'axios';
import { message } from 'antd';

import { AgentProvider, DuplicateAgentParams } from '../../../../../api/agentProviders/types';

interface UseDuplicateAgentModalLogicArgs {
  agentName: string;
  agentToDuplicate: AgentProvider | null;
  onConfirm: (params: DuplicateAgentParams) => Promise<void>;
  isValid: (agentName: string) => boolean;
}

const useDuplicateAgentModalLogic = ({
  agentName,
  agentToDuplicate,
  isValid,
  onConfirm: onConfirmBase,
}: UseDuplicateAgentModalLogicArgs) => {
  const [saving, setSaving] = useState(false);

  const onConfirm = useCallback(async () => {
    if (!agentToDuplicate || !isValid(agentName)) {
      return;
    }

    setSaving(true);

    try {
      await onConfirmBase({
        agentName: agentName.trim(),
        agentProviderId: agentToDuplicate.agentProviderId,
        orchestratorProviderId: agentToDuplicate.orchestratorProviderId,
      });
    } catch (error) {
      console.error(error);

      if (error instanceof AxiosError) {
        message.open({ content: error.response?.data?.message, type: 'error', duration: 10 });
      }
    }

    setSaving(false);
  }, [agentName, agentToDuplicate, isValid, onConfirmBase]);

  return {
    saving,
    onConfirm,
  };
};

export default useDuplicateAgentModalLogic;
