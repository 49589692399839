import { ChangeEvent, useCallback } from 'react';
import { Form, Input } from 'antd';

import { FieldProps } from '../../types';

const LlmIdField = ({
  state,
  disabled,
  error,
  onUpdate,
}: FieldProps) => {
  const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    onUpdate('llmId', event.target.value || undefined);
  }, [onUpdate]);

  return (
    <Form.Item
      label="LLM ID"
      validateStatus={error ? 'error' : undefined}
      help={error}
      tooltip="Retell LLM with that id will be copied and associated with new agent"
    >
      <Input
        value={state.llmId}
        onChange={onChange}
        disabled={disabled}
      />
    </Form.Item>
  );
};

export default LlmIdField;
