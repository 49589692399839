import { SortOrder } from '../../constants';
import { OrchestratorNumberAssignment, OrchestratorProvider, PromptParameters } from '../common/types';

export interface AgentProvider {
  agentProviderId: string;
  orchestratorProviderId: string;
  orchestratorProvider: OrchestratorProvider;
  providerType: string;
  apiVersion: string;
  class: string;
  llmPrompt: string;
  prompt: string;
  multiplePrompts: string[];
  functionTools: Record<string, string | number>;
  integrationId: string;
  personaId: number | null;
  websocket: string;
  vectorDbConfig: Record<string, string | number>;
  dataModelConfig: Record<string, string | number>;
  dynamicPrompts: Record<string, string>;
  chatModelAlias?: string;
  dataModelAlias?: string;
  embeddingAlias?: string;
  active: boolean;
  orchestratorNumberAssignment: OrchestratorNumberAssignment;
}

export enum OrchestratorProviderTypes {
  vocode = 'vocode',
  retell = 'retell',
}

export interface GetAgentProvidersParams {
  take: number;
  skip: number;
  sortBy: keyof AgentProvider;
  order: SortOrder;
  search: string;
  campaign?: number;
  functionTools?: boolean;
  integration?: boolean;
  persona?: boolean;
  vectorDbConfig?: boolean;
  deploy?: boolean;
  apiVersion?: string;
  providerType?: string;
  orchestratorProviderType?: OrchestratorProviderTypes;
  agentName?: string;
  agentStatus?: string;
  numberAssignment?: boolean;
  searchBy?: string;
}

export interface GetSearchableDataParams {
  search: string;
}

export interface DuplicateAgentParams {
  agentProviderId: string;
  agentName: string;
  orchestratorProviderId: string;
}

export interface CreateAgentParams {
  providerType?: string;
  apiVersion?: string;
  class?: string;
  llmPrompt?: string;
  prompt?: string;
  multiplePrompts?: string[];
  functionTools?: string;
  integrationId?: string;
  personaId?: number;
  vectorDbConfig?: string;
  dataModelConfig?: string;
  dynamicPrompts?: string;
  chatModelAlias?: string;
  dataModelAlias?: string;
  embeddingAlias?: string;

  // orchestrator provider fields
  campaignId?: number;
  orchestratorProviderType?: string;
  direction?: number;
  websocketBaseUrl?: string;
  agentName?: string;
  voiceId?: string;
  asrConfigId?: number;
  ttsConfigId?: number;
  voiceAgentConfigId?: number;
  deploymentPath?: string;
  replicas?: number;

  voiceTemperature?: number;
  voiceSpeed?: number;
  responsiveness?: number;
  interruptionSensitivity?: number;
  enableBackchannel?: boolean;
  pronunciationDictionary?: string;
  backchannelFrequency?: number;
  backchannelWords?: string[];
  reminderTrigger?: number;
  reminderMaxCount?: number;
  boostedKeywords?: string[];

  copyRetellLlm?: boolean;
  llmId?: string;
}

export interface AssignNumberParams {
  promptParameters?: PromptParameters[];
  originationNumber: string;
  orchestratorProviderId: number;
  agentProviderId: number;
  areaCode?: number;
  createTwilioNumber: boolean;
}

export interface DeployAgentParams {
  orchestratorNumberAssignmentId: number;
  deploy: boolean;
}
