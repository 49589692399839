import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { AxiosError } from 'axios';
import { message, Typography } from 'antd';
import SuccessNotificationProvider from '@marrlab-app-shared/components/successNotification';

import DuplicateAgentModal from '../../components/duplicateAgentModal';
import { AgentProvider, DuplicateAgentParams } from '../../../../api/agentProviders/types';
import { duplicateAgent } from '../../../../api/agentProviders';

interface DuplicateAgentContextType {
  open: (agent: AgentProvider) => void;
}

const initialState: DuplicateAgentContextType = {
  open: () => {
  },
};

const DuplicateAgentContext = createContext<DuplicateAgentContextType>(initialState);

interface DuplicateAgentProviderProps extends PropsWithChildren {
  fetchAgentProviders: () => Promise<void>;
}

const DuplicateAgentProvider = ({ children, fetchAgentProviders }: DuplicateAgentProviderProps) => {
  const successNotification = SuccessNotificationProvider.useSuccessNotification();

  const [isOpen, setIsOpen] = useState(false);
  const [agentToDuplicate, setAgentToDuplicate] = useState<AgentProvider | null>(null);

  const close = useCallback(() => setIsOpen(false), []);

  const open = useCallback((agent: AgentProvider) => {
    setAgentToDuplicate(agent);
    setIsOpen(true);
  }, []);

  const onConfirm = useCallback(async (params: DuplicateAgentParams) => {
    try {
      const response = await duplicateAgent(params);

      successNotification.open({
        key: response.data.agentProviderId,
        title: 'Agent duplicated successfully',
        message: (
          <>
            <Typography.Text strong>Agent provider ID: </Typography.Text>
            <Typography.Text>{response.data.agentProviderId}</Typography.Text>
            <br />
            <Typography.Text strong>Orchestrator provider ID: </Typography.Text>
            <Typography.Text>{response.data.orchestratorProviderId}</Typography.Text>
          </>
        ),
      });

      await fetchAgentProviders();

      close();
      setAgentToDuplicate(null);
    } catch (error) {
      if (error instanceof AxiosError) {
        message.open({ content: error.response?.data?.message, type: 'error', duration: 10 });
      }
    }
  }, [close, fetchAgentProviders, successNotification]);

  const value: DuplicateAgentContextType = useMemo(
    () => ({
      open,
    }),
    [open],
  );

  return (
    <DuplicateAgentContext.Provider value={value}>
      {children}
      <DuplicateAgentModal
        isOpen={isOpen}
        agentToDuplicate={agentToDuplicate}
        onCancel={close}
        onConfirm={onConfirm}
      />
    </DuplicateAgentContext.Provider>
  );
};

DuplicateAgentProvider.useDuplicateAgent = () => useContext(DuplicateAgentContext);

export default DuplicateAgentProvider;
