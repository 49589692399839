import { Modal, Form } from 'antd';
import ModalFooter from '@marrlab-app-shared/components/modalFooter';

import ShortNameField from './components/shortNameField';

import useCreateTtsConfigModalLogic from './hooks/useCreateTtsConfigModalLogic';
import useCreateTtsConfigModalState from './hooks/useCreateTtsConfigModalState';
import useCreateTtsConfigModalValidation from './hooks/useCreateTtsConfigModalValidation';
import { CreateTtsConfigParams, TtsConfig } from '../../../../api/ttsConfigs/types';
import TtsConfigField from './components/ttsConfigField';

interface CreateTtsConfigModalProps {
  isOpen: boolean;
  initialTtsConfig: TtsConfig | null;
  onConfirm: (params: CreateTtsConfigParams) => Promise<void>;
  onCancel: () => void;
}

const CreateTtsConfigModal = ({
  isOpen,
  initialTtsConfig,
  onConfirm: onConfirmBase,
  onCancel,
}: CreateTtsConfigModalProps) => {
  const {
    errors,
    isValid,
    clearErrorForField,
  } = useCreateTtsConfigModalValidation({ isOpen });

  const {
    isEdit,
    state,
    onPropertyUpdate,
  } = useCreateTtsConfigModalState({
    isOpen,
    initialTtsConfig,
    clearErrorForField,
  });

  const { saving, onConfirm } = useCreateTtsConfigModalLogic({
    state,
    isValid,
    onConfirm: onConfirmBase,
  });

  return (
    <Modal
      title={isEdit ? `Edit TTS config #${initialTtsConfig?.ttsConfigId}` : 'Create TTS config'}
      open={isOpen}
      onOk={onConfirm}
      onCancel={onCancel}
      width="600px"
      footer={(
        <ModalFooter
          disabled={saving}
          saveButtonLabel={isEdit ? 'Save' : 'Create'}
          onCancel={onCancel}
          onConfirm={onConfirm}
        />
      )}
    >
      <Form
        layout="vertical"
        className="py-4"
      >
        <ShortNameField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={saving}
          error={errors.shortName}
        />
        <TtsConfigField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={saving}
          error={errors.config}
        />
      </Form>
    </Modal>
  );
};

export default CreateTtsConfigModal;
