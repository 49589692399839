import { useCallback } from 'react';
import { Button, Tooltip } from 'antd';
import { PhoneOutlined } from '@ant-design/icons';

import AssignNumberProvider from '../../../agentProviders/providers/assignNumberProvider';
import { AgentProvider } from '../../../../api/agentProviders/types';

interface AssignNumberButtonProps {
  agent: AgentProvider;
}

const AssignNumberButton = ({ agent }: AssignNumberButtonProps) => {
  const assignNumber = AssignNumberProvider.useAssignNumber();

  const isInactive = !agent.active;
  const hasNumberAssigned = !!agent.orchestratorNumberAssignment;

  const isDisabled = isInactive || hasNumberAssigned;

  const open = useCallback(() => {
    assignNumber.open(agent);
  }, [agent, assignNumber]);

  let tooltip = '';

  if (isInactive) {
    tooltip = 'The agent marked as archived';
  }

  if (hasNumberAssigned) {
    tooltip = 'Agent has number assigned';
  }

  return (
    <Tooltip title={tooltip}>
      <Button
        type="primary"
        icon={<PhoneOutlined />}
        size="middle"
        onClick={open}
        disabled={isDisabled}
      >
        Assign phone number
      </Button>
    </Tooltip>
  );
};

export default AssignNumberButton;
