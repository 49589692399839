import { useCallback } from 'react';
import { Switch, Form } from 'antd';

import { FieldProps } from '../../types';

const HumanReviewField = ({
  state,
  disabled,
  onUpdate,
}: FieldProps) => {
  const onChange = useCallback((checked: boolean) => {
    onUpdate('humanReview', checked);
  }, [onUpdate]);

  return (
    <Form.Item
      label="Human review"
      required
    >
      <Switch
        checked={state.humanReview}
        onChange={onChange}
        disabled={disabled}
      />
    </Form.Item>
  );
};

export default HumanReviewField;
