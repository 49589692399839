import { useCallback } from 'react';
import { Form, Select } from 'antd';

import { FieldProps } from '../../types';
import { QuestionFailValue } from '../../../../../../api/questions/types';

const options = [
  { label: 'Yes', value: QuestionFailValue.yes },
  { label: 'No', value: QuestionFailValue.no },
];

const FailValueField = ({
  state,
  disabled,
  onUpdate,
}: FieldProps) => {
  const onChange = useCallback((value: QuestionFailValue | null) => {
    onUpdate('failValue', value);
  }, [onUpdate]);

  return (
    <Form.Item label="Fail value">
      <Select
        value={state.failValue}
        options={options}
        onChange={onChange}
        disabled={disabled}
        allowClear
      />
    </Form.Item>
  );
};

export default FailValueField;
