import { useCallback } from 'react';
import { Form, InputNumber } from 'antd';

import { FieldProps } from '../../types';

const BackchannelFrequencyField = ({
  state,
  disabled,
  onUpdate,
}: FieldProps) => {
  const onChange = useCallback((value: number | null) => {
    onUpdate('backchannelFrequency', value ?? undefined);
  }, [onUpdate]);

  return (
    <Form.Item
      className="field-with-info-tooltip"
      label="Backchannel frequency"
      tooltip="Only applicable when enable_backchannel is true. Controls how often the agent would backchannel when a backchannel is possible. Value ranging from [0,1]. Lower value means less frequent backchannel, while higher value means more frequent backchannel. If unset, default value 0.8 will apply."
    >
      <InputNumber
        placeholder="0.80"
        value={state.backchannelFrequency}
        onChange={onChange}
        disabled={disabled}
        min={0}
        max={1}
        precision={2}
        step={0.1}
      />
    </Form.Item>
  );
};

export default BackchannelFrequencyField;
