import { Modal, Form } from 'antd';
import ModalFooter from '@marrlab-app-shared/components/modalFooter';

import AgentProviderField from './components/agentProviderField';
import OrchestratorProviderField from './components/orchestratorProviderField';
import AreaCodeField from './components/areaCodeField';
import OriginationPhoneField from './components/originationNumberField';
import PromptParamsField from './components/promptParamsField';
import CreateTwilioNumberField from './components/createTwilioNumberField';

import useAssignNumberModalLogic from './hooks/useAssignNumberModalLogic';
import useAssignNumberModalState from './hooks/useAssignNumberModalState';
import useAssignNumberModalValidation from './hooks/useAssignNumberModalValidation';
import { AgentProvider, AssignNumberParams } from '../../../../api/agentProviders/types';
import { SCROLLABLE_CONTAINER_ID } from './constants';

import './index.scss';

interface AssignNumberModalProps {
  isOpen: boolean;
  initialAgent: AgentProvider | null;
  onConfirm: (params: AssignNumberParams) => Promise<void>;
  onCancel: () => void;
}

const AssignNumberModal = ({
  isOpen,
  initialAgent,
  onConfirm: onConfirmBase,
  onCancel,
}: AssignNumberModalProps) => {
  const {
    errors,
    isValid,
    clearErrorForField,
  } = useAssignNumberModalValidation({ isOpen });

  const {
    state,
    onPropertyUpdate,
  } = useAssignNumberModalState({
    isOpen,
    initialAgent,
    clearErrorForField,
  });

  const { saving, onConfirm } = useAssignNumberModalLogic({
    state,
    isValid,
    onConfirm: onConfirmBase,
  });

  return (
    <Modal
      title={`Assign number to agent #${initialAgent?.agentProviderId}`}
      open={isOpen}
      onOk={onConfirm}
      onCancel={onCancel}
      width="800px"
      footer={(
        <ModalFooter
          disabled={saving}
          saveButtonLabel="Assign"
          onCancel={onCancel}
          onConfirm={onConfirm}
        />
      )}
    >
      <Form
        id={SCROLLABLE_CONTAINER_ID}
        layout="vertical"
        className="assign-number-modal-scrollable-area py-4"
      >
        <AgentProviderField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={saving}
          error={errors.agentProviderId}
        />
        <OrchestratorProviderField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={saving}
          error={errors.orchestratorProviderId}
        />
        <OriginationPhoneField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={saving}
          error={errors.originationNumber}
        />
        <CreateTwilioNumberField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={saving}
        />
        {state.createTwilioNumber && (
          <AreaCodeField
            state={state}
            onUpdate={onPropertyUpdate}
            disabled={saving}
            error={errors.areaCode}
          />
        )}
        <PromptParamsField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={saving}
          error={errors.promptParameters}
        />
      </Form>
    </Modal>
  );
};

export default AssignNumberModal;
