import { useCallback, useMemo } from 'react';
import {
  Empty,
  Flex,
  Form,
  Select,
  Spin,
} from 'antd';
import usePaginatedDataFetchWithSearch from '@marrlab-app-shared/hooks/data/usePaginatedDataFetchWithSearch';

import { FieldProps } from '../../types';
import { getEmbeddingModelProviders } from '../../../../../../api/agentProviders';

const EmbeddingAliasField = ({
  state,
  disabled,
  onUpdate,
}: FieldProps) => {
  const { updateSearch, loading, data } = usePaginatedDataFetchWithSearch({
    request: getEmbeddingModelProviders,
  });

  const onChange = useCallback((value?: string) => {
    onUpdate('embeddingAlias', value);
  }, [onUpdate]);

  const options = useMemo(() => (
    data?.map((item) => ({
      value: item.alias,
      label: item.alias,
    })) ?? []
  ), [data]);

  return (
    <Form.Item label="Embedding alias">
      <Select
        value={state.embeddingAlias}
        onChange={onChange}
        onSearch={updateSearch}
        loading={loading}
        options={options}
        allowClear
        showSearch
        filterOption={false}
        notFoundContent={loading ? (
          <Flex justify="center" align="center" className="h-20">
            <Spin />
          </Flex>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
        disabled={disabled}
      />
    </Form.Item>
  );
};

export default EmbeddingAliasField;
