import { useCallback } from 'react';
import { Button, Tooltip } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import CreateCustomerProvider from '../../providers/createCustomerProvider';
import { Customer } from '../../../../api/customers/types';

interface EditCustomerActionProps {
  customer: Customer;
}

const EditCustomerAction = ({ customer }: EditCustomerActionProps) => {
  const createCustomer = CreateCustomerProvider.useCreateCustomer();

  const isInactive = !customer.active;

  let tooltip = 'Edit customer';

  if (isInactive) {
    tooltip = 'The customer marked as archived';
  }

  const open = useCallback(() => {
    createCustomer.openOnEdit(customer);
  }, [createCustomer, customer]);

  return (
    <Tooltip title={tooltip}>
      <Button
        type="primary"
        ghost
        icon={<EditOutlined />}
        size="middle"
        onClick={open}
        disabled={isInactive}
      />
    </Tooltip>
  );
};

export default EditCustomerAction;
