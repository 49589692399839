import { useCallback, useState } from 'react';
import useDataFetch from '@marrlab-app-shared/hooks/data/useDataFetch';

import { getPersonaById } from '../../../../api/personas';
import { Persona } from '../../../../api/personas/types';
import { newPersonaId } from '../../../../constants';

interface UseAgentProviderDataArgs {
  personaId?: string;
}

const usePersonaData = ({ personaId }: UseAgentProviderDataArgs) => {
  const [hasReferenceDataRecords, setHasReferenceDataRecords] = useState(false);

  const request = useCallback(() => {
    if (!personaId) {
      return Promise.resolve();
    }

    if (personaId === newPersonaId) {
      return Promise.resolve();
    }

    return getPersonaById(personaId);
  }, [personaId]);

  const { loading, data, fetchData } = useDataFetch<Persona | null>({ request });

  const isNew = personaId === newPersonaId;

  return {
    isNew,
    loading,
    data,
    hasReferenceDataRecords,
    setHasReferenceDataRecords,
    refetch: fetchData,
  };
};

export default usePersonaData;
