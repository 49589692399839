import { useCallback, useEffect, useState } from 'react';
import { message } from 'antd';
import { AxiosError } from 'axios';
import { Page } from '@marrlab-app-shared/types';

import { Question, GetQuestionsParams } from '../../../api/questions/types';
import { getQuestions } from '../../../api/questions';

const useQuestionsData = ({
  skip,
  take,
  sortBy,
  order,
  search,
  campaign,
}: GetQuestionsParams) => {
  const [data, setData] = useState<Page<Question> | null>(null);
  const [loading, setLoading] = useState(false);

  const fetchQuestions = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getQuestions({
        skip,
        take,
        sortBy,
        order,
        search,
        campaign,
      });

      setData(response.data);
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        message.open({ content: error.message, type: 'error', duration: 10 });
      }
    }

    setLoading(false);
  }, [campaign, order, search, skip, sortBy, take]);

  useEffect(() => {
    fetchQuestions();
  }, [fetchQuestions]);

  return {
    data,
    loading,
    fetchQuestions,
  };
};

export default useQuestionsData;
