import { ChangeEvent, useCallback } from 'react';
import { Form, Input, Select } from 'antd';

import { FieldProps } from '../../types';
import {
  ReferenceDataField as ReferenceDataFieldType,
  ReferenceFieldTypes,
} from '../../../../../../api/referenceDataFields/types';
import { getOptionsFromChoices } from '../../../../../../utils/personas';

const hasChoices = (referenceDataField: ReferenceDataFieldType) => (
  !!referenceDataField.referenceFieldChoices
);

const getPlaceholderBasedOnType = (referenceDataField: ReferenceDataFieldType) => {
  const numberType = [ReferenceFieldTypes.int, ReferenceFieldTypes.float]
    .includes(referenceDataField.referenceFieldType);

  if (numberType) {
    return '12000';
  }

  return 'self-employed';
};

const ReferenceDataField = ({
  state,
  disabled,
  error,
  onUpdate,
  referenceDataField,
}: FieldProps) => {
  const onSelectChange = useCallback((value: string) => {
    onUpdate(state.referenceFieldId, 'referenceData', value);
  }, [onUpdate, state.referenceFieldId]);

  const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    onUpdate(state.referenceFieldId, 'referenceData', event.target.value);
  }, [onUpdate, state.referenceFieldId]);

  return (
    <Form.Item
      className="flex-1"
      label="Reference data"
      required
      validateStatus={error ? 'error' : undefined}
      help={error}
    >
      {hasChoices(referenceDataField) ? (
        <Select
          options={getOptionsFromChoices(referenceDataField.referenceFieldChoices!)}
          value={state.referenceData}
          onChange={onSelectChange}
          disabled={disabled}
        />
      ) : (
        <Input
          placeholder={getPlaceholderBasedOnType(referenceDataField)}
          value={state.referenceData}
          onChange={onChange}
          disabled={disabled}
        />
      )}
    </Form.Item>
  );
};

export default ReferenceDataField;
