import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { AxiosError } from 'axios';
import { message } from 'antd';

import { deletePersona } from '../../../../api/personas';

interface DeletePersonaContextType {
  loading: boolean;
  onDeletePersona: (personaId: number) => Promise<void>;
}

const initialState: DeletePersonaContextType = {
  loading: false,
  onDeletePersona: () => Promise.reject(),
};

const DeletePersonaContext = createContext<DeletePersonaContextType>(initialState);

interface DeletePersonaProviderProps extends PropsWithChildren {
  fetchPersonas: () => Promise<void>;
}

const DeletePersonaProvider = ({ children, fetchPersonas }: DeletePersonaProviderProps) => {
  const [loading, setLoading] = useState(false);

  const onDeletePersona = useCallback(async (personaId: number) => {
    setLoading(true);

    try {
      await deletePersona(personaId);

      await fetchPersonas();
    } catch (error) {
      if (error instanceof AxiosError) {
        message.open({ content: error.response?.data?.message, type: 'error', duration: 10 });
      }
    }

    setLoading(false);
  }, [fetchPersonas]);

  const value: DeletePersonaContextType = useMemo(
    () => ({
      loading,
      onDeletePersona,
    }),
    [loading, onDeletePersona],
  );

  return (
    <DeletePersonaContext.Provider value={value}>
      {children}
    </DeletePersonaContext.Provider>
  );
};

DeletePersonaProvider.useDeletePersona = () => useContext(DeletePersonaContext);

export default DeletePersonaProvider;
