import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { AxiosError } from 'axios';
import { message } from 'antd';

import { deleteCustomer } from '../../../../api/customers';

interface DeleteCustomerContextType {
  loading: boolean;
  onDeleteCustomer: (customerId: number) => Promise<void>;
}

const initialState: DeleteCustomerContextType = {
  loading: false,
  onDeleteCustomer: () => Promise.reject(),
};

const DeleteCustomerContext = createContext<DeleteCustomerContextType>(initialState);

interface DeleteCustomerProviderProps extends PropsWithChildren {
  fetchCustomers: () => Promise<void>;
}

const DeleteCustomerProvider = ({ children, fetchCustomers }: DeleteCustomerProviderProps) => {
  const [loading, setLoading] = useState(false);

  const onDeleteCustomer = useCallback(async (customerId: number) => {
    setLoading(true);

    try {
      await deleteCustomer(customerId);

      await fetchCustomers();
    } catch (error) {
      if (error instanceof AxiosError) {
        message.open({ content: error.response?.data?.message, type: 'error', duration: 10 });
      }
    }

    setLoading(false);
  }, [fetchCustomers]);

  const value: DeleteCustomerContextType = useMemo(
    () => ({
      loading,
      onDeleteCustomer,
    }),
    [loading, onDeleteCustomer],
  );

  return (
    <DeleteCustomerContext.Provider value={value}>
      {children}
    </DeleteCustomerContext.Provider>
  );
};

DeleteCustomerProvider.useDeleteCustomer = () => useContext(DeleteCustomerContext);

export default DeleteCustomerProvider;
