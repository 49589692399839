import { useCallback } from 'react';
import { Form, Switch } from 'antd';

import { FieldProps } from '../../types';

const CopyRetellLlmField = ({
  state,
  disabled,
  onUpdate,
}: FieldProps) => {
  const onChange = useCallback((checked: boolean) => {
    onUpdate('copyRetellLlm', checked);
    onUpdate('websocketBaseUrl', checked
      ? 'wss://api.retellai.com/retell-llm-new/'
      : '');
  }, [onUpdate]);

  return (
    <Form.Item
      className="field-with-info-tooltip"
      label="Copy Retell LLM"
      tooltip="When enabled, copies Retell LLM by LLM ID"
    >
      <Switch
        checked={state.copyRetellLlm}
        onChange={onChange}
        disabled={disabled}
      />
    </Form.Item>
  );
};

export default CopyRetellLlmField;
