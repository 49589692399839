import { useCallback } from 'react';
import {
  Flex,
  Form,
  Input,
  Select,
  Spin,
} from 'antd';

import { FieldProps, PlaceholderValuesRawError } from '../../types';
import { Placeholder } from '../../../../api/common/types';
import { Persona } from '../../../../api/personas/types';
import { getOptionsFromChoices } from '../../../../utils/personas';

interface PlaceholdersFieldsProps extends Omit<FieldProps, 'error'> {
  loading: boolean;
  placeholders: Placeholder[];
  error?: PlaceholderValuesRawError;
}

const resolvePlaceholderValue = (
  placeholder: Placeholder,
  placeholderValues: Persona['placeholderValues'],
) => {
  const value = placeholderValues[placeholder.placeholderId.toString()];

  if (!value) {
    return undefined;
  }

  return value;
};

const hasChoices = (placeholder: Placeholder) => !!placeholder.placeholderChoices;

const PlaceholdersFields = ({
  loading,
  placeholders,
  state,
  disabled,
  error,
  onUpdate,
}: PlaceholdersFieldsProps) => {
  const placeholderValues = state.placeholderValuesRaw;

  const onChange = useCallback((placeholderId: number, value: string) => {
    onUpdate('placeholderValuesRaw', {
      ...placeholderValues,
      [placeholderId.toString()]: value,
    });
  }, [onUpdate, placeholderValues]);

  return (
    <Form.Item
      className="field-with-info-tooltip mb-2"
      label="Placeholder values"
      tooltip={!loading && !state.templateId
        ? 'Select template to fill placeholders'
        : undefined}
    >
      {loading && (
        <Flex className="m-4" align="center" justify="center">
          <Spin size="small" />
        </Flex>
      )}
      {!loading && placeholders.map((placeholder) => (
        <Flex
          key={placeholder.placeholderId}
          gap="12px"
        >
          <Form.Item label="Name">
            <Input
              value={placeholder.placeholderName}
              disabled
            />
          </Form.Item>
          <Form.Item
            className="field-with-info-tooltip flex-1"
            required
            label="Value"
            tooltip={`Example: ${placeholder.placeholderDefaultValue}`}
            validateStatus={error?.[placeholder.placeholderId.toString()] ? 'error' : undefined}
            help={error?.[placeholder.placeholderId.toString()]}
          >
            {hasChoices(placeholder) ? (
              <Select
                options={getOptionsFromChoices(placeholder.placeholderChoices)}
                value={resolvePlaceholderValue(placeholder, placeholderValues)}
                onChange={(value) => onChange(placeholder.placeholderId, value)}
                disabled={disabled}
              />
            ) : (
              <Input
                placeholder={placeholder.placeholderDefaultValue}
                value={resolvePlaceholderValue(placeholder, placeholderValues)}
                onChange={(event) => onChange(placeholder.placeholderId, event.target.value)}
                disabled={disabled}
                title={
                  resolvePlaceholderValue(placeholder, placeholderValues)?.toString()
                  ?? placeholder.placeholderDefaultValue
                }
              />
            )}
          </Form.Item>
        </Flex>
      ))}
    </Form.Item>
  );
};

export default PlaceholdersFields;
