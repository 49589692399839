import { useCallback } from 'react';
import { Flex, Select, Typography } from 'antd';

import { FilterProps } from '../../types';
import { OrchestratorProviderTypes } from '../../../../api/agentProviders/types';

const options = [
  {
    value: OrchestratorProviderTypes.retell,
    label: 'Retell',
  },
  {
    value: OrchestratorProviderTypes.vocode,
    label: 'Vocode',
  },
];

const OrchestratorProviderTypeFilter = ({ filters, onFiltersUpdate }: FilterProps) => {
  const onChange = useCallback((value?: string) => {
    onFiltersUpdate('orchestratorProviderType', value);
  }, [onFiltersUpdate]);

  return (
    <Flex vertical gap={8} className="min-w-48">
      <Typography.Text>Orchestrator provider type</Typography.Text>
      <Select
        placeholder="Any type"
        value={filters.orchestratorProviderType}
        onChange={onChange}
        options={options}
        allowClear
      />
    </Flex>
  );
};

export default OrchestratorProviderTypeFilter;
