import ManagePersonaProvider from './providers/managePersonaProvider';
import PersonaManagementContent from './components/personaManagementContent';

const PersonaManagementWrapper = () => (
  <ManagePersonaProvider>
    <PersonaManagementContent />
  </ManagePersonaProvider>
);

export default PersonaManagementWrapper;
