import { Form, Input } from 'antd';

import { FieldProps } from '../../types';

const OrchestratorProviderField = ({
  state,
  error,
}: FieldProps) => (
  <Form.Item
    label="Orchestrator provider ID"
    required
    validateStatus={error ? 'error' : undefined}
    help={error}
  >
    <Input
      value={state.orchestratorProviderId}
      disabled
    />
  </Form.Item>
);

export default OrchestratorProviderField;
