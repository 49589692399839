import { Dispatch, SetStateAction, useCallback } from 'react';
import { TableColumnsType } from 'antd';
import useModalState from '@marrlab-app-shared/hooks/state/useModalState';

import { AgentProvider } from '../../../api/agentProviders/types';

interface UseColumnsSettingsModalArgs {
  setColumns: Dispatch<SetStateAction<TableColumnsType<AgentProvider>>>;
}

const useColumnsSettingsModal = ({ setColumns }: UseColumnsSettingsModalArgs) => {
  const { isOpen, open, close } = useModalState();

  const onConfirm = useCallback((columns: TableColumnsType<AgentProvider>) => {
    close();
    setColumns(columns);
  }, [close, setColumns]);

  const onCancel = useCallback(() => close(), [close]);

  return {
    isOpen,
    open,
    onCancel,
    onConfirm,
  };
};

export default useColumnsSettingsModal;
