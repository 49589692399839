import { Modal, Form } from 'antd';
import ModalFooter from '@marrlab-app-shared/components/modalFooter';

import ShortNameField from './components/shortNameField';

import useCreateVoiceAgentConfigModalLogic from './hooks/useCreateVoiceAgentConfigModalLogic';
import useCreateVoiceAgentConfigModalState from './hooks/useCreateVoiceAgentConfigModalState';
import useCreateVoiceAgentConfigModalValidation from './hooks/useCreateVoiceAgentConfigModalValidation';
import { CreateVoiceAgentConfigParams, VoiceAgentConfig } from '../../../../api/voiceAgentConfigs/types';
import VoiceAgentConfigField from './components/voiceAgentConfigField';

interface CreateVoiceAgentConfigModalProps {
  isOpen: boolean;
  initialVoiceAgentConfig: VoiceAgentConfig | null;
  onConfirm: (params: CreateVoiceAgentConfigParams) => Promise<void>;
  onCancel: () => void;
}

const CreateVoiceAgentConfigModal = ({
  isOpen,
  initialVoiceAgentConfig,
  onConfirm: onConfirmBase,
  onCancel,
}: CreateVoiceAgentConfigModalProps) => {
  const {
    errors,
    isValid,
    clearErrorForField,
  } = useCreateVoiceAgentConfigModalValidation({ isOpen });

  const {
    isEdit,
    state,
    onPropertyUpdate,
  } = useCreateVoiceAgentConfigModalState({
    isOpen,
    initialVoiceAgentConfig,
    clearErrorForField,
  });

  const { saving, onConfirm } = useCreateVoiceAgentConfigModalLogic({
    state,
    isValid,
    onConfirm: onConfirmBase,
  });

  return (
    <Modal
      title={isEdit ? `Edit voice agent config #${initialVoiceAgentConfig?.voiceAgentConfigId}` : 'Create voice agent config'}
      open={isOpen}
      onOk={onConfirm}
      onCancel={onCancel}
      width="600px"
      footer={(
        <ModalFooter
          disabled={saving}
          saveButtonLabel={isEdit ? 'Save' : 'Create'}
          onCancel={onCancel}
          onConfirm={onConfirm}
        />
      )}
    >
      <Form
        layout="vertical"
        className="py-4"
      >
        <ShortNameField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={saving}
          error={errors.shortName}
        />
        <VoiceAgentConfigField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={saving}
          error={errors.config}
        />
      </Form>
    </Modal>
  );
};

export default CreateVoiceAgentConfigModal;
