import { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Card,
  Collapse,
  CollapseProps,
  Empty,
  Flex,
  Spin,
  Typography,
} from 'antd';
import { useParams } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import cn from 'classnames';

import ManagePersonaProvider from '../../providers/managePersonaProvider';
import PersonaManagement from '../../../../features/personaManagement';
import ReferenceData from '../../../../features/referenceData';
import HumanAgentSection from '../../../../features/humanAgentSection';

import useNavigate from '../../../../hooks/navigation/useNavigate';

import './index.scss';

const PersonaManagementContent = () => {
  const { navigateToPersonas } = useNavigate();
  const { personaId } = useParams();
  const [collapseActiveKeys, setCollapseActiveKeys] = useState<string[]>([]);

  const {
    isNew,
    loading,
    persona,
    hasReferenceDataRecords,
  } = ManagePersonaProvider.useManagePersona();

  const collapseItems: CollapseProps['items'] = useMemo(() => [
    {
      key: 'personas',
      label: (
        <Typography.Title
          className="persona-management-title"
          level={5}
        >
          Personas
        </Typography.Title
        >
      ),
      children: <PersonaManagement />,
    },
    {
      key: 'reference-data',
      label: (
        <>
          <Typography.Title
            className="persona-management-title"
            level={5}
          >
            Reference data
          </Typography.Title>
          {isNew && (
            <Typography.Text strong>
              To manage reference data, save the person first.
            </Typography.Text>
          )}
        </>
      ),
      children: persona && (
        <ReferenceData />
      ),
      className: cn({ 'persona-management-section-disabled': isNew }),
    },
    {
      key: 'agent-provider',
      label: (
        <>
          <Typography.Title
            className="persona-management-title"
            level={5}
          >
            Human agent
          </Typography.Title>
          {isNew && (
            <Typography.Text strong>
              To manage human agent, save the person first.
            </Typography.Text>
          )}
          {!isNew && !hasReferenceDataRecords && (
            <Typography.Text strong>
              To manage human agent, create at least one reference data record.
            </Typography.Text>
          )}
        </>
      ),
      children: persona && (
        <HumanAgentSection />
      ),
      className: cn({ 'persona-management-section-disabled': isNew || !hasReferenceDataRecords }),
    },
  ], [hasReferenceDataRecords, isNew, persona]);

  useEffect(() => {
    if (isNew) {
      setCollapseActiveKeys(['personas']);
      return;
    }

    if (!hasReferenceDataRecords) {
      setCollapseActiveKeys(['personas', 'reference-data']);
      return;
    }

    setCollapseActiveKeys(['personas', 'reference-data', 'agent-provider']);
  }, [hasReferenceDataRecords, isNew]);

  if (loading) {
    return (
      <Flex className="m-16" align="center" justify="center">
        <Spin size="large" />
      </Flex>
    );
  }

  if (!persona && !isNew) {
    return (
      <Empty
        className="m-16"
        description={(
          <Typography.Text>
            {`The persona with #${personaId} doesn't exist`}
          </Typography.Text>
        )}
      >
        <Button
          type="primary"
          onClick={navigateToPersonas}
        >
          Go back
        </Button>
      </Empty>
    );
  }

  return (
    <Card
      title={(
        <Flex align="center" gap={16}>
          <Button
            icon={<ArrowLeftOutlined />}
            onClick={navigateToPersonas}
          />
          <Typography.Title
            level={4}
            className="personas-title"
          >
            {isNew ? 'Create persona' : `Edit persona #${persona?.personaId}`}
          </Typography.Title>
        </Flex>
      )}
      className="bg-white rounded shadow-lg"
    >
      <Collapse
        className="persona-management-collapsible-group"
        items={collapseItems}
        activeKey={collapseActiveKeys}
        onChange={setCollapseActiveKeys}
      />
      <Flex
        className="mt-6"
        justify="flex-end"
      >
        <Button
          type="primary"
          icon={<ArrowLeftOutlined />}
          onClick={navigateToPersonas}
        >
          Go back to the personal list
        </Button>
      </Flex>
    </Card>
  );
};

export default PersonaManagementContent;
