import {
  useCallback,
  useEffect,
  useState,
} from 'react';

import { AgentProvider } from '../../../../../api/agentProviders/types';

interface UseDuplicateAgentModalStateArgs {
  isOpen: boolean;
  agentToDuplicate: AgentProvider | null;
  clearErrorForField: (propName: string) => void;
}

const useDuplicateAgentModalState = ({
  isOpen,
  agentToDuplicate,
  clearErrorForField,
}: UseDuplicateAgentModalStateArgs) => {
  const [agentName, setAgentName] = useState<string>('');

  const onAgentNameUpdate = useCallback((value: string) => {
    setAgentName(value);
    clearErrorForField('agentName');
  }, [clearErrorForField]);

  useEffect(() => {
    if (isOpen && agentToDuplicate) {
      setAgentName(agentToDuplicate.orchestratorProvider.config.AgentName);
    }
  }, [agentToDuplicate, isOpen]);

  return {
    agentName,
    onAgentNameUpdate,
  };
};

export default useDuplicateAgentModalState;
