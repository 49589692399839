import { useCallback } from 'react';
import { Button, Tooltip } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import CreateVoiceAgentConfigProvider from '../../providers/createVoiceAgentConfigProvider';
import { VoiceAgentConfig } from '../../../../api/voiceAgentConfigs/types';

interface EditVoiceAgentConfigActionProps {
  voiceAgentConfig: VoiceAgentConfig;
}

const EditVoiceAgentConfigAction = ({ voiceAgentConfig }: EditVoiceAgentConfigActionProps) => {
  const createVoiceAgentConfig = CreateVoiceAgentConfigProvider.useCreateVoiceAgentConfig();

  const open = useCallback(() => {
    createVoiceAgentConfig.openOnEdit(voiceAgentConfig);
  }, [createVoiceAgentConfig, voiceAgentConfig]);

  return (
    <Tooltip title="Edit voice agent config">
      <Button
        type="primary"
        ghost
        icon={<EditOutlined />}
        size="middle"
        onClick={open}
      />
    </Tooltip>
  );
};

export default EditVoiceAgentConfigAction;
