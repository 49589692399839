import { Flex } from 'antd';

import EditTtsConfigAction from '../editTtsConfigAction';
import { TtsConfig } from '../../../../api/ttsConfigs/types';

interface ActionsCellProps {
  ttsConfig: TtsConfig;
}

const ActionsCell = ({ ttsConfig }: ActionsCellProps) => (
  <Flex justify="flex-end">
    <EditTtsConfigAction ttsConfig={ttsConfig} />
  </Flex>
);

export default ActionsCell;
