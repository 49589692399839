import { Button } from 'antd';

import useNavigate from '../../../../hooks/navigation/useNavigate';

const CreatePersonaButton = () => {
  const { navigateToCreatePersona } = useNavigate();

  return (
    <Button
      className="ml-auto"
      type="primary"
      onClick={navigateToCreatePersona}
    >
      Create persona
    </Button>
  );
};

export default CreatePersonaButton;
