import { useCallback } from 'react';
import { Form, InputNumber } from 'antd';

import { FieldProps } from '../../types';

const VoiceTemperatureField = ({
  state,
  disabled,
  onUpdate,
}: FieldProps) => {
  const onChange = useCallback((value: number | null) => {
    onUpdate('voiceTemperature', value ?? undefined);
  }, [onUpdate]);

  return (
    <Form.Item
      className="field-with-info-tooltip"
      label="Voice temperature"
      tooltip="Controls how stable the voice is. Value ranging from [0,2]. Lower value means more stable, and higher value means more variant speech generation. Currently this setting only applies to 11labs voices. If unset, default value 1 will apply."
    >
      <InputNumber
        placeholder="1.00"
        value={state.voiceTemperature}
        onChange={onChange}
        disabled={disabled}
        min={0}
        max={2}
        precision={2}
        step={0.1}
      />
    </Form.Item>
  );
};

export default VoiceTemperatureField;
