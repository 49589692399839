import { useCallback, useEffect, useState } from 'react';
import { message } from 'antd';
import { AxiosError } from 'axios';

import { Placeholder } from '../../../api/common/types';
import { getPlaceholdersByHumanTemplateId } from '../../../api/humanTemplates';

interface UsePlaceholdersListArgs {
  templateId: number | null;
}

const usePlaceholdersList = ({ templateId }: UsePlaceholdersListArgs) => {
  const [placeholders, setPlaceholders] = useState<Placeholder[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchPlaceholder = useCallback(async () => {
    if (!templateId) {
      return;
    }

    setLoading(true);

    try {
      const response = await getPlaceholdersByHumanTemplateId(templateId);

      setPlaceholders(response.data);
    } catch (error) {
      if (error instanceof AxiosError) {
        message.open({ content: error.response?.data?.message, type: 'error', duration: 10 });
      }
    }

    setLoading(false);
  }, [templateId]);

  const clearPlaceholder = useCallback(() => {
    setPlaceholders([]);
  }, []);

  useEffect(() => {
    fetchPlaceholder();
  }, [fetchPlaceholder]);

  return {
    loading,
    placeholders,
    clearPlaceholder,
  };
};

export default usePlaceholdersList;
