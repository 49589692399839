import {
  Modal,
  Form,
  Spin,
  Flex,
} from 'antd';
import ModalFooter from '@marrlab-app-shared/components/modalFooter';

import ReferenceDataField from './components/referenceDataField';
import ReferenceDataIdField from './components/referenceDataIdField';
import ReferenceDataFieldField from './components/referenceDataFieldField';

import useCreateReferenceDataModalLogic from './hooks/useCreateReferenceDataModalLogic';
import useCreateReferenceDataModalState from './hooks/useCreateReferenceDataModalState';
import useCreateReferenceDataModalValidation from './hooks/useCreateReferenceDataModalValidation';
import useReferenceDataFields from './hooks/useReferenceDataFields';
import useInitialReferenceDataId from './hooks/useInitialReferenceDataId';
import { ReferenceData } from '../../../../api/referenceData/types';
import { Persona } from '../../../../api/personas/types';
import { CreateReferenceDataModalState } from './types';

interface CreateReferenceDataModalProps {
  isOpen: boolean;
  persona: Persona | null;
  initialReferenceData: ReferenceData | null;
  onConfirm: (params: CreateReferenceDataModalState) => Promise<void>;
  onCancel: () => void;
}

const CreateReferenceDataModal = ({
  isOpen,
  persona,
  initialReferenceData,
  onConfirm: onConfirmBase,
  onCancel,
}: CreateReferenceDataModalProps) => {
  const {
    errors,
    isValid,
    clearErrorForField,
  } = useCreateReferenceDataModalValidation({ isOpen });

  const {
    data: referenceDataFields,
    loading: referenceDataFieldsLoading,
  } = useReferenceDataFields({ campaignId: persona?.template?.campaignId });

  const {
    initialReferenceDataId,
    loading: initialReferenceDataIdLoading,
  } = useInitialReferenceDataId({
    isOpen,
    personaId: persona?.personaId,
  });

  const {
    isEdit,
    state,
    onPropertyUpdate,
  } = useCreateReferenceDataModalState({
    isOpen,
    persona,
    initialReferenceData,
    initialReferenceDataId,
    clearErrorForField,
    referenceDataFields,
  });

  const { saving, onConfirm } = useCreateReferenceDataModalLogic({
    state,
    isValid,
    onConfirm: onConfirmBase,
  });

  const loading = referenceDataFieldsLoading || initialReferenceDataIdLoading;

  return (
    <Modal
      title={isEdit ? `Edit reference data #${initialReferenceData?.id}` : 'Create reference data'}
      open={isOpen}
      onOk={onConfirm}
      onCancel={onCancel}
      width="800px"
      footer={(
        <ModalFooter
          disabled={saving}
          saveButtonLabel={isEdit ? 'Save' : 'Create'}
          onCancel={onCancel}
          onConfirm={onConfirm}
        />
      )}
    >
      <Form
        layout="vertical"
        className="py-4"
      >
        {loading && (
          <Flex
            className="h-xs"
            align="center"
            justify="center"
          >
            <Spin />
          </Flex>
        )}
        {!loading && referenceDataFields.map((referenceDataField) => {
          if (!state[referenceDataField.referenceFieldId]) {
            return null;
          }

          return (
            <Flex gap={12} key={referenceDataField.referenceFieldId}>
              <ReferenceDataFieldField
                state={state[referenceDataField.referenceFieldId]}
                referenceDataField={referenceDataField}
                onUpdate={onPropertyUpdate}
                disabled={saving}
                error={errors[referenceDataField.referenceFieldId]?.referenceFieldId}
              />
              <ReferenceDataIdField
                state={state[referenceDataField.referenceFieldId]}
                referenceDataField={referenceDataField}
                onUpdate={onPropertyUpdate}
                disabled={saving}
                error={errors[referenceDataField.referenceFieldId]?.referenceDataId}
              />
              <ReferenceDataField
                state={state[referenceDataField.referenceFieldId]}
                referenceDataField={referenceDataField}
                onUpdate={onPropertyUpdate}
                disabled={saving}
                error={errors[referenceDataField.referenceFieldId]?.referenceData}
              />
            </Flex>
          );
        })}
      </Form>
    </Modal>
  );
};

export default CreateReferenceDataModal;
