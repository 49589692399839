import { CreateAgentParams } from '../../../../../api/agentProviders/types';

export enum AgentType {
  humanAgent = 'humanAgent',
  agent = 'agent',
}

export type CreateAgentModalState = CreateAgentParams & {
  agentType: AgentType;
};

export interface FieldProps {
  state: CreateAgentModalState;
  disabled: boolean;
  error?: string;
  onUpdate: (
    paramName: keyof CreateAgentModalState,
    value: CreateAgentModalState[keyof CreateAgentModalState],
  ) => void;
}
