import { useCallback, useMemo } from 'react';
import {
  Empty,
  Flex,
  Form,
  Select,
  Spin,
} from 'antd';
import usePaginatedDataFetchWithSearch from '@marrlab-app-shared/hooks/data/usePaginatedDataFetchWithSearch';

import { FieldProps } from '../../types';
import { getHumanTemplate } from '../../../../api/humanTemplates';

interface Option {
  label: string;
  value: string;
  templateText: string;
}

const HumanTemplateField = ({
  state,
  disabled,
  error,
  onUpdate,
}: FieldProps) => {
  const request = useCallback(({ search }: { search: string }) => {
    if (!state.campaignId) {
      return Promise.resolve();
    }

    return getHumanTemplate({
      search,
      take: 100,
      campaign: state.campaignId,
    });
  }, [state.campaignId]);

  const { updateSearch, loading, data } = usePaginatedDataFetchWithSearch({
    request,
  });

  const onChange = useCallback((value?: number, option?: Option | Option[]) => {
    onUpdate('templateId', value ?? null);

    if (!Array.isArray(option)) {
      onUpdate('templateText', option?.templateText ?? '');
    }
  }, [onUpdate]);

  const options = useMemo(() => (
    data?.map((item) => ({
      value: item.templateId,
      label: item.templateName,
      templateText: item.template,
    })) ?? []
  ), [data]);

  return (
    <Form.Item
      className="field-with-info-tooltip"
      label="Template"
      required
      validateStatus={error ? 'error' : undefined}
      help={error}
      tooltip={state.campaignId ? undefined : 'Select campaign to unblock that field'}
    >
      <Select<number | undefined, Option>
        value={state.templateId}
        onChange={onChange}
        onSearch={updateSearch}
        loading={loading}
        options={options}
        allowClear
        showSearch
        filterOption={false}
        notFoundContent={loading ? (
          <Flex justify="center" align="center" className="h-20">
            <Spin />
          </Flex>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
        disabled={disabled || !state.campaignId}
      />
    </Form.Item>
  );
};

export default HumanTemplateField;
