import { useCallback, useState } from 'react';
import { AxiosError } from 'axios';
import { message } from 'antd';

import { trimCreateQuestionParams } from '../mappers';
import { CreateQuestionParams } from '../../../../../api/questions/types';

interface UseCreateQuestionModalLogicArgs {
  state: CreateQuestionParams;
  isValid: (state: CreateQuestionParams) => boolean;
  onConfirm: (params: CreateQuestionParams) => Promise<void>;
}

const useCreateQuestionModalLogic = ({
  state,
  isValid,
  onConfirm: onConfirmBase,
}: UseCreateQuestionModalLogicArgs) => {
  const [saving, setSaving] = useState(false);

  const onConfirm = useCallback(async () => {
    if (!isValid(state)) {
      return;
    }

    setSaving(true);

    try {
      await onConfirmBase(trimCreateQuestionParams(state));
    } catch (error) {
      console.error(error);

      if (error instanceof AxiosError) {
        message.open({ content: error.response?.data?.message, type: 'error', duration: 10 });
      }
    }

    setSaving(false);
  }, [isValid, state, onConfirmBase]);

  return {
    saving,
    onConfirm,
  };
};

export default useCreateQuestionModalLogic;
