import { CreateAsrConfigParams, AsrConfig } from '../../../../../api/asrConfigs/types';
import { CreateAsrConfigModalState } from '../types';

export const trimCreateAsrConfigParams = (
  params: CreateAsrConfigModalState,
): CreateAsrConfigParams => ({
  shortName: params.shortName?.trim(),
  config: params.config ? JSON.stringify(params.config) : undefined,
});

export const getCreateModalInitialState = (
  initialAsrConfig?: AsrConfig | null,
): CreateAsrConfigModalState => {
  if (!initialAsrConfig) {
    return {
      shortName: '',
      config: {},
    };
  }

  return {
    shortName: initialAsrConfig.shortName,
    config: initialAsrConfig.config,
  };
};
