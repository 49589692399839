import { useCallback, useState } from 'react';
import {
  Button,
  Popconfirm,
  Tooltip,
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import { AgentProvider } from '../../../../api/agentProviders/types';
import DeleteAgentProvider from '../../../agentProviders/providers/deleteAgentProvider';

interface DeleteAgentButtonProps {
  agent: AgentProvider;
}

const DeleteAgentButton = ({ agent }: DeleteAgentButtonProps) => {
  const { loading, onDeleteAgent } = DeleteAgentProvider.useDeleteAgent();

  const [open, setOpen] = useState(false);

  const isInactive = !agent.active;
  const hasNumberAssigned = !!agent.orchestratorNumberAssignment;
  const isDisabled = isInactive || hasNumberAssigned;

  let tooltip = '';

  if (isInactive) {
    tooltip = 'The agent marked as archived';
  }

  if (hasNumberAssigned) {
    tooltip = 'To archive the agent, unassign number from the agent first';
  }

  const showConfirmation = useCallback(() => setOpen(true), []);

  const onConfirm = useCallback(async () => {
    await onDeleteAgent(agent.agentProviderId);

    setOpen(false);
  }, [agent.agentProviderId, onDeleteAgent]);

  const onCancel = useCallback(() => setOpen(false), []);

  return (
    <Popconfirm
      title={`Archived the agent #${agent.agentProviderId}`}
      description="Are you sure to archive the agent?"
      open={open}
      onConfirm={onConfirm}
      okButtonProps={{ loading }}
      onCancel={onCancel}
      disabled={isDisabled}
    >
      <Tooltip title={tooltip}>
        <Button
          className="ml-auto"
          danger
          icon={<DeleteOutlined />}
          onClick={showConfirmation}
          disabled={isDisabled}
        >
          Archive agent
        </Button>
      </Tooltip>
    </Popconfirm>
  );
};

export default DeleteAgentButton;
