import {
  Modal,
  Input,
  Form,
} from 'antd';
import ModalFooter from '@marrlab-app-shared/components/modalFooter';

import useDuplicateAgentModalLogic from './hooks/useDuplicateAgentModalLogic';
import useDuplicateAgentModalState from './hooks/useDuplicateAgentModalState';
import { AgentProvider, DuplicateAgentParams } from '../../../../api/agentProviders/types';
import useDuplicateAgentModalValidation from './hooks/useDuplicateAgentModalValidation';

interface DuplicateAgentModalProps {
  isOpen: boolean;
  onConfirm: (params: DuplicateAgentParams) => Promise<void>;
  onCancel: () => void;
  agentToDuplicate: AgentProvider | null;
}

const DuplicateAgentModal = ({
  isOpen,
  agentToDuplicate,
  onConfirm: onConfirmBase,
  onCancel,
}: DuplicateAgentModalProps) => {
  const {
    errors,
    clearErrorForField,
    isValid,
  } = useDuplicateAgentModalValidation({ isOpen });

  const {
    agentName,
    onAgentNameUpdate,
  } = useDuplicateAgentModalState({
    isOpen,
    agentToDuplicate,
    clearErrorForField,
  });

  const { saving, onConfirm } = useDuplicateAgentModalLogic({
    agentName,
    agentToDuplicate,
    isValid,
    onConfirm: onConfirmBase,
  });

  return (
    <Modal
      title={`Duplicate agent #${agentToDuplicate?.agentProviderId}`}
      open={isOpen}
      onOk={onConfirm}
      onCancel={onCancel}
      footer={(
        <ModalFooter
          disabled={saving}
          saveButtonLabel="Save"
          onCancel={onCancel}
          onConfirm={onConfirm}
        />
      )}
    >
      <Form layout="vertical" className="py-4">
        <Form.Item
          label="Agent name"
          required
          validateStatus={errors.agentName ? 'error' : undefined}
          help={errors.agentName}
        >
          <Input
            value={agentName}
            onChange={(event) => onAgentNameUpdate(event.target.value)}
            disabled={saving}
            maxLength={80}
            showCount
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default DuplicateAgentModal;
