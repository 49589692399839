import { useCallback, useState } from 'react';
import { AxiosError } from 'axios';
import { message } from 'antd';

import { trimCreateHumanTemplateParams } from '../mappers';
import { CreateHumanTemplateParams } from '../../../../../api/humanTemplates/types';

interface UseCreateHumanTemplateModalLogicArgs {
  state: CreateHumanTemplateParams;
  isValid: (state: CreateHumanTemplateParams) => boolean;
  onConfirm: (params: CreateHumanTemplateParams) => Promise<void>;
  onCancel: () => void;
}

const useCreateHumanTemplateModalLogic = ({
  state,
  isValid,
  onConfirm: onConfirmBase,
  onCancel: onCancelBase,
}: UseCreateHumanTemplateModalLogicArgs) => {
  const [saving, setSaving] = useState(false);

  const onConfirm = useCallback(async () => {
    if (!isValid(state)) {
      return;
    }

    setSaving(true);

    try {
      await onConfirmBase(trimCreateHumanTemplateParams(state));
    } catch (error) {
      console.error(error);

      if (error instanceof AxiosError) {
        message.open({ content: error.response?.data?.message, type: 'error', duration: 10 });
      }
    }

    setSaving(false);
  }, [isValid, state, onConfirmBase]);

  const onCancel = useCallback(() => {
    onCancelBase();
  }, [onCancelBase]);

  return {
    saving,
    onCancel,
    onConfirm,
  };
};

export default useCreateHumanTemplateModalLogic;
