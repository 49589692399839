import { Flex } from 'antd';

import EditCustomerAction from '../editCustomerAction';
import DeleteCustomerAction from '../deleteCustomerAction';
import { Customer } from '../../../../api/customers/types';

interface ActionsCellProps {
  customer: Customer;
}

const ActionsCell = ({ customer }: ActionsCellProps) => (
  <Flex gap={8}>
    <EditCustomerAction customer={customer} />
    <DeleteCustomerAction customer={customer} />
  </Flex>
);

export default ActionsCell;
