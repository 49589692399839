import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { AxiosError } from 'axios';
import { message } from 'antd';

import { deleteQuestion } from '../../../../api/questions';

interface DeleteQuestionContextType {
  loading: boolean;
  onDeleteQuestion: (questionId: number) => Promise<void>;
}

const initialState: DeleteQuestionContextType = {
  loading: false,
  onDeleteQuestion: () => Promise.reject(),
};

const DeleteQuestionContext = createContext<DeleteQuestionContextType>(initialState);

interface DeleteQuestionProviderProps extends PropsWithChildren {
  fetchQuestions: () => Promise<void>;
}

const DeleteQuestionProvider = ({ children, fetchQuestions }: DeleteQuestionProviderProps) => {
  const [loading, setLoading] = useState(false);

  const onDeleteQuestion = useCallback(async (questionId: number) => {
    setLoading(true);

    try {
      await deleteQuestion(questionId);

      await fetchQuestions();
    } catch (error) {
      if (error instanceof AxiosError) {
        message.open({ content: error.response?.data?.message, type: 'error', duration: 10 });
      }
    }

    setLoading(false);
  }, [fetchQuestions]);

  const value: DeleteQuestionContextType = useMemo(
    () => ({
      loading,
      onDeleteQuestion,
    }),
    [loading, onDeleteQuestion],
  );

  return (
    <DeleteQuestionContext.Provider value={value}>
      {children}
    </DeleteQuestionContext.Provider>
  );
};

DeleteQuestionProvider.useDeleteQuestion = () => useContext(DeleteQuestionContext);

export default DeleteQuestionProvider;
