import {
  useCallback,
  useEffect,
  useState,
} from 'react';

import { getCreateModalInitialState } from '../mappers';
import { CreateCampaignParams, Campaign } from '../../../../../api/campaigns/types';

interface UseCreateCampaignModalStateArgs {
  clearErrorForField: (propName: string) => void;
  initialCampaign: Campaign | null;
  isOpen: boolean;
}

const useCreateCampaignModalState = ({
  isOpen,
  initialCampaign,
  clearErrorForField,
}: UseCreateCampaignModalStateArgs) => {
  const [state, setState] = useState<CreateCampaignParams>(getCreateModalInitialState());

  const onPropertyUpdate = useCallback((
    propName: keyof CreateCampaignParams,
    value: CreateCampaignParams[keyof CreateCampaignParams],
  ) => {
    setState((oldState) => ({ ...oldState, [propName]: value }));
    clearErrorForField(propName);
  }, [clearErrorForField]);

  useEffect(() => {
    setState(getCreateModalInitialState(isOpen ? initialCampaign : null));
  }, [initialCampaign, isOpen]);

  const isEdit = !!initialCampaign;

  return {
    isEdit,
    state,
    onPropertyUpdate,
  };
};

export default useCreateCampaignModalState;
