import { useCallback, useState } from 'react';
import {
  Button,
  Popconfirm,
  Tooltip,
  Typography,
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import DeletePersonaProvider from '../../providers/deletePersonaProvider';
import { Persona } from '../../../../api/personas/types';

interface DeletePersonaActionProps {
  persona: Persona;
}

const DeletePersonaAction = ({ persona }: DeletePersonaActionProps) => {
  const { loading, onDeletePersona } = DeletePersonaProvider.useDeletePersona();

  const [open, setOpen] = useState(false);

  const hasAgentConnected = !!persona.agentProvider?.active;

  const tooltip = hasAgentConnected ? 'To delete this persona, archive human agent first' : 'Delete persona';

  const showConfirmation = useCallback(() => setOpen(true), []);

  const onConfirm = useCallback(async () => {
    await onDeletePersona(persona.personaId);

    setOpen(false);
  }, [persona.personaId, onDeletePersona]);

  const onCancel = useCallback(() => setOpen(false), []);

  return (
    <Popconfirm
      title={`Delete the persona #${persona.personaId}`}
      description={(
        <Typography.Text>
          <Typography.Text strong>
            All related &quot;reference data&quot; records will be removed
          </Typography.Text>
          <br />
          Are you sure to delete the persona?
        </Typography.Text>
      )}
      open={open}
      onConfirm={onConfirm}
      okButtonProps={{ loading }}
      onCancel={onCancel}
    >
      <Tooltip title={tooltip}>
        <Button
          type="primary"
          ghost
          icon={<DeleteOutlined />}
          onClick={showConfirmation}
          disabled={hasAgentConnected}
        />
      </Tooltip>
    </Popconfirm>
  );
};

export default DeletePersonaAction;
