import { TableColumnsType } from 'antd';

import ActionsCell from '../components/actionsCell';
import { ReferenceData } from '../../../api/referenceData/types';

export const idColumnKey = 'id';
export const defaultSortColumn = 'referenceDataId';

export const columnsInitialState: TableColumnsType<ReferenceData> = [
  {
    title: 'ID',
    dataIndex: idColumnKey,
    key: idColumnKey,
    fixed: 'left',
    sorter: true,
    width: 80,
  },
  {
    title: 'Reference field ID',
    dataIndex: 'referenceFieldId',
    key: 'referenceFieldId',
    sorter: true,
    width: 180,
  },
  {
    title: 'Reference data ID',
    dataIndex: 'referenceDataId',
    key: 'referenceDataId',
    sortOrder: 'descend',
    sorter: true,
    width: 180,
  },
  {
    title: 'Reference data',
    dataIndex: 'referenceData',
    key: 'referenceData',
    sorter: true,
  },
  {
    title: 'Actions',
    width: 104,
    fixed: 'right',
    render: ((_, record) => <ActionsCell referenceData={record} />),
  },
];
