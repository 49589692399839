import { useMemo } from 'react';
import { DescriptionsItemType } from 'antd/es/descriptions';
import { Typography } from 'antd';

import { AgentProvider } from '../../../api/agentProviders/types';
import ExpandableText from '../../../components/expandablePrompt';

interface UseAgentDetailsColumnsArgs {
  agent: AgentProvider;
}

export const useAgentDetailsColumns = ({ agent }: UseAgentDetailsColumnsArgs) => {
  const columns: DescriptionsItemType[] = useMemo(() => [
    {
      key: 'agentProviderId',
      label: 'Agent provider ID',
      children: agent.agentProviderId,
    },
    {
      key: 'active',
      label: 'Status',
      children: (
        <Typography.Text
          type={agent.active ? undefined : 'danger'}
          strong={!agent.active}
        >
          {agent.active ? 'Active' : 'Archived'}
        </Typography.Text>
      ),
    },
    {
      span: 2,
      label: 'Agent name',
      key: 'orchestratorProviderAgentName',
      children: agent.orchestratorProvider?.config.AgentName,
    },
    {
      key: 'orchestratorProviderId',
      label: 'Orchestrator provider ID',
      children: agent.orchestratorProviderId,
    },
    {
      key: 'numberAssignmentId',
      label: 'Number assignment ID',
      children: agent.orchestratorNumberAssignment?.orchestratorNumberAssignmentId,
    },
    {
      label: 'Integration ID',
      key: 'integrationId',
      children: agent.integrationId,
    },
    {
      label: 'Agent ID',
      key: 'orchestratorProviderAgentId',
      children: agent.orchestratorProvider?.config.AgentId,
    },
    {
      span: 2,
      label: 'LLM prompt',
      key: 'llmPrompt',
      children: <ExpandableText>{agent.llmPrompt}</ExpandableText>,
    },
  ], [agent]);

  return {
    columns,
  };
};

export default useAgentDetailsColumns;
