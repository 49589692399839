import { TableColumnsType } from 'antd';

import { HumanTemplate } from '../../../api/humanTemplates/types';
import LongTextCell from '../../../components/longTextCell';

export const idColumnKey = 'templateId';

export const columnsInitialState: TableColumnsType<HumanTemplate> = [
  {
    title: 'ID',
    dataIndex: idColumnKey,
    key: idColumnKey,
    fixed: 'left',
    sorter: true,
    sortOrder: 'descend',
    width: 80,
  },
  {
    title: 'Template name',
    dataIndex: 'templateName',
    key: 'templateName',
    sorter: true,
  },
  {
    title: 'Template text',
    dataIndex: 'template',
    key: 'template',
    sorter: true,
    width: 200,
    render: ((value: HumanTemplate['template']) => <LongTextCell value={value} title="Template text" />),
  },
  {
    title: 'Campaign name',
    dataIndex: 'campaignName',
    key: 'campaignName',
    render: ((_, record) => record.campaign.displayName),
  },
];
