import { useCallback } from 'react';
import { Button } from 'antd';

import CreateReferenceDataFieldProvider from '../../providers/createReferenceDataFieldProvider';

const CreateReferenceDataFieldButton = () => {
  const createReferenceDataField = CreateReferenceDataFieldProvider.useCreateReferenceDataField();

  const open = useCallback(() => {
    createReferenceDataField.open();
  }, [createReferenceDataField]);

  return (
    <Button
      className="ml-auto"
      type="primary"
      onClick={open}
    >
      Create reference data field
    </Button>
  );
};

export default CreateReferenceDataFieldButton;
