import { useCallback, useState } from 'react';
import { AxiosError } from 'axios';
import { message } from 'antd';

import { trimCreateCampaignParams } from '../mappers';
import { CreateCampaignParams } from '../../../../../api/campaigns/types';

interface UseCreateCampaignModalLogicArgs {
  state: CreateCampaignParams;
  isValid: (state: CreateCampaignParams) => boolean;
  onConfirm: (params: CreateCampaignParams) => Promise<void>;
  onCancel: () => void;
}

const useCreateCampaignModalLogic = ({
  state,
  isValid,
  onConfirm: onConfirmBase,
  onCancel: onCancelBase,
}: UseCreateCampaignModalLogicArgs) => {
  const [saving, setSaving] = useState(false);

  const onConfirm = useCallback(async () => {
    if (!isValid(state)) {
      return;
    }

    setSaving(true);

    try {
      await onConfirmBase(trimCreateCampaignParams(state));
    } catch (error) {
      console.error(error);

      if (error instanceof AxiosError) {
        message.open({ content: error.response?.data?.message, type: 'error', duration: 10 });
      }
    }

    setSaving(false);
  }, [isValid, state, onConfirmBase]);

  const onCancel = useCallback(() => {
    onCancelBase();
  }, [onCancelBase]);

  return {
    saving,
    onCancel,
    onConfirm,
  };
};

export default useCreateCampaignModalLogic;
