import { useCallback, useEffect, useState } from 'react';
import { message } from 'antd';
import { AxiosError } from 'axios';
import { Page } from '@marrlab-app-shared/types';

import { Customer, GetCustomersParams } from '../../../api/customers/types';
import { getCustomers } from '../../../api/customers';

const useCustomersData = ({
  skip,
  take,
  sortBy,
  order,
  search,
  status,
}: GetCustomersParams) => {
  const [data, setData] = useState<Page<Customer> | null>(null);
  const [loading, setLoading] = useState(false);

  const fetchCustomers = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getCustomers({
        skip,
        take,
        sortBy,
        order,
        search,
        status,
      });

      setData(response.data);
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        message.open({ content: error.message, type: 'error', duration: 10 });
      }
    }

    setLoading(false);
  }, [order, search, skip, sortBy, take, status]);

  useEffect(() => {
    fetchCustomers();
  }, [fetchCustomers]);

  return {
    data,
    loading,
    fetchCustomers,
  };
};

export default useCustomersData;
