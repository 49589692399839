import { useCallback } from 'react';
import { Form, InputNumber } from 'antd';

import { FieldProps } from '../../types';

const InterruptionSensitivityField = ({
  state,
  disabled,
  onUpdate,
}: FieldProps) => {
  const onChange = useCallback((value: number | null) => {
    onUpdate('interruptionSensitivity', value ?? undefined);
  }, [onUpdate]);

  return (
    <Form.Item
      className="field-with-info-tooltip"
      label="Interruption sensitivity"
      tooltip="Controls how sensitive the agent is to user interruptions. Value ranging from [0,1]. Lower value means it will take longer / more words for user to interrupt agent, while higher value means it's easier for user to interrupt agent. If unset, default value 1 will apply. When this is set to 0, agent would never be interrupted."
    >
      <InputNumber
        placeholder="1.00"
        value={state.interruptionSensitivity}
        onChange={onChange}
        disabled={disabled}
        min={0}
        max={1}
        precision={2}
        step={0.1}
      />
    </Form.Item>
  );
};

export default InterruptionSensitivityField;
