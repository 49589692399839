import { ChangeEvent, useCallback } from 'react';
import { Form, Input } from 'antd';

import { FieldProps } from '../../types';

const PromptField = ({
  state,
  disabled,
  onUpdate,
}: FieldProps) => {
  const onChange = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
    onUpdate('prompt', event.target.value);
  }, [onUpdate]);

  return (
    <Form.Item label="Prompt">
      <Input.TextArea
        value={state.prompt}
        onChange={onChange}
        disabled={disabled}
        autoSize={{ minRows: 2, maxRows: 8 }}
      />
    </Form.Item>
  );
};

export default PromptField;
