import { useCallback, useState } from 'react';
import { AxiosError } from 'axios';
import { message } from 'antd';

import { trimCreateReferenceDataParams } from '../mappers';
import { CreateReferenceDataModalState } from '../types';

interface UseCreateReferenceDataModalLogicArgs {
  state: CreateReferenceDataModalState;
  isValid: (state: CreateReferenceDataModalState) => boolean;
  onConfirm: (params: CreateReferenceDataModalState) => Promise<void>;
}

const useCreateReferenceDataModalLogic = ({
  state,
  isValid,
  onConfirm: onConfirmBase,
}: UseCreateReferenceDataModalLogicArgs) => {
  const [saving, setSaving] = useState(false);

  const onConfirm = useCallback(async () => {
    if (!isValid(state)) {
      return;
    }

    setSaving(true);

    try {
      await onConfirmBase(trimCreateReferenceDataParams(state));
    } catch (error) {
      console.error(error);

      if (error instanceof AxiosError) {
        message.open({ content: error.response?.data?.message, type: 'error', duration: 10 });
      }
    }

    setSaving(false);
  }, [isValid, state, onConfirmBase]);

  return {
    saving,
    onConfirm,
  };
};

export default useCreateReferenceDataModalLogic;
