import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import {
  Button,
  message,
  Space,
  Typography,
} from 'antd';
import SuccessNotificationProvider from '@marrlab-app-shared/components/successNotification';

import { trimCreatePersonaParams } from '../mappers';
import { Persona } from '../../../api/personas/types';
import { CreatePersonaModalState } from '../types';
import { createPersona, editPersona } from '../../../api/personas';
import useNavigateLocal from '../../../hooks/navigation/useNavigate';

interface UseCreatePersonaModalLogicArgs {
  state: CreatePersonaModalState;
  initialPersona: Persona | null;
  isValid: (state: CreatePersonaModalState) => boolean;
}

const useCreatePersonaModalLogic = ({
  state,
  isValid,
  initialPersona,
}: UseCreatePersonaModalLogicArgs) => {
  const navigate = useNavigate();
  const { navigateToPersonas } = useNavigateLocal();
  const successNotification = SuccessNotificationProvider.useSuccessNotification();

  const [saving, setSaving] = useState(false);

  const onConfirm = useCallback(async () => {
    if (!isValid(state)) {
      return;
    }

    setSaving(true);

    try {
      const params = trimCreatePersonaParams(state);

      const response = initialPersona
        ? await editPersona(initialPersona.personaId, params)
        : await createPersona(params);

      successNotification.open({
        key: response.data.personaId.toString(),
        title: initialPersona ? 'Changes saved' : 'Persona created successfully',
        message: (
          <>
            <Typography.Text strong>Persona ID: </Typography.Text>
            <Typography.Text>{response.data.personaId}</Typography.Text>
            <br />
            <Typography.Text strong>Persona name: </Typography.Text>
            <Typography.Text>{response.data.personaName}</Typography.Text>
          </>
        ),
        btn: (api, key) => (
          <Space>
            <Button
              type="primary"
              size="small"
              onClick={() => {
                api.destroy(key);
                navigateToPersonas();
              }}
            >
              Go back to the personal list
            </Button>
            <Button
              size="small"
              onClick={() => api.destroy(key)}
            >
              Close
            </Button>
          </Space>
        ),
      });

      if (!initialPersona) {
        navigate(`/personas/${response.data.personaId}`, { replace: true });
      }
    } catch (error) {
      console.error(error);

      if (error instanceof AxiosError) {
        message.open({ content: error.response?.data?.message, type: 'error', duration: 10 });
      }
    }

    setSaving(false);
  }, [
    isValid,
    state,
    initialPersona,
    successNotification,
    navigateToPersonas,
    navigate,
  ]);

  return {
    saving,
    onConfirm,
  };
};

export default useCreatePersonaModalLogic;
