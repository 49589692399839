const vocodePostfix = 'vocode';

export const addVocodePostfixToAgentName = (agentName?: string) => {
  if (!agentName) {
    return '';
  }

  if (agentName.toLowerCase().includes(vocodePostfix)) {
    return agentName;
  }

  return `${agentName}-${vocodePostfix}`;
};

export const removeVocodePostfixFromAgentName = (agentName?: string) => {
  if (!agentName) {
    return '';
  }

  if (agentName.toLowerCase().includes(vocodePostfix)) {
    return agentName.replace(new RegExp(`-*${vocodePostfix}`, 'i'), '');
  }

  return agentName;
};
