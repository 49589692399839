import { useCallback, useEffect, useState } from 'react';

import { CreateReferenceDataParams } from '../../../../../api/referenceData/types';
import { isEmptyNumber, isEmptyString } from '../../../../../utils/validators';
import { CreateReferenceDataModalState } from '../types';

type Errors = Record<string, Partial<Record<keyof CreateReferenceDataParams, string>>>;

interface UseCreateReferenceDataModalValidation {
  isOpen: boolean;
}

const initialState: Errors = {};

const useCreateReferenceDataModalValidation = ({
  isOpen,
}: UseCreateReferenceDataModalValidation) => {
  const [errors, setErrors] = useState<Errors>(initialState);

  const isValid = useCallback((state: CreateReferenceDataModalState) => {
    const newState = Object.values(state).reduce((acc, fieldState) => {
      acc[fieldState.referenceFieldId] = {
        personaId: isEmptyNumber(fieldState.personaId ?? undefined),
        referenceFieldId: isEmptyNumber(fieldState.referenceFieldId ?? undefined),
        referenceDataId: isEmptyNumber(fieldState.referenceDataId ?? undefined),
        referenceData: isEmptyString(fieldState.referenceData),
      };
      return acc;
    }, {} as Errors);

    setErrors(newState);

    return Object.values(newState).every((section) => (
      Object.values(section).every((field) => !field)
    ));
  }, []);

  const clearErrorForField = useCallback((referenceDataFieldId: number, propName: string) => {
    setErrors((state) => ({
      ...state,
      [referenceDataFieldId]: {
        ...state[referenceDataFieldId],
        [propName]: '',
      },
    }));
  }, []);

  useEffect(() => {
    if (!isOpen) {
      setErrors(initialState);
    }
  }, [isOpen]);

  return {
    errors,
    isValid,
    clearErrorForField,
  };
};

export default useCreateReferenceDataModalValidation;
