import { Flex } from 'antd';

import EditPersonaAction from '../editPersonaAction';
import DeletePersonaAction from '../deletePersonaAction';
import { Persona } from '../../../../api/personas/types';

interface ActionsCellProps {
  persona: Persona;
}

const ActionsCell = ({ persona }: ActionsCellProps) => (
  <Flex gap={8}>
    <EditPersonaAction persona={persona} />
    <DeletePersonaAction persona={persona} />
  </Flex>
);

export default ActionsCell;
