import {
  useCallback,
  useEffect,
  useState,
} from 'react';

import { getCreateModalInitialState } from '../mappers';
import { CreateReferenceDataParams, ReferenceData } from '../../../../../api/referenceData/types';
import { Persona } from '../../../../../api/personas/types';
import { CreateReferenceDataModalState } from '../types';
import { ReferenceDataField } from '../../../../../api/referenceDataFields/types';

interface UseCreateReferenceDataModalStateArgs {
  clearErrorForField: (referenceDataFieldId: number, propName: string) => void;
  persona: Persona | null;
  initialReferenceData: ReferenceData | null;
  initialReferenceDataId: number | null;
  isOpen: boolean;
  referenceDataFields: ReferenceDataField[];
}

const useCreateReferenceDataModalState = ({
  isOpen,
  persona,
  initialReferenceData,
  initialReferenceDataId,
  clearErrorForField,
  referenceDataFields,
}: UseCreateReferenceDataModalStateArgs) => {
  const [state, setState] = useState<CreateReferenceDataModalState>(getCreateModalInitialState({}));

  const onPropertyUpdate = useCallback((
    referenceDataFieldId: number,
    propName: keyof CreateReferenceDataParams,
    value: CreateReferenceDataParams[keyof CreateReferenceDataParams],
  ) => {
    setState((oldState) => {
      const referenceData = oldState[referenceDataFieldId] ?? {};

      return ({
        ...oldState,
        [referenceDataFieldId]: {
          ...referenceData,
          [propName]: value,
        },
      });
    });

    clearErrorForField(referenceDataFieldId, propName);
  }, [clearErrorForField]);

  useEffect(() => {
    setState(getCreateModalInitialState({
      initialReferenceData: isOpen ? initialReferenceData : null,
      persona: isOpen ? persona : null,
      referenceDataFields: isOpen ? referenceDataFields : null,
      initialReferenceDataId,
    }));
  }, [initialReferenceData, initialReferenceDataId, isOpen, persona, referenceDataFields]);

  const isEdit = !!initialReferenceData;

  return {
    isEdit,
    state,
    onPropertyUpdate,
  };
};

export default useCreateReferenceDataModalState;
