import { Popover, Typography } from 'antd';

import { Fragment, useMemo } from 'react';

import './index.scss';

interface LongTextCellProps {
  value?: string | string[];
  title: string;
  width?: string;
}

const LongTextCell = ({
  value: rawValue,
  title,
  width,
}: LongTextCellProps) => {
  const value = useMemo(() => {
    if (Array.isArray(rawValue)) {
      return rawValue;
    }

    return [rawValue];
  }, [rawValue]);

  if (!rawValue) {
    return null;
  }

  return (
    <Popover
      overlayClassName="campaign-params-cell-popover"
      content={(
        <>
          {value.map((row, index) => (
            //  eslint-disable-next-line react/no-array-index-key
            <Fragment key={index}>
              <Typography.Text className="whitespace-pre-wrap">
                {row}
              </Typography.Text>
              <br />
            </Fragment>
          ))}
        </>
      )}
      title={(
        <Typography.Title level={5} className="mt-0">{title}</Typography.Title>
      )}
    >
      <Typography.Text
        ellipsis
        className={width ?? 'w-48'}
      >
        <Typography.Text>{value}</Typography.Text>
      </Typography.Text>
    </Popover>
  );
};

export default LongTextCell;
