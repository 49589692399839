import { CreateHumanTemplateParams, HumanTemplate } from '../../../../../api/humanTemplates/types';
import { extractPlaceholdersFromTemplate } from '../../../../../utils/humanTemplate';

export const trimCreateHumanTemplateParams = (
  params: CreateHumanTemplateParams,
): CreateHumanTemplateParams => {
  const template = params.template?.trim();

  return {
    templateName: params.templateName?.trim(),
    campaignId: params.campaignId,
    template,
    placeholders: extractPlaceholdersFromTemplate(template),
  };
};

export const getHumanTemplateModalInitialState = (
  initialHumanTemplate?: HumanTemplate | null,
): CreateHumanTemplateParams => {
  if (!initialHumanTemplate) {
    return {
      templateName: '',
      campaignId: '',
      template: '',
      placeholders: [],
    };
  }

  return {
    templateName: initialHumanTemplate.templateName,
    campaignId: initialHumanTemplate.campaignId,
    template: initialHumanTemplate.template,
    placeholders: [],
  };
};
