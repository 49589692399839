import { Form, Input } from 'antd';

import { FieldProps } from '../../types';

const ReferenceDataFieldField = ({
  referenceDataField,
  error,
}: FieldProps) => (
  <Form.Item
    className="flex-1"
    label="Reference data field"
    required
    validateStatus={error ? 'error' : undefined}
    help={error}
  >
    <Input
      value={referenceDataField.referenceFieldName ?? ''}
      disabled
    />
  </Form.Item>
);

export default ReferenceDataFieldField;
