import { useCallback } from 'react';
import { Menu } from 'antd';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import config from '../../utils/config';

import './index.scss';

const testingToolsMenuItems = config.TESTING_TOOLS_ENABLED
  ? [{
    label: 'Testing tools',
    key: 'testing',
    children: [
      {
        label: 'Human templates',
        key: '/human-templates',
      },
      {
        label: 'Questions',
        key: '/questions',
      },
      {
        label: 'Reference data fields',
        key: '/reference-data-fields',
      },
      {
        label: 'Personas',
        key: '/personas',
      },
    ],
  }]
  : [];

const menuItems = [
  {
    label: 'Agent providers',
    key: '/',
  },
  {
    label: 'Campaigns',
    key: '/campaigns',
  },
  {
    label: 'Customers',
    key: '/customers',
  },
  {
    label: 'Configs',
    key: '/configs',
    children: [
      {
        label: 'ASR configs',
        key: '/asr-configs',
      },
      {
        label: 'TTS configs',
        key: '/tts-configs',
      },
      {
        label: 'Voice agent configs',
        key: '/voice-agent-configs',
      },
    ],
  },
  ...testingToolsMenuItems,
];

const Navigation = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const onMenuClick = useCallback((info: { key: string }) => {
    navigate(info.key);
  }, [navigate]);

  return (
    <>
      <Menu
        className="global-navigation-menu mb-6"
        onClick={onMenuClick}
        selectedKeys={[location.pathname]}
        mode="horizontal"
        items={menuItems}
      />
      <Outlet />
    </>
  );
};

export default Navigation;
