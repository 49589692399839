import { useCallback, useState } from 'react';
import { Button, Popconfirm, Tooltip } from 'antd';
import { PhoneOutlined } from '@ant-design/icons';

import { AgentProvider } from '../../../../api/agentProviders/types';
import UnassignNumberProvider from '../../../agentProviders/providers/unassignNumberProvider';

import './index.scss';

interface UnassignNumberButtonProps {
  agent: AgentProvider;
}

const UnassignNumberButton = ({ agent }: UnassignNumberButtonProps) => {
  const { loading, onUnassignNumber } = UnassignNumberProvider.useUnassignNumber();

  const [open, setOpen] = useState(false);

  const isInactive = !agent.active;
  const hasNumberAssigned = !agent.orchestratorNumberAssignment;

  const isDisabled = isInactive || hasNumberAssigned;

  let tooltip = '';

  if (isInactive) {
    tooltip = 'The agent marked as archived';
  }

  if (hasNumberAssigned) {
    tooltip = 'Agent has no number assigned';
  }

  const showConfirmation = useCallback(() => setOpen(true), []);

  const onConfirm = useCallback(async () => {
    if (agent.orchestratorNumberAssignment?.orchestratorNumberAssignmentId) {
      await onUnassignNumber(agent.orchestratorNumberAssignment.orchestratorNumberAssignmentId);
    }

    setOpen(false);
  }, [agent.orchestratorNumberAssignment, onUnassignNumber]);

  const onCancel = useCallback(() => setOpen(false), []);

  return (
    <Popconfirm
      title={`Unassign number from the agent #${agent.agentProviderId}`}
      description="Are you sure to unassign number from the agent?"
      open={open}
      onConfirm={onConfirm}
      okButtonProps={{ loading }}
      onCancel={onCancel}
      disabled={isDisabled}
    >
      <Tooltip title={tooltip}>
        <Button
          icon={<PhoneOutlined className="unassign-number-button-icon" />}
          onClick={showConfirmation}
          disabled={isDisabled}
        >
          Unassign phone number
        </Button>
      </Tooltip>
    </Popconfirm>
  );
};

export default UnassignNumberButton;
