import { TableColumnsType } from 'antd';

import ActionsCell from '../components/actionsCell';
import StatusCell from '../../../components/statusCell';
import { Campaign } from '../../../api/campaigns/types';

export const idColumnKey = 'campaignId';

export const columnsInitialState: TableColumnsType<Campaign> = [
  {
    title: 'ID',
    dataIndex: idColumnKey,
    key: idColumnKey,
    fixed: 'left',
    sorter: true,
    sortOrder: 'descend',
    width: 80,
  },
  {
    title: 'Status',
    dataIndex: 'active',
    key: 'active',
    sorter: true,
    width: 90,
    render: ((value: Campaign['active']) => <StatusCell value={value} />),
  },
  {
    title: 'Campaign name',
    dataIndex: 'displayName',
    key: 'displayName',
    sorter: true,
  },
  {
    title: 'Customer name',
    dataIndex: 'customerName',
    key: 'customerName',
    sorter: true,
    render: ((_, record) => record.customer.name),
  },
  {
    title: 'Human review',
    dataIndex: 'humanReview',
    key: 'humanReview',
    width: 90,
    sorter: true,
    render: (value: Campaign['humanReview']) => (value ? 'Yes' : 'No'),
  },

  {
    title: 'Actions',
    width: 104,
    fixed: 'right',
    render: ((_, record) => <ActionsCell campaign={record} />),
  },
];
