import { useCallback } from 'react';
import { Form, Switch } from 'antd';

import { FieldProps } from '../../types';

const CreateTwilioNumberField = ({
  state,
  disabled,
  onUpdate,
}: FieldProps) => {
  const onChange = useCallback((checked: boolean) => {
    onUpdate('createTwilioNumber', checked);
  }, [onUpdate]);

  return (
    <Form.Item label="Create Twilio number">
      <Switch
        checked={state.createTwilioNumber}
        onChange={onChange}
        disabled={disabled}
      />
    </Form.Item>
  );
};

export default CreateTwilioNumberField;
