import { Form, Modal, Typography } from 'antd';
import ModalFooter from '@marrlab-app-shared/components/modalFooter';

import ProviderTypeField from './components/providerTypeField';
import ApiVersionField from './components/apiVersionField';
import ClassField from './components/classField';
import LlmPromptField from './components/llmPromptField';
import PromptField from './components/promptField';
import FunctionToolsField from './components/functionToolsField';
import VectorDbConfigField from './components/vectorDbConfigField';
import AgentNameField from './components/agentNameField';
import VoiceIdField from './components/voiceIdField';
import OrchestratorProviderTypeField from './components/orchestratorProviderTypeField';
import CampaignField from './components/campaignField';
import MultiplePromptsField from './components/multiplePromptsField';
import IntegrationField from './components/integrationField';
import WebsocketBaseUrlField from './components/websocketBaseUrlField';
import CopyRetellLlmField from './components/copyRetellLlmField';
import LlmIdField from './components/llmIdField';
import DataModelConfigField from './components/dataModelConfigField';
import EmbeddingAliasField from './components/embeddingAliasField';
import ChatModelAliasField from './components/chatModelAliasField';
import DataModelAliasField from './components/dataModelAliasField';
import VoiceTemperatureField from './components/voiceTemperatureField';
import VoiceSpeedField from './components/voiceSpeedField';
import ResponsivenessField from './components/responsivenessField';
import InterruptionSensitivityField from './components/interruptionSensitivityField';
import EnableBackchannelField from './components/enableBackchannelField';
import BackchannelFrequencyField from './components/backchannelFrequencyField';
import ReminderTriggerField from './components/reminderTriggerField';
import ReminderMaxCountField from './components/reminderMaxCountField';
import BackchannelWordsField from './components/backchannelWordsField';
import BoostedKeywordsField from './components/boostedKeywordsField';
import PronunciationDictionaryField from './components/pronunciationDictionaryField';
import DirectionField from './components/directionField';
import DynamicPromptsField from './components/dynamicPromptsField';
import PersonaField from './components/personaField';
import AsrConfigField from './components/asrConfigField';
import TtsConfigField from './components/ttsConfigField';
import VoiceAgentConfigField from './components/voiceAgentConfigField';
import ReplicasField from './components/replicasField';
import DeploymentPathField from './components/deploymentPathField';

import useCreateAgentModalLogic from './hooks/useCreateAgentModalLogic';
import useCreateAgentModalState from './hooks/useCreateAgentModalState';
import useCreateAgentModalValidation from './hooks/useCreateAgentModalValidation';
import {
  AgentProvider,
  CreateAgentParams,
  OrchestratorProviderTypes,
} from '../../../../api/agentProviders/types';
import { SCROLLABLE_CONTAINER_ID } from './constants';
import { CreateAgentDialogPrefilledData } from '../../types';
import { AgentType } from './types';

import './index.scss';

interface CreateAgentModalProps {
  isOpen: boolean;
  previewMode: boolean;
  prefilledData: CreateAgentDialogPrefilledData | null;
  initialAgent: AgentProvider | null;
  onConfirm: (params: CreateAgentParams) => Promise<void>;
  onCancel: () => void;
}

const CreateAgentModal = ({
  isOpen,
  previewMode,
  prefilledData,
  initialAgent,
  onConfirm: onConfirmBase,
  onCancel,
}: CreateAgentModalProps) => {
  const {
    errors,
    isValid,
    clearErrorForField,
  } = useCreateAgentModalValidation({ isOpen, initialAgent });

  const {
    isEdit,
    state,
    onPropertyUpdate,
  } = useCreateAgentModalState({
    isOpen,
    prefilledData,
    initialAgent,
    clearErrorForField,
  });

  const { saving, onConfirm } = useCreateAgentModalLogic({
    state,
    isValid,
    onConfirm: onConfirmBase,
  });

  const isHumanAgent = state.agentType === AgentType.humanAgent;

  let title = isHumanAgent ? 'Create human agent' : 'Create agent';

  if (isEdit) {
    title = `Edit & copy agent #${initialAgent?.agentProviderId}`;
  }

  if (previewMode) {
    title = 'Preview agent';
  }

  const disabled = saving || previewMode;

  const isVocode = state.orchestratorProviderType === OrchestratorProviderTypes.vocode;

  return (
    <Modal
      title={title}
      open={isOpen}
      onOk={onConfirm}
      onCancel={onCancel}
      width="800px"
      footer={(
        <ModalFooter
          disabled={saving}
          showSaveButton={!previewMode}
          saveButtonLabel={isEdit ? 'Save' : 'Create'}
          onCancel={onCancel}
          onConfirm={onConfirm}
        />
      )}
    >
      <Form
        id={SCROLLABLE_CONTAINER_ID}
        layout="vertical"
        className="create-agent-modal-scrollable-area py-4"
      >
        <Typography.Title level={5} className="mt-0">
          Agent provider fields
        </Typography.Title>
        {isHumanAgent && (
          <PersonaField
            state={state}
            onUpdate={onPropertyUpdate}
            disabled={disabled}
            error={errors.personaId}
          />
        )}
        <ProviderTypeField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={disabled}
          error={errors.providerType}
        />
        <ApiVersionField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={disabled}
          error={errors.apiVersion}
        />
        <ClassField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={disabled}
          error={errors.class}
        />
        <LlmPromptField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={disabled || isHumanAgent}
        />
        <PromptField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={disabled}
        />
        <MultiplePromptsField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={disabled}
        />
        <FunctionToolsField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={disabled}
          error={errors.functionTools}
        />
        <IntegrationField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={disabled}
        />
        <VectorDbConfigField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={disabled}
          error={errors.vectorDbConfig}
        />
        <DataModelConfigField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={disabled}
          error={errors.dataModelConfig}
        />
        <DynamicPromptsField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={disabled}
          error={errors.dynamicPrompts}
        />
        <EmbeddingAliasField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={disabled}
        />
        <ChatModelAliasField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={disabled}
        />
        <DataModelAliasField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={disabled}
        />
        <Typography.Title level={5}>Orchestrator provider fields</Typography.Title>
        <CampaignField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={disabled}
          error={errors.campaignId}
        />
        <OrchestratorProviderTypeField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={disabled}
          error={errors.orchestratorProviderType}
        />
        <DirectionField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={disabled}
          error={errors.direction}
        />
        <AgentNameField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={disabled}
          error={errors.agentName}
        />
        <WebsocketBaseUrlField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={disabled || !!state.copyRetellLlm}
          error={errors.websocketBaseUrl}
        />
        <CopyRetellLlmField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={disabled}
        />
        <LlmIdField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={disabled || !state.copyRetellLlm}
          error={errors.llmId}
        />
        <VoiceIdField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={disabled}
          error={errors.voiceId}
        />
        <VoiceTemperatureField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={disabled}
        />
        <VoiceSpeedField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={disabled}
        />
        <ResponsivenessField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={disabled}
        />
        <InterruptionSensitivityField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={disabled}
        />
        <EnableBackchannelField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={disabled}
        />
        <BackchannelFrequencyField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={disabled || !state.enableBackchannel}
        />
        <BackchannelWordsField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={disabled || !state.enableBackchannel}
        />
        <ReminderTriggerField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={disabled}
        />
        <ReminderMaxCountField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={disabled}
        />
        <BoostedKeywordsField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={disabled}
        />
        <PronunciationDictionaryField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={disabled}
          error={errors.pronunciationDictionary}
        />
        {isVocode && (
          <>
            <TtsConfigField
              state={state}
              onUpdate={onPropertyUpdate}
              disabled={disabled}
              error={errors.ttsConfigId}
            />
            <AsrConfigField
              state={state}
              onUpdate={onPropertyUpdate}
              disabled={disabled}
              error={errors.asrConfigId}
            />
            <VoiceAgentConfigField
              state={state}
              onUpdate={onPropertyUpdate}
              disabled={disabled}
              error={errors.voiceAgentConfigId}
            />
            <DeploymentPathField
              state={state}
              onUpdate={onPropertyUpdate}
              disabled={disabled}
            />
            <ReplicasField
              state={state}
              onUpdate={onPropertyUpdate}
              disabled={disabled}
            />
          </>
        )}
      </Form>
    </Modal>
  );
};

export default CreateAgentModal;
