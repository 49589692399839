import { useCallback } from 'react';
import { Button, Tooltip } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import { AgentProvider } from '../../../../api/agentProviders/types';
import CreateAgentProvider from '../../providers/createAgentProvider';

interface EditAgentActionProps {
  agent: AgentProvider;
}

const EditAgentAction = ({ agent }: EditAgentActionProps) => {
  const createAgent = CreateAgentProvider.useCreateAgent();

  const isDisabled = !agent.active;
  const humanAgent = !!agent.personaId;

  let tooltip = 'Copy & edit agent';

  if (isDisabled) {
    tooltip = 'The agent marked as archived';
  }

  if (humanAgent) {
    tooltip = 'Human agent cannot be copied';
  }

  const open = useCallback(() => {
    createAgent.openOnEdit(agent);
  }, [agent, createAgent]);

  return (
    <Tooltip title={tooltip}>
      <Button
        type="primary"
        ghost
        icon={<EditOutlined />}
        size="middle"
        onClick={open}
        disabled={isDisabled || humanAgent}
      />
    </Tooltip>
  );
};

export default EditAgentAction;
