import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { AxiosError } from 'axios';
import { message } from 'antd';

import { unassignNumber } from '../../../../api/agentProviders';

interface UnassignNumberContextType {
  loading: boolean;
  onUnassignNumber: (numberAssignmentId: number) => Promise<void>;
}

const initialState: UnassignNumberContextType = {
  loading: false,
  onUnassignNumber: () => Promise.reject(),
};

const UnassignNumberContext = createContext<UnassignNumberContextType>(initialState);

interface UnassignNumberProviderProps extends PropsWithChildren {
  fetchAgentProviders: () => Promise<void>;
}

const UnassignNumberProvider = ({ children, fetchAgentProviders }: UnassignNumberProviderProps) => {
  const [loading, setLoading] = useState(false);

  const onUnassignNumber = useCallback(async (numberAssignmentId: number) => {
    setLoading(true);

    try {
      await unassignNumber(numberAssignmentId);

      await fetchAgentProviders();
    } catch (error) {
      if (error instanceof AxiosError) {
        message.open({ content: error.response?.data?.message, type: 'error', duration: 10 });
      }
    }

    setLoading(false);
  }, [fetchAgentProviders]);

  const value: UnassignNumberContextType = useMemo(
    () => ({
      loading,
      onUnassignNumber,
    }),
    [loading, onUnassignNumber],
  );

  return (
    <UnassignNumberContext.Provider value={value}>
      {children}
    </UnassignNumberContext.Provider>
  );
};

UnassignNumberProvider.useUnassignNumber = () => useContext(UnassignNumberContext);

export default UnassignNumberProvider;
