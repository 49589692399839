import { TableColumnsType } from 'antd';

import ActionsCell from '../components/actionsCell';
import StatusCell from '../../../components/statusCell';
import { Customer } from '../../../api/customers/types';

export const idColumnKey = 'customerId';

export const columnsInitialState: TableColumnsType<Customer> = [
  {
    title: 'ID',
    dataIndex: idColumnKey,
    key: idColumnKey,
    fixed: 'left',
    sorter: true,
    sortOrder: 'descend',
    width: 80,
  },
  {
    title: 'Status',
    dataIndex: 'active',
    key: 'active',
    sorter: true,
    width: 90,
    render: ((value: Customer['active']) => <StatusCell value={value} />),
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sorter: true,
  },
  {
    title: 'Short name',
    dataIndex: 'shortName',
    key: 'shortName',
    sorter: true,
  },
  {
    title: 'Actions',
    width: 104,
    fixed: 'right',
    render: ((_, record) => <ActionsCell customer={record} />),
  },
];
