import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { AxiosError } from 'axios';
import { message } from 'antd';

import { deleteReferenceDataField } from '../../../../api/referenceDataFields';

interface DeleteReferenceDataFieldContextType {
  loading: boolean;
  onDeleteReferenceDataField: (referenceDataFieldId: number) => Promise<void>;
}

const initialState: DeleteReferenceDataFieldContextType = {
  loading: false,
  onDeleteReferenceDataField: () => Promise.reject(),
};

const DeleteReferenceDataFieldContext = createContext<DeleteReferenceDataFieldContextType>(
  initialState,
);

interface DeleteReferenceDataFieldProviderProps extends PropsWithChildren {
  fetchReferenceDataFields: () => Promise<void>;
}

const DeleteReferenceDataFieldProvider = ({
  children,
  fetchReferenceDataFields,
}: DeleteReferenceDataFieldProviderProps) => {
  const [loading, setLoading] = useState(false);

  const onDeleteReferenceDataField = useCallback(async (referenceDataFieldId: number) => {
    setLoading(true);

    try {
      await deleteReferenceDataField(referenceDataFieldId);

      await fetchReferenceDataFields();
    } catch (error) {
      if (error instanceof AxiosError) {
        message.open({ content: error.response?.data?.message, type: 'error', duration: 10 });
      }
    }

    setLoading(false);
  }, [fetchReferenceDataFields]);

  const value: DeleteReferenceDataFieldContextType = useMemo(
    () => ({
      loading,
      onDeleteReferenceDataField,
    }),
    [loading, onDeleteReferenceDataField],
  );

  return (
    <DeleteReferenceDataFieldContext.Provider value={value}>
      {children}
    </DeleteReferenceDataFieldContext.Provider>
  );
};

DeleteReferenceDataFieldProvider.useDeleteReferenceDataField = () => (
  useContext(DeleteReferenceDataFieldContext)
);

export default DeleteReferenceDataFieldProvider;
