import axios from 'axios';
import { Page } from '@marrlab-app-shared/types';

import {
  TtsConfig,
  CreateTtsConfigParams,
  GetTtsConfigParams,
} from './types';
import config from '../../utils/config';

export const getTtsConfigs = async (params: GetTtsConfigParams) => (
  axios.get<Page<TtsConfig>>(`${config.CALLS_HISTORY_API_BASE_URL}/api/tts-config/`, { params })
);

export const createTtsConfig = async (params: CreateTtsConfigParams) => (
  axios.post<TtsConfig>(`${config.CALLS_HISTORY_API_BASE_URL}/api/tts-config`, params)
);

export const editTtsConfig = async (id: number, params: CreateTtsConfigParams) => (
  axios.put<TtsConfig>(`${config.CALLS_HISTORY_API_BASE_URL}/api/tts-config/${id}`, params)
);
