export const doesAgentHasLlm = (websocketUrl?: string) => !!websocketUrl?.includes('retell-llm');

export const getLlmIdFromWebsocketUrl = (url?: string) => {
  if (!url) {
    return undefined;
  }

  const tokens = url.split('/');

  if (url.endsWith('/')) {
    return tokens[tokens.length - 2];
  }

  return tokens[tokens.length - 1];
};
