import { ChangeEvent, useCallback } from 'react';
import { Form, Input } from 'antd';

import { FieldProps } from '../../types';

const PersonaDescriptionField = ({
  state,
  disabled,
  onUpdate,
}: FieldProps) => {
  const onChange = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
    onUpdate('personaDescription', event.target.value);
  }, [onUpdate]);

  return (
    <Form.Item label="Persona description">
      <Input.TextArea
        value={state.personaDescription}
        onChange={onChange}
        disabled={disabled}
      />
    </Form.Item>
  );
};

export default PersonaDescriptionField;
