import { Modal, Form } from 'antd';
import ModalFooter from '@marrlab-app-shared/components/modalFooter';

import QuestionField from './components/questionField';
import CampaignField from './components/campaignField';
import FailValueField from './components/failValueField';
import IsInfoOnlyField from './components/isInfoOnlyField';

import useCreateQuestionModalLogic from './hooks/useCreateQuestionModalLogic';
import useCreateQuestionModalState from './hooks/useCreateQuestionModalState';
import useCreateQuestionModalValidation from './hooks/useCreateQuestionModalValidation';
import { CreateQuestionParams, Question } from '../../../../api/questions/types';
import { CreateQuestionDialogRelatedData } from '../../types';

interface CreateQuestionModalProps {
  isOpen: boolean;
  initialQuestion: Question | null;
  relatedData: CreateQuestionDialogRelatedData | null;
  onConfirm: (params: CreateQuestionParams) => Promise<void>;
  onCancel: () => void;
}

const CreateQuestionModal = ({
  isOpen,
  initialQuestion,
  relatedData,
  onConfirm: onConfirmBase,
  onCancel,
}: CreateQuestionModalProps) => {
  const {
    errors,
    isValid,
    clearErrorForField,
  } = useCreateQuestionModalValidation({ isOpen });

  const {
    isEdit,
    state,
    onPropertyUpdate,
  } = useCreateQuestionModalState({
    isOpen,
    initialQuestion,
    relatedData,
    clearErrorForField,
  });

  const { saving, onConfirm } = useCreateQuestionModalLogic({
    state,
    isValid,
    onConfirm: onConfirmBase,
  });

  return (
    <Modal
      title={isEdit ? `Edit question #${initialQuestion?.questionId}` : 'Create question'}
      open={isOpen}
      onOk={onConfirm}
      onCancel={onCancel}
      width="600px"
      footer={(
        <ModalFooter
          disabled={saving}
          saveButtonLabel={isEdit ? 'Save' : 'Create'}
          onCancel={onCancel}
          onConfirm={onConfirm}
        />
      )}
    >
      <Form
        layout="vertical"
        className="py-4"
      >
        <QuestionField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={saving}
          error={errors.question}
        />
        <CampaignField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={saving || !!relatedData}
          error={errors.campaignId}
        />
        <FailValueField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={saving}
          error={errors.failValue}
        />
        <IsInfoOnlyField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={saving}
          error={errors.isInfoOnly}
        />
      </Form>
    </Modal>
  );
};

export default CreateQuestionModal;
