import { useCallback, useEffect, useMemo } from 'react';
import {
  Button,
  Empty,
  Flex,
  Form,
  Select,
  Spin,
} from 'antd';
import usePaginatedDataFetchWithSearch from '@marrlab-app-shared/hooks/data/usePaginatedDataFetchWithSearch';

import { FieldProps } from '../../types';
import { getQuestions } from '../../../../api/questions';
import CreateQuestionProvider from '../../../questions/providers/createQuestionProvider';
import CopyQuestionPopover from '../copyQuestionPopover';
import { SortOrder } from '../../../../constants';

import './index.scss';

interface QuestionsFieldProps extends FieldProps {
  campaignId?: string;
}

const QuestionsField = ({
  state,
  disabled,
  campaignId,
  error,
  onUpdate,
}: QuestionsFieldProps) => {
  const request = useCallback(({ search }: { search: string }) => {
    if (!campaignId) {
      return Promise.resolve();
    }

    return getQuestions({
      search,
      take: 100,
      order: SortOrder.descend,
      campaign: campaignId,
    });
  }, [campaignId]);

  const createQuestion = CreateQuestionProvider.useCreateQuestion();

  const {
    updateSearch,
    loading,
    data,
    fetchData,
  } = usePaginatedDataFetchWithSearch({
    request,
  });

  const onChange = useCallback((value?: number[]) => {
    onUpdate('questionIds', value ?? []);
  }, [onUpdate]);

  const clearSelection = useCallback(() => {
    onUpdate('questionIds', []);
  }, [onUpdate]);

  const selectAll = useCallback(() => {
    onUpdate('questionIds', data?.map((question) => question.questionId) ?? []);
  }, [data, onUpdate]);

  const options = useMemo(() => (
    data?.map((item) => ({
      value: item.questionId,
      label: item.question,
    })) ?? []
  ), [data]);

  useEffect(() => {
    fetchData();
  }, [state.questionIds, fetchData]);

  return (
    <Form.Item
      className="field-with-info-tooltip"
      label="Questions"
      required
      validateStatus={error ? 'error' : undefined}
      help={error}
      tooltip={state.campaignId ? 'Multiple questions can be selected' : 'Select campaign to unblock that field'}
    >
      <Select
        mode="multiple"
        value={state.questionIds}
        onChange={onChange}
        onSearch={updateSearch}
        loading={loading}
        options={options}
        showSearch
        allowClear
        filterOption={false}
        notFoundContent={loading ? (
          <Flex justify="center" align="center" className="h-20">
            <Spin />
          </Flex>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
        disabled={disabled || !state.campaignId}
      />
      <Flex
        align="center"
        gap={16}
      >
        <Button
          className="add-question-button"
          type="link"
          onClick={selectAll}
          disabled={disabled || !state.campaignId}
        >
          Select all
        </Button>
        <Button
          className="add-question-button"
          type="link"
          onClick={clearSelection}
          disabled={disabled || !state.campaignId}
        >
          Unselect all
        </Button>
        <CopyQuestionPopover
          campaignId={state.campaignId}
          disabled={disabled || !state.campaignId}
          setQuestions={onChange}
        />
        <Button
          className="ml-auto add-question-button"
          type="link"
          onClick={() => createQuestion.open({ campaignId: campaignId?.toString() ?? '' })}
          disabled={disabled || !state.campaignId}
        >
          Add new question
        </Button>
      </Flex>
    </Form.Item>
  );
};

export default QuestionsField;
