import { useCallback } from 'react';
import { Button, Tooltip } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import CreateReferenceDataProvider from '../../providers/createReferenceDataProvider';
import { ReferenceData } from '../../../../api/referenceData/types';

interface EditReferenceDataActionProps {
  referenceData: ReferenceData;
}

const EditReferenceDataAction = ({ referenceData }: EditReferenceDataActionProps) => {
  const createReferenceData = CreateReferenceDataProvider.useCreateReferenceData();

  const open = useCallback(() => {
    createReferenceData.openOnEdit(referenceData);
  }, [createReferenceData, referenceData]);

  return (
    <Tooltip title="Edit reference data">
      <Button
        type="primary"
        ghost
        icon={<EditOutlined />}
        size="middle"
        onClick={open}
      />
    </Tooltip>
  );
};

export default EditReferenceDataAction;
