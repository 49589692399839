import {
  Button,
  Flex,
  Pagination,
  Table,
} from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import useSearchDebounce from '@marrlab-app-shared/hooks/state/useSearchDebounce';

import Search from './components/search';
import CreateReferenceDataProvider from './providers/createReferenceDataProvider';
import DeleteReferenceDataProvider from './providers/deleteReferenceDataProvider';
import CreateReferenceDataButton from './components/createReferenceDataButton';
import ManagePersonaProvider from '../../pages/personaManagement/providers/managePersonaProvider';

import usePagination from '../../hooks/table/usePagination';
import useReferenceDataData from './hooks/useReferenceData';
import useSorting from './hooks/useSorting';
import useTableConfiguration from './hooks/useTableConfiguration';
import { idColumnKey } from './constants/columnsInitialState';

import './index.scss';

const ReferenceData = () => {
  const { persona } = ManagePersonaProvider.useManagePersona();

  const {
    columns,
    sticky,
    scroll,
    setColumns,
  } = useTableConfiguration();
  const {
    skip,
    page,
    pageSize,
    resetPage,
    onPageChange,
    showTotal,
  } = usePagination();
  const { search, updateSearch } = useSearchDebounce({ onDebounce: resetPage });
  const { sortBy, sortDirection, onSortChange } = useSorting({ setColumns });
  const { data, loading, fetchReferenceData } = useReferenceDataData(persona?.personaId, {
    skip,
    take: pageSize,
    sortBy,
    order: sortDirection,
    search,
  });

  return (
    <DeleteReferenceDataProvider fetchReferenceData={fetchReferenceData}>
      <CreateReferenceDataProvider
        fetchReferenceData={fetchReferenceData}
        persona={persona}
      >
        <Flex
          gap={16}
          className="mb-4"
          justify="flex-start"
        >
          <Search updateSearch={updateSearch} />
          <Button
            icon={<ReloadOutlined />}
            onClick={fetchReferenceData}
          >
            Reload
          </Button>
          <CreateReferenceDataButton />
        </Flex>
        <div className="table-wrapper">
          <Table
            rowKey={idColumnKey}
            dataSource={data?.data}
            columns={columns}
            loading={loading}
            scroll={scroll}
            pagination={false}
            sticky={sticky}
            onChange={onSortChange}
          />
        </div>
        <Flex justify="flex-end">
          <Pagination
            hideOnSinglePage
            className="mt-4"
            total={data?.total}
            showTotal={showTotal}
            pageSize={pageSize}
            current={page}
            showSizeChanger
            onChange={onPageChange}
            disabled={loading}
          />
        </Flex>
      </CreateReferenceDataProvider>
    </DeleteReferenceDataProvider>
  );
};

export default ReferenceData;
