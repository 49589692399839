import { useCallback } from 'react';
import { Button } from 'antd';

import CreateHumanTemplateProvider from '../../providers/createHumanTemplateProvider';

const CreateHumanTemplate = () => {
  const createHumanTemplate = CreateHumanTemplateProvider.useCreateHumanTemplate();

  const open = useCallback(() => {
    createHumanTemplate.open();
  }, [createHumanTemplate]);

  return (
    <Button
      className="ml-auto"
      type="primary"
      onClick={open}
    >
      Create human template
    </Button>
  );
};

export default CreateHumanTemplate;
