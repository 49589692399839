import { useCallback, useState } from 'react';
import { AxiosError } from 'axios';
import { message } from 'antd';

import { CreateAgentModalState } from '../types';
import { trimCreateAgentParams } from '../mappers';
import { CreateAgentParams } from '../../../../../api/agentProviders/types';

interface UseCreateAgentModalLogicArgs {
  state: CreateAgentModalState;
  isValid: (state: CreateAgentModalState) => Promise<boolean>;
  onConfirm: (params: CreateAgentParams) => Promise<void>;
}

const useCreateAgentModalLogic = ({
  state,
  isValid,
  onConfirm: onConfirmBase,
}: UseCreateAgentModalLogicArgs) => {
  const [saving, setSaving] = useState(false);

  const onConfirm = useCallback(async () => {
    if (!await isValid(state)) {
      message.open({ content: 'Some fields are not valid', type: 'error', duration: 10 });
      return;
    }

    setSaving(true);

    try {
      const mappedState = await trimCreateAgentParams(state);

      await onConfirmBase(mappedState);
    } catch (error) {
      console.error(error);

      if (error instanceof AxiosError) {
        message.open({ content: error.response?.data?.message, type: 'error', duration: 10 });
      }
    }

    setSaving(false);
  }, [isValid, state, onConfirmBase]);

  return {
    saving,
    onConfirm,
  };
};

export default useCreateAgentModalLogic;
