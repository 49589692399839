import { ChangeEvent, useCallback } from 'react';
import { Form, Input, Typography } from 'antd';

import { FieldProps } from '../../types';
import { extractPlaceholdersFromTemplate } from '../../../../../../utils/humanTemplate';

const TemplateField = ({
  state,
  disabled,
  error,
  onUpdate,
}: FieldProps) => {
  const onChange = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
    onUpdate('template', event.target.value);
  }, [onUpdate]);

  const placeholders = extractPlaceholdersFromTemplate(state.template);

  return (
    <Form.Item
      required
      className="field-with-info-tooltip"
      label="Template text"
      tooltip="Placeholders must be wrapped in triangle brackets. E.g. <placeholder_name>"
      validateStatus={error ? 'error' : undefined}
      help={error}
    >
      <Input.TextArea
        className="mb-2"
        value={state.template}
        placeholder="Human template example with <placeholder>"
        onChange={onChange}
        disabled={disabled}
        autoSize={{ minRows: 2, maxRows: 8 }}
      />
      <Typography.Text>
        <Typography.Text strong>
          Placeholders:
          {' '}
        </Typography.Text>
        {placeholders.join(', ')}
      </Typography.Text>
    </Form.Item>
  );
};

export default TemplateField;
