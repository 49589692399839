import { useCallback, useEffect, useState } from 'react';
import { message } from 'antd';
import { AxiosError } from 'axios';
import { Page } from '@marrlab-app-shared/types';

import { getAgentProviders } from '../../../api/agentProviders';
import { AgentProvider, GetAgentProvidersParams } from '../../../api/agentProviders/types';

const useAgentProviderData = ({
  skip,
  take,
  sortBy,
  order,
  search,
  campaign,
  functionTools,
  integration,
  persona,
  vectorDbConfig,
  deploy,
  apiVersion,
  providerType,
  orchestratorProviderType,
  agentName,
  agentStatus,
  numberAssignment,
  searchBy,
}: GetAgentProvidersParams) => {
  const [data, setData] = useState<Page<AgentProvider> | null>(null);
  const [loading, setLoading] = useState(false);

  const fetchAgentProviders = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getAgentProviders({
        skip,
        take,
        sortBy,
        order,
        search,
        campaign,
        functionTools,
        integration,
        persona,
        vectorDbConfig,
        deploy,
        apiVersion,
        providerType,
        orchestratorProviderType,
        agentName,
        agentStatus,
        numberAssignment,
        searchBy: searchBy === 'all' ? undefined : searchBy,
      });

      setData(response.data);
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        message.open({ content: error.message, type: 'error', duration: 10 });
      }
    }

    setLoading(false);
  }, [
    agentStatus,
    apiVersion,
    providerType,
    orchestratorProviderType,
    agentName,
    integration,
    persona,
    vectorDbConfig,
    deploy,
    functionTools,
    campaign,
    order,
    search,
    skip,
    sortBy,
    take,
    numberAssignment,
    searchBy,
  ]);

  useEffect(() => {
    fetchAgentProviders();
  }, [fetchAgentProviders]);

  return {
    data,
    loading,
    fetchAgentProviders,
  };
};

export default useAgentProviderData;
