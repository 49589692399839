import { ChangeEvent, useCallback } from 'react';
import { Input, Select, Tooltip } from 'antd';

import { FilterProps } from '../../types';

import './index.scss';

interface SearchProps {
  searchBy?: string;
  updateSearch: (search: string) => void;
  onFiltersUpdate: FilterProps['onFiltersUpdate'];
}

const searchByOptions = [
  {
    value: 'all',
    label: 'All (Default)',
  },
  {
    value: 'agentProviderId',
    label: 'Agent provider ID',
  },
  {
    value: 'orchestratorProviderId',
    label: 'Orchestrator provider ID',
  },
  {
    value: 'numberAssignmentId',
    label: 'Orch. number assignment ID',
  },
  {
    value: 'numberAssignmentOrchestratorNumber',
    label: 'Orchestrator number',
  },
  {
    value: 'numberAssignmentOriginationNumber',
    label: 'Origination number',
  },
];

const Search = ({
  searchBy,
  updateSearch,
  onFiltersUpdate,
}: SearchProps) => {
  const onSearchChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    updateSearch(event.target.value);
  }, [updateSearch]);

  const onSearchByChange = useCallback((value: string | null) => {
    onFiltersUpdate('searchBy', value ?? undefined);
  }, [onFiltersUpdate]);

  return (
    <Input.Search
      addonBefore={(
        <Tooltip
          placement="top"
          title='Select a specific column to search only by it ("full match" creteria), or select "All" to search by all searchable columns ("includes" creteria)'
        >
          <Select
            className="agent-provider-search-by-select"
            options={searchByOptions}
            value={searchBy}
            onChange={onSearchByChange}
          />
        </Tooltip>
      )}
      className="w-prose"
      placeholder="Type to search"
      allowClear
      onChange={onSearchChange}
    />
  );
};

export default Search;
