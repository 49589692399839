import {
  List,
  Modal,
  TableColumnsType,
  Typography,
} from 'antd';

import ColumnSettingRow from './components/columnSettingRow';
import ColumnsSettingsHeader from './components/columnSettingHeader';
import ColumnsSettingsFooter from './components/columnsSettingsFooter';
import useColumnsModalState from './hooks/useColumnsModalState';
import useColumnsModalLogic from './hooks/useColumnsModalLogic';
import { AgentProvider } from '../../../../api/agentProviders/types';
import { ColumnSetting } from './types';

import './index.scss';

interface ColumnSettingsModalProps {
  isOpen: boolean;
  onConfirm: (columns: TableColumnsType<AgentProvider>) => void;
  onCancel: () => void;
  initialColumns: TableColumnsType<AgentProvider>;
}

const ColumnSettingsModal = ({
  isOpen,
  initialColumns,
  onConfirm: onConfirmBase,
  onCancel,
}: ColumnSettingsModalProps) => {
  const {
    allVisible,
    columnsSettings,
    onVisibilityUpdate,
    onAllVisibleUpdate,
  } = useColumnsModalState({ isOpen, initialColumns });

  const { onConfirm, onResetToDefault } = useColumnsModalLogic({
    initialColumns,
    columnsSettings,
    onConfirm: onConfirmBase,
  });

  return (
    <Modal
      title="Columns settings"
      open={isOpen}
      onOk={onConfirm}
      onCancel={onCancel}
      width="800px"
      footer={(
        <ColumnsSettingsFooter
          onCancel={onCancel}
          onConfirm={onConfirm}
          onResetToDefault={onResetToDefault}
        />
      )}
    >
      <Typography.Paragraph className="w-prose">
        Those settings allow to control columns visibility.
        <br />
        Saved configuration will be persisted in your browser and will be applied as default
        even if you reload the page. You always can adjust it as you need or restore to default.
      </Typography.Paragraph>

      <List<ColumnSetting>
        className="columns-settings-list"
        header={(
          <ColumnsSettingsHeader
            allVisible={allVisible}
            onAllVisibleUpdate={onAllVisibleUpdate}
          />
        )}
        dataSource={columnsSettings}
        renderItem={(item) => (
          <ColumnSettingRow
            item={item}
            onVisibilityUpdate={onVisibilityUpdate}
          />
        )}
      />
    </Modal>
  );
};

export default ColumnSettingsModal;
