import { useCallback } from 'react';
import { Form, Switch } from 'antd';

import { FieldProps } from '../../types';

const EnableBackchannelField = ({
  state,
  disabled,
  onUpdate,
}: FieldProps) => {
  const onChange = useCallback((checked: boolean) => {
    onUpdate('enableBackchannel', checked);
  }, [onUpdate]);

  return (
    <Form.Item
      className="field-with-info-tooltip"
      label="Backchannel"
      tooltip="Controls whether the agent would backchannel (agent interjects the speaker with phrases like 'yeah', 'uh-huh' to signify interest and engagement). Backchannel when enabled tends to show up more in longer user utterances. If not set, agent will not backchannel."
    >
      <Switch
        checked={state.enableBackchannel}
        onChange={onChange}
        disabled={disabled}
      />
    </Form.Item>
  );
};

export default EnableBackchannelField;
