import { CreateCampaignParams, Campaign } from '../../../../../api/campaigns/types';

export const trimCreateCampaignParams = (params: CreateCampaignParams): CreateCampaignParams => ({
  name: params.name?.trim(),
  customerId: params.customerId,
  humanReview: params.humanReview ?? false,
});

export const getCreateModalInitialState = (
  initialCampaign?: Campaign | null,
): CreateCampaignParams => {
  if (!initialCampaign) {
    return {
      name: '',
      customerId: null,
      humanReview: false,
    };
  }

  return {
    name: initialCampaign.displayName,
    customerId: initialCampaign.customerId,
    humanReview: initialCampaign.humanReview,
  };
};
