import { Tooltip, Typography } from 'antd';

interface WebsocketCellProps {
  value?: string;
}

const WebsocketCell = ({
  value,
}: WebsocketCellProps) => {
  if (!value) {
    return null;
  }

  return (
    <Tooltip title={value}>
      <Typography.Text
        ellipsis
        className="w-64"
        copyable={{ text: value }}
      >
        {value}
      </Typography.Text>
    </Tooltip>
  );
};

export default WebsocketCell;
