import axios from 'axios';
import { Page } from '@marrlab-app-shared/types';

import {
  CreateQuestionParams,
  Question,
  GetQuestionsParams,
} from './types';
import config from '../../utils/config';

export const getQuestions = async (params: GetQuestionsParams) => (
  axios.get<Page<Question>>(`${config.CALLS_HISTORY_API_BASE_URL}/api/questions/`, { params })
);

export const createQuestion = async (params: CreateQuestionParams) => (
  axios.post<Question>(`${config.CALLS_HISTORY_API_BASE_URL}/api/questions`, params)
);

export const editQuestion = async (id: number, params: CreateQuestionParams) => (
  axios.put<Question>(`${config.CALLS_HISTORY_API_BASE_URL}/api/questions/${id}`, params)
);

export const deleteQuestion = async (id: number) => (
  axios.delete<void>(`${config.CALLS_HISTORY_API_BASE_URL}/api/questions/${id}`)
);
