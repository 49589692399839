import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { AxiosError } from 'axios';
import { message, Typography } from 'antd';
import SuccessNotificationProvider from '@marrlab-app-shared/components/successNotification';

import CreateReferenceDataFieldModal from '../../components/createReferenceDataFieldModal';
import { CreateReferenceDataFieldParams, ReferenceDataField } from '../../../../api/referenceDataFields/types';
import { createReferenceDataField, editReferenceDataField } from '../../../../api/referenceDataFields';

interface CreateReferenceDataFieldContextType {
  open: () => void;
  openOnEdit: (referenceDataField: ReferenceDataField) => void;
}

const initialState: CreateReferenceDataFieldContextType = {
  open: () => {
  },
  openOnEdit: () => {
  },
};

const CreateReferenceDataFieldContext = createContext<CreateReferenceDataFieldContextType>(
  initialState,
);

interface CreateReferenceDataFieldContextProps extends PropsWithChildren {
  fetchReferenceDataFields: () => Promise<void>;
}

const CreateReferenceDataFieldProvider = ({
  children,
  fetchReferenceDataFields,
}: CreateReferenceDataFieldContextProps) => {
  const successNotification = SuccessNotificationProvider.useSuccessNotification();

  const [isOpen, setIsOpen] = useState(false);
  const [
    referenceDataFieldToEdit,
    setReferenceDataFieldToEdit,
  ] = useState<ReferenceDataField | null>(null);

  const close = useCallback(() => {
    setIsOpen(false);
    setReferenceDataFieldToEdit(null);
  }, []);

  const open = useCallback(() => {
    setIsOpen(true);
  }, []);

  const openOnEdit = useCallback((referenceDataField: ReferenceDataField) => {
    setReferenceDataFieldToEdit(referenceDataField);
    setIsOpen(true);
  }, []);

  const onConfirm = useCallback(async (params: CreateReferenceDataFieldParams) => {
    try {
      const response = referenceDataFieldToEdit
        ? await editReferenceDataField(referenceDataFieldToEdit.referenceFieldId, params)
        : await createReferenceDataField(params);

      successNotification.open({
        key: response.data.referenceFieldId.toString(),
        title: referenceDataFieldToEdit ? 'Changes saved' : 'ReferenceDataField created successfully',
        message: (
          <>
            <Typography.Text strong>Reference data field ID: </Typography.Text>
            <Typography.Text>{response.data.referenceFieldId}</Typography.Text>
            <br />
            <Typography.Text strong>reference data field name: </Typography.Text>
            <Typography.Text>{response.data.referenceFieldName}</Typography.Text>
          </>
        ),
      });

      await fetchReferenceDataFields();

      close();
    } catch (error) {
      if (error instanceof AxiosError) {
        message.open({ content: error.response?.data?.message, type: 'error', duration: 10 });
      }
    }
  }, [close, referenceDataFieldToEdit, fetchReferenceDataFields, successNotification]);

  const value: CreateReferenceDataFieldContextType = useMemo(
    () => ({
      open,
      openOnEdit,
    }),
    [open, openOnEdit],
  );

  return (
    <CreateReferenceDataFieldContext.Provider value={value}>
      {children}
      <CreateReferenceDataFieldModal
        isOpen={isOpen}
        initialReferenceDataField={referenceDataFieldToEdit}
        onCancel={close}
        onConfirm={onConfirm}
      />
    </CreateReferenceDataFieldContext.Provider>
  );
};

CreateReferenceDataFieldProvider.useCreateReferenceDataField = () => (
  useContext(CreateReferenceDataFieldContext)
);

export default CreateReferenceDataFieldProvider;
