import { useCallback, useEffect, useState } from 'react';
import { message } from 'antd';
import { AxiosError } from 'axios';
import { Page } from '@marrlab-app-shared/types';

import { ReferenceData, GetReferenceDataParams } from '../../../api/referenceData/types';
import { getReferenceData } from '../../../api/referenceData';
import ManagePersonaProvider from '../../../pages/personaManagement/providers/managePersonaProvider';

const useReferenceDataData = (
  personaId: number | undefined,
  {
    skip,
    take,
    sortBy,
    order,
    search,
  }: GetReferenceDataParams,
) => {
  const { setHasReferenceDataRecords } = ManagePersonaProvider.useManagePersona();

  const [data, setData] = useState<Page<ReferenceData> | null>(null);
  const [loading, setLoading] = useState(false);

  const fetchReferenceData = useCallback(async () => {
    if (!personaId) {
      return;
    }

    setLoading(true);
    try {
      const [response, allReferenceDataResponse] = await Promise.all([
        getReferenceData(
          personaId,
          {
            skip,
            take,
            sortBy,
            order,
            search,
          },
        ),
        getReferenceData(
          personaId,
          {
            skip,
            take,
            sortBy,
            order,
          },
        ),
      ]);

      setHasReferenceDataRecords(!!allReferenceDataResponse.data.data.length);
      setData(response.data);
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        message.open({ content: error.message, type: 'error', duration: 10 });
      }
    }

    setLoading(false);
  }, [
    order,
    personaId,
    search,
    setHasReferenceDataRecords,
    skip,
    sortBy,
    take,
  ]);

  useEffect(() => {
    fetchReferenceData();
  }, [fetchReferenceData]);

  return {
    data,
    loading,
    fetchReferenceData,
  };
};

export default useReferenceDataData;
