import { Modal, Form } from 'antd';
import ModalFooter from '@marrlab-app-shared/components/modalFooter';

import ShortNameField from './components/shortNameField';

import useCreateAsrConfigModalLogic from './hooks/useCreateAsrConfigModalLogic';
import useCreateAsrConfigModalState from './hooks/useCreateAsrConfigModalState';
import useCreateAsrConfigModalValidation from './hooks/useCreateAsrConfigModalValidation';
import { CreateAsrConfigParams, AsrConfig } from '../../../../api/asrConfigs/types';
import AsrConfigField from './components/asrConfigField';

interface CreateAsrConfigModalProps {
  isOpen: boolean;
  initialAsrConfig: AsrConfig | null;
  onConfirm: (params: CreateAsrConfigParams) => Promise<void>;
  onCancel: () => void;
}

const CreateAsrConfigModal = ({
  isOpen,
  initialAsrConfig,
  onConfirm: onConfirmBase,
  onCancel,
}: CreateAsrConfigModalProps) => {
  const {
    errors,
    isValid,
    clearErrorForField,
  } = useCreateAsrConfigModalValidation({ isOpen });

  const {
    isEdit,
    state,
    onPropertyUpdate,
  } = useCreateAsrConfigModalState({
    isOpen,
    initialAsrConfig,
    clearErrorForField,
  });

  const { saving, onConfirm } = useCreateAsrConfigModalLogic({
    state,
    isValid,
    onConfirm: onConfirmBase,
  });

  return (
    <Modal
      title={isEdit ? `Edit ASR config #${initialAsrConfig?.asrConfigId}` : 'Create ASR config'}
      open={isOpen}
      onOk={onConfirm}
      onCancel={onCancel}
      width="600px"
      footer={(
        <ModalFooter
          disabled={saving}
          saveButtonLabel={isEdit ? 'Save' : 'Create'}
          onCancel={onCancel}
          onConfirm={onConfirm}
        />
      )}
    >
      <Form
        layout="vertical"
        className="py-4"
      >
        <ShortNameField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={saving}
          error={errors.shortName}
        />
        <AsrConfigField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={saving}
          error={errors.config}
        />
      </Form>
    </Modal>
  );
};

export default CreateAsrConfigModal;
