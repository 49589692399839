import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { AxiosError } from 'axios';
import { message, Typography } from 'antd';
import SuccessNotificationProvider from '@marrlab-app-shared/components/successNotification';

import CreateVoiceAgentConfigModal from '../../components/createVoiceAgentConfigModal';
import { CreateVoiceAgentConfigParams, VoiceAgentConfig } from '../../../../api/voiceAgentConfigs/types';
import { createVoiceAgentConfig, editVoiceAgentConfig } from '../../../../api/voiceAgentConfigs';

interface CreateVoiceAgentConfigContextType {
  open: () => void;
  openOnEdit: (voiceAgentConfig: VoiceAgentConfig) => void;
}

const initialState: CreateVoiceAgentConfigContextType = {
  open: () => {
  },
  openOnEdit: () => {
  },
};

const CreateVoiceAgentConfigContext = createContext<CreateVoiceAgentConfigContextType>(
  initialState,
);

interface CreateVoiceAgentConfigContextProps extends PropsWithChildren {
  fetchVoiceAgentConfigs: () => Promise<void>;
}

const CreateVoiceAgentConfigProvider = ({
  children,
  fetchVoiceAgentConfigs,
}: CreateVoiceAgentConfigContextProps) => {
  const successNotification = SuccessNotificationProvider.useSuccessNotification();

  const [isOpen, setIsOpen] = useState(false);
  const [
    voiceAgentConfigToEdit,
    setVoiceAgentConfigToEdit,
  ] = useState<VoiceAgentConfig | null>(null);

  const close = useCallback(() => {
    setIsOpen(false);
    setVoiceAgentConfigToEdit(null);
  }, []);

  const open = useCallback(() => {
    setIsOpen(true);
  }, []);

  const openOnEdit = useCallback((voiceAgentConfig: VoiceAgentConfig) => {
    setVoiceAgentConfigToEdit(voiceAgentConfig);
    setIsOpen(true);
  }, []);

  const onConfirm = useCallback(async (params: CreateVoiceAgentConfigParams) => {
    try {
      const response = voiceAgentConfigToEdit
        ? await editVoiceAgentConfig(voiceAgentConfigToEdit.voiceAgentConfigId, params)
        : await createVoiceAgentConfig(params);

      successNotification.open({
        key: response.data.voiceAgentConfigId.toString(),
        title: voiceAgentConfigToEdit ? 'Changes saved' : 'Voice agent config created successfully',
        message: (
          <>
            <Typography.Text strong>ID: </Typography.Text>
            <Typography.Text>{response.data.voiceAgentConfigId}</Typography.Text>
            <br />
            <Typography.Text strong>Short name: </Typography.Text>
            <Typography.Text>{response.data.shortName}</Typography.Text>
          </>
        ),
      });

      await fetchVoiceAgentConfigs();

      close();
    } catch (error) {
      if (error instanceof AxiosError) {
        message.open({ content: error.response?.data?.message, type: 'error', duration: 10 });
      }
    }
  }, [close, voiceAgentConfigToEdit, fetchVoiceAgentConfigs, successNotification]);

  const value: CreateVoiceAgentConfigContextType = useMemo(
    () => ({
      open,
      openOnEdit,
    }),
    [open, openOnEdit],
  );

  return (
    <CreateVoiceAgentConfigContext.Provider value={value}>
      {children}
      <CreateVoiceAgentConfigModal
        isOpen={isOpen}
        initialVoiceAgentConfig={voiceAgentConfigToEdit}
        onCancel={close}
        onConfirm={onConfirm}
      />
    </CreateVoiceAgentConfigContext.Provider>
  );
};

CreateVoiceAgentConfigProvider.useCreateVoiceAgentConfig = () => (
  useContext(CreateVoiceAgentConfigContext)
);

export default CreateVoiceAgentConfigProvider;
