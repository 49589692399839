import { useCallback, useState } from 'react';
import {
  Button,
  Popconfirm,
  Tooltip,
  Typography,
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import DeleteReferenceDataFieldProvider from '../../providers/deleteReferenceDataFieldProvider';
import { ReferenceDataField } from '../../../../api/referenceDataFields/types';

interface DeleteReferenceDataFieldActionProps {
  referenceDataField: ReferenceDataField;
}

const DeleteReferenceDataFieldAction = ({
  referenceDataField,
}: DeleteReferenceDataFieldActionProps) => {
  const {
    loading,
    onDeleteReferenceDataField,
  } = DeleteReferenceDataFieldProvider.useDeleteReferenceDataField();

  const [open, setOpen] = useState(false);

  const showConfirmation = useCallback(() => setOpen(true), []);

  const onConfirm = useCallback(async () => {
    await onDeleteReferenceDataField(referenceDataField.referenceFieldId);

    setOpen(false);
  }, [referenceDataField.referenceFieldId, onDeleteReferenceDataField]);

  const onCancel = useCallback(() => setOpen(false), []);

  return (
    <Popconfirm
      title={`Delete the reference data field #${referenceDataField.referenceFieldId}`}
      description={(
        <Typography.Text>
          <Typography.Text strong>
            All related &quot;reference data&quot; records will be removed
          </Typography.Text>
          <br />
          Are you sure to delete the reference data field?
        </Typography.Text>
      )}
      open={open}
      onConfirm={onConfirm}
      okButtonProps={{ loading }}
      onCancel={onCancel}
    >
      <Tooltip title="Delete reference data field">
        <Button
          type="primary"
          ghost
          icon={<DeleteOutlined />}
          onClick={showConfirmation}
        />
      </Tooltip>
    </Popconfirm>
  );
};

export default DeleteReferenceDataFieldAction;
