import { useCallback } from 'react';
import { Form, Select } from 'antd';

import { FieldProps } from '../../types';

const MultiplePromptsField = ({
  state,
  disabled,
  onUpdate,
}: FieldProps) => {
  const onChange = useCallback((value?: string[]) => {
    onUpdate('multiplePrompts', value);
  }, [onUpdate]);

  return (
    <Form.Item label="Multiple prompts">
      <Select
        mode="tags"
        value={state.multiplePrompts}
        onChange={onChange}
        disabled={disabled}
        open={false}
      />
    </Form.Item>
  );
};

export default MultiplePromptsField;
