import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { AxiosError } from 'axios';
import { message, Typography } from 'antd';
import SuccessNotificationProvider from '@marrlab-app-shared/components/successNotification';

import CreateCampaignModal from '../../components/createCampaignModal';
import { CreateCampaignParams, Campaign } from '../../../../api/campaigns/types';
import { createCampaign, editCampaign } from '../../../../api/campaigns';

interface CreateCampaignContextType {
  open: () => void;
  openOnEdit: (campaign: Campaign) => void;
}

const initialState: CreateCampaignContextType = {
  open: () => {
  },
  openOnEdit: () => {
  },
};

const CreateCampaignContext = createContext<CreateCampaignContextType>(initialState);

interface CreateCampaignContextProps extends PropsWithChildren {
  fetchCampaigns: () => Promise<void>;
}

const CreateCampaignProvider = ({ children, fetchCampaigns }: CreateCampaignContextProps) => {
  const successNotification = SuccessNotificationProvider.useSuccessNotification();

  const [isOpen, setIsOpen] = useState(false);
  const [campaignToEdit, setCampaignToEdit] = useState<Campaign | null>(null);

  const close = useCallback(() => {
    setIsOpen(false);
    setCampaignToEdit(null);
  }, []);

  const open = useCallback(() => {
    setIsOpen(true);
  }, []);

  const openOnEdit = useCallback((campaign: Campaign) => {
    setCampaignToEdit(campaign);
    setIsOpen(true);
  }, []);

  const onConfirm = useCallback(async (params: CreateCampaignParams) => {
    try {
      const response = campaignToEdit
        ? await editCampaign(campaignToEdit.campaignId, params)
        : await createCampaign(params);

      successNotification.open({
        key: response.data.campaignId.toString(),
        title: campaignToEdit ? 'Changes saved' : 'Campaign created successfully',
        message: (
          <>
            <Typography.Text strong>Campaign ID: </Typography.Text>
            <Typography.Text>{response.data.campaignId}</Typography.Text>
            <br />
            <Typography.Text strong>Campaign name: </Typography.Text>
            <Typography.Text>{response.data.displayName}</Typography.Text>
          </>
        ),
      });

      await fetchCampaigns();

      close();
    } catch (error) {
      if (error instanceof AxiosError) {
        message.open({ content: error.response?.data?.message, type: 'error', duration: 10 });
      }
    }
  }, [close, campaignToEdit, fetchCampaigns, successNotification]);

  const value: CreateCampaignContextType = useMemo(
    () => ({
      open,
      openOnEdit,
    }),
    [open, openOnEdit],
  );

  return (
    <CreateCampaignContext.Provider value={value}>
      {children}
      <CreateCampaignModal
        isOpen={isOpen}
        initialCampaign={campaignToEdit}
        onCancel={close}
        onConfirm={onConfirm}
      />
    </CreateCampaignContext.Provider>
  );
};

CreateCampaignProvider.useCreateCampaign = () => useContext(CreateCampaignContext);

export default CreateCampaignProvider;
