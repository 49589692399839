import { Persona } from '../../../api/personas/types';
import { Placeholder } from '../../../api/common/types';

interface FillTemplateWithPlaceholdersArgs {
  placeholderValues: Persona['placeholderValues'];
  placeholders: Placeholder[];
  template?: string;
}

export const fillTemplateWithPlaceholders = ({
  placeholders,
  placeholderValues,
  template,
}: FillTemplateWithPlaceholdersArgs) => {
  const placeholdersMap = placeholders.reduce((acc, item) => {
    acc[item.placeholderId.toString()] = item;

    return acc;
  }, {} as Record<string, Placeholder>);

  let resolvedTemplate = template ?? '';

  Object.entries(placeholderValues).forEach(([key, value]) => {
    const placeholderName = placeholdersMap[key]?.placeholderName;

    if (placeholderName) {
      resolvedTemplate = resolvedTemplate.replaceAll(`<${placeholderName}>`, value);
    }
  });

  return resolvedTemplate;
};

export default fillTemplateWithPlaceholders;
