import { SortOrder } from '../../constants';
import { Campaign } from '../campaigns/types';

export enum QuestionFailValue {
  yes = 'yes',
  no = 'no',
}

export interface Question {
  questionId: number;
  question: string;
  failValue: QuestionFailValue | null;
  isInfoOnly: boolean;
  campaignId: string;
  campaign: Campaign;
}

export interface GetQuestionsParams {
  take?: number;
  skip?: number;
  sortBy?: keyof Question;
  order?: SortOrder;
  search?: string;
  campaign?: string;
}

export interface CreateQuestionParams {
  question: string;
  failValue: QuestionFailValue | null;
  isInfoOnly: boolean;
  campaignId: string;
}
