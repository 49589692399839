import { useCallback } from 'react';
import { Form, Select } from 'antd';

import { FieldProps } from '../../types';

const options = [
  {
    value: '11labs-Nina',
    label: '11labs-Nina',
  },
  {
    value: '11labs-Marissa',
    label: '11labs-Marissa',
  },
  {
    value: '11labs-Brian',
    label: '11labs-Brian',
  },
  {
    value: '11labs-Joe',
    label: '11labs-Joe',
  },
];

const VoiceIdField = ({
  state,
  disabled,
  error,
  onUpdate,
}: FieldProps) => {
  const onChange = useCallback((value?: string) => {
    onUpdate('voiceId', value);
  }, [onUpdate]);

  return (
    <Form.Item
      label="Voice ID"
      required
      validateStatus={error ? 'error' : undefined}
      help={error}
    >
      <Select
        value={state.voiceId}
        onChange={onChange}
        options={options}
        disabled={disabled}
      />
    </Form.Item>
  );
};

export default VoiceIdField;
