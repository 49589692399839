import { Button, Flex } from 'antd';

interface ModalFooterProps {
  disabled: boolean;
  showSaveButton?: boolean;
  saveButtonLabel: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const ModalFooter = ({
  disabled,
  showSaveButton = true,
  saveButtonLabel,
  onCancel,
  onConfirm,
}: ModalFooterProps) => (
  <Flex gap={8} justify="flex-end">
    <Button onClick={onCancel} disabled={disabled}>
      Cancel
    </Button>
    {showSaveButton && (
      <Button type="primary" onClick={onConfirm} disabled={disabled}>
        {saveButtonLabel}
      </Button>
    )}
  </Flex>
);

export default ModalFooter;
