import {
  useCallback,
  useEffect,
  useState,
} from 'react';

import { getCreateModalInitialState } from '../mappers';
import { VoiceAgentConfig } from '../../../../../api/voiceAgentConfigs/types';
import { CreateVoiceAgentConfigModalState } from '../types';

interface UseCreateVoiceAgentConfigModalStateArgs {
  clearErrorForField: (propName: string) => void;
  initialVoiceAgentConfig: VoiceAgentConfig | null;
  isOpen: boolean;
}

const useCreateVoiceAgentConfigModalState = ({
  isOpen,
  initialVoiceAgentConfig,
  clearErrorForField,
}: UseCreateVoiceAgentConfigModalStateArgs) => {
  const [state, setState] = useState<CreateVoiceAgentConfigModalState>(
    getCreateModalInitialState(),
  );

  const onPropertyUpdate = useCallback((
    propName: keyof CreateVoiceAgentConfigModalState,
    value: CreateVoiceAgentConfigModalState[keyof CreateVoiceAgentConfigModalState],
  ) => {
    setState((oldState) => ({ ...oldState, [propName]: value }));
    clearErrorForField(propName);
  }, [clearErrorForField]);

  useEffect(() => {
    setState(getCreateModalInitialState(isOpen ? initialVoiceAgentConfig : null));
  }, [initialVoiceAgentConfig, isOpen]);

  const isEdit = !!initialVoiceAgentConfig;

  return {
    isEdit,
    state,
    onPropertyUpdate,
  };
};

export default useCreateVoiceAgentConfigModalState;
