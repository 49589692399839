import { Form, Input } from 'antd';

import { FieldProps } from '../../types';

const AgentProviderField = ({
  state,
  error,
}: FieldProps) => (
  <Form.Item
    label="Agent provider ID"
    required
    validateStatus={error ? 'error' : undefined}
    help={error}
  >
    <Input
      value={state.agentProviderId}
      disabled
    />
  </Form.Item>
);

export default AgentProviderField;
