import { useCallback, useState } from 'react';
import { AxiosError } from 'axios';
import { message } from 'antd';

import { AssignNumberParams } from '../../../../../api/agentProviders/types';
import { AssignNumberModalState } from '../types';
import { trimAssignNumberParams } from '../mappers';

interface UseAssignNumberModalLogicArgs {
  state: AssignNumberModalState;
  isValid: (state: AssignNumberModalState) => boolean;
  onConfirm: (params: AssignNumberParams) => Promise<void>;
}

const useAssignNumberModalLogic = ({
  state,
  isValid,
  onConfirm: onConfirmBase,
}: UseAssignNumberModalLogicArgs) => {
  const [saving, setSaving] = useState(false);

  const onConfirm = useCallback(async () => {
    if (!isValid(state)) {
      message.open({ content: 'Some fields are not valid', type: 'error', duration: 10 });
      return;
    }

    setSaving(true);

    try {
      await onConfirmBase(trimAssignNumberParams(state));
    } catch (error) {
      console.error(error);

      if (error instanceof AxiosError) {
        message.open({ content: error.response?.data?.message, type: 'error', duration: 10 });
      }
    }

    setSaving(false);
  }, [isValid, state, onConfirmBase]);

  return {
    saving,
    onConfirm,
  };
};

export default useAssignNumberModalLogic;
