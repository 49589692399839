import { useCallback } from 'react';
import { Form, Select } from 'antd';

import { FieldProps } from '../../types';

const BoostedKeywordsField = ({
  state,
  disabled,
  onUpdate,
}: FieldProps) => {
  const onChange = useCallback((value?: string[]) => {
    onUpdate('boostedKeywords', value);
  }, [onUpdate]);

  return (
    <Form.Item
      className="field-with-info-tooltip"
      label="Boosted keywords"
      tooltip="Provide a customized list of keywords to bias the transcriber model, so that these words are more likely to get transcribed. Commonly used for names, brands, street, etc."
    >
      <Select
        mode="tags"
        value={state.boostedKeywords}
        onChange={onChange}
        disabled={disabled}
        open={false}
      />
    </Form.Item>
  );
};

export default BoostedKeywordsField;
