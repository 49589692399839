import { useCallback } from 'react';
import { Form, Select } from 'antd';

import { FieldProps } from '../../types';
import { ReferenceFieldTypes } from '../../../../../../api/referenceDataFields/types';
import { referenceFieldTypeNames } from '../../../../constants';

const options = [
  {
    label: referenceFieldTypeNames[ReferenceFieldTypes.int],
    value: ReferenceFieldTypes.int,
  },
  {
    label: referenceFieldTypeNames[ReferenceFieldTypes.float],
    value: ReferenceFieldTypes.float,
  },
  {
    label: referenceFieldTypeNames[ReferenceFieldTypes.str],
    value: ReferenceFieldTypes.str,
  },
  {
    label: referenceFieldTypeNames[ReferenceFieldTypes.list],
    value: ReferenceFieldTypes.list,
  },
];

const TypeField = ({
  state,
  error,
  disabled,
  onUpdate,
}: FieldProps) => {
  const onChange = useCallback((value: ReferenceFieldTypes) => {
    onUpdate('type', value);
  }, [onUpdate]);

  return (
    <Form.Item
      label="Type"
      required
      validateStatus={error ? 'error' : undefined}
      help={error}
    >
      <Select
        value={state.type}
        options={options}
        onChange={onChange}
        disabled={disabled}
      />
    </Form.Item>
  );
};

export default TypeField;
