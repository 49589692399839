import { useCallback, useEffect, useState } from 'react';

import { CreateHumanTemplateParams } from '../../../../../api/humanTemplates/types';
import { isEmptyString, validateHumanTemplate } from '../../../../../utils/validators';

type Errors = Partial<Record<keyof CreateHumanTemplateParams, string>>;

interface UseCreateHumanTemplateModalValidation {
  isOpen: boolean;
}

const initialState: Errors = {};

const useCreateHumanTemplateModalValidation = ({
  isOpen,
}: UseCreateHumanTemplateModalValidation) => {
  const [errors, setErrors] = useState<Errors>(initialState);

  const isValid = useCallback((state: CreateHumanTemplateParams) => {
    const newState = {
      templateName: isEmptyString(state.templateName),
      campaignId: isEmptyString(state.campaignId),
      template: validateHumanTemplate(state.template),
    };

    setErrors(newState);

    return Object.values(newState).every((field) => !field);
  }, []);

  const clearErrorForField = useCallback((propName: string) => {
    setErrors((state) => ({
      ...state,
      [propName]: '',
    }));
  }, []);

  useEffect(() => {
    if (!isOpen) {
      setErrors(initialState);
    }
  }, [isOpen]);

  return {
    errors,
    isValid,
    clearErrorForField,
  };
};

export default useCreateHumanTemplateModalValidation;
