import { ChangeEvent, useCallback } from 'react';
import { Form, Input } from 'antd';

import { FieldProps } from '../../types';

const ChoicesField = ({
  state,
  disabled,
  onUpdate,
}: FieldProps) => {
  const onChange = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
    onUpdate('choices', event.target.value);
  }, [onUpdate]);

  return (
    <Form.Item label="Choices">
      <Input.TextArea
        className="mb-2"
        value={state.choices}
        placeholder="[weekly, bi-weekly, monthly, bi-monthly, annually]"
        onChange={onChange}
        disabled={disabled}
        autoSize={{ minRows: 2, maxRows: 8 }}
        maxLength={255}
        showCount
      />
    </Form.Item>
  );
};

export default ChoicesField;
