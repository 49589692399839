import { useCallback, useState } from 'react';
import { Button, Popconfirm, Tooltip } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import { Campaign } from '../../../../api/campaigns/types';
import DeleteCampaignProvider from '../../providers/deleteCampaignsProvider';

interface DeleteCampaignActionProps {
  campaign: Campaign;
}

const DeleteCampaignAction = ({ campaign }: DeleteCampaignActionProps) => {
  const { loading, onDeleteCampaign } = DeleteCampaignProvider.useDeleteCampaign();

  const [open, setOpen] = useState(false);

  const isInactive = !campaign.active;

  let tooltip = 'Archive campaign';

  if (isInactive) {
    tooltip = 'The campaign marked as archived';
  }

  const showConfirmation = useCallback(() => setOpen(true), []);

  const onConfirm = useCallback(async () => {
    await onDeleteCampaign(campaign.campaignId);

    setOpen(false);
  }, [campaign.campaignId, onDeleteCampaign]);

  const onCancel = useCallback(() => setOpen(false), []);

  return (
    <Popconfirm
      title={`Archive the campaign #${campaign.campaignId}`}
      description="Are you sure to archive the campaign?"
      open={open}
      onConfirm={onConfirm}
      okButtonProps={{ loading }}
      onCancel={onCancel}
      disabled={isInactive}
    >
      <Tooltip title={tooltip}>
        <Button
          type="primary"
          ghost
          icon={<DeleteOutlined />}
          onClick={showConfirmation}
          disabled={isInactive}
        />
      </Tooltip>
    </Popconfirm>
  );
};

export default DeleteCampaignAction;
