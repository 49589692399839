import { useCallback } from 'react';
import { Button, Tooltip } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import CreateCampaignProvider from '../../providers/createCampaignsProvider';
import { Campaign } from '../../../../api/campaigns/types';

interface EditCampaignActionProps {
  campaign: Campaign;
}

const EditCampaignAction = ({ campaign }: EditCampaignActionProps) => {
  const createCampaign = CreateCampaignProvider.useCreateCampaign();

  const isInactive = !campaign.active;

  let tooltip = 'Edit campaign';

  if (isInactive) {
    tooltip = 'The campaign marked as archived';
  }

  const open = useCallback(() => {
    createCampaign.openOnEdit(campaign);
  }, [createCampaign, campaign]);

  return (
    <Tooltip title={tooltip}>
      <Button
        type="primary"
        ghost
        icon={<EditOutlined />}
        size="middle"
        onClick={open}
        disabled={isInactive}
      />
    </Tooltip>
  );
};

export default EditCampaignAction;
