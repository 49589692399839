import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { AxiosError } from 'axios';
import { message, Typography } from 'antd';
import SuccessNotificationProvider from '@marrlab-app-shared/components/successNotification';

import CreateAsrConfigModal from '../../components/createAsrConfigModal';
import { CreateAsrConfigParams, AsrConfig } from '../../../../api/asrConfigs/types';
import { createAsrConfig, editAsrConfig } from '../../../../api/asrConfigs';

interface CreateAsrConfigContextType {
  open: () => void;
  openOnEdit: (asrConfig: AsrConfig) => void;
}

const initialState: CreateAsrConfigContextType = {
  open: () => {
  },
  openOnEdit: () => {
  },
};

const CreateAsrConfigContext = createContext<CreateAsrConfigContextType>(initialState);

interface CreateAsrConfigContextProps extends PropsWithChildren {
  fetchAsrConfigs: () => Promise<void>;
}

const CreateAsrConfigProvider = ({ children, fetchAsrConfigs }: CreateAsrConfigContextProps) => {
  const successNotification = SuccessNotificationProvider.useSuccessNotification();

  const [isOpen, setIsOpen] = useState(false);
  const [asrConfigToEdit, setAsrConfigToEdit] = useState<AsrConfig | null>(null);

  const close = useCallback(() => {
    setIsOpen(false);
    setAsrConfigToEdit(null);
  }, []);

  const open = useCallback(() => {
    setIsOpen(true);
  }, []);

  const openOnEdit = useCallback((asrConfig: AsrConfig) => {
    setAsrConfigToEdit(asrConfig);
    setIsOpen(true);
  }, []);

  const onConfirm = useCallback(async (params: CreateAsrConfigParams) => {
    try {
      const response = asrConfigToEdit
        ? await editAsrConfig(asrConfigToEdit.asrConfigId, params)
        : await createAsrConfig(params);

      successNotification.open({
        key: response.data.asrConfigId.toString(),
        title: asrConfigToEdit ? 'Changes saved' : 'ASR config created successfully',
        message: (
          <>
            <Typography.Text strong>ID: </Typography.Text>
            <Typography.Text>{response.data.asrConfigId}</Typography.Text>
            <br />
            <Typography.Text strong>Short name: </Typography.Text>
            <Typography.Text>{response.data.shortName}</Typography.Text>
          </>
        ),
      });

      await fetchAsrConfigs();

      close();
    } catch (error) {
      if (error instanceof AxiosError) {
        message.open({ content: error.response?.data?.message, type: 'error', duration: 10 });
      }
    }
  }, [close, asrConfigToEdit, fetchAsrConfigs, successNotification]);

  const value: CreateAsrConfigContextType = useMemo(
    () => ({
      open,
      openOnEdit,
    }),
    [open, openOnEdit],
  );

  return (
    <CreateAsrConfigContext.Provider value={value}>
      {children}
      <CreateAsrConfigModal
        isOpen={isOpen}
        initialAsrConfig={asrConfigToEdit}
        onCancel={close}
        onConfirm={onConfirm}
      />
    </CreateAsrConfigContext.Provider>
  );
};

CreateAsrConfigProvider.useCreateAsrConfig = () => useContext(CreateAsrConfigContext);

export default CreateAsrConfigProvider;
