import { useCallback, useState } from 'react';
import { AgentProvidersFilters } from '../types';

interface UseFiltersProps {
  resetPage: () => void;
}

const initialFiltersState: AgentProvidersFilters = {
  campaign: undefined,
  functionTools: undefined,
  integration: undefined,
  persona: undefined,
  vectorDbConfig: undefined,
  deploy: undefined,
  apiVersion: undefined,
  providerType: undefined,
  orchestratorProviderType: undefined,
  agentName: undefined,
  agentStatus: 'active',
  numberAssignment: undefined,
  searchBy: 'all',
};

const useFilters = ({ resetPage }: UseFiltersProps) => {
  const [filters, setFilters] = useState<AgentProvidersFilters>(initialFiltersState);

  const onFiltersUpdate = useCallback((
    filterName: keyof AgentProvidersFilters,
    value: AgentProvidersFilters[keyof AgentProvidersFilters],
  ) => {
    resetPage();

    setFilters((state) => ({
      ...state,
      [filterName]: value,
    }));
  }, [resetPage]);

  const clearFilters = useCallback(() => {
    resetPage();
    setFilters(initialFiltersState);
  }, [resetPage]);

  return {
    filters,
    onFiltersUpdate,
    clearFilters,
  };
};

export default useFilters;
