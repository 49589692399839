import { useCallback } from 'react';
import { Button } from 'antd';

import CreateVoiceAgentConfigProvider from '../../providers/createVoiceAgentConfigProvider';

const CreateVoiceAgentConfigButton = () => {
  const createVoiceAgentConfig = CreateVoiceAgentConfigProvider.useCreateVoiceAgentConfig();

  const open = useCallback(() => {
    createVoiceAgentConfig.open();
  }, [createVoiceAgentConfig]);

  return (
    <Button
      type="primary"
      onClick={open}
    >
      Create voice agent config
    </Button>
  );
};

export default CreateVoiceAgentConfigButton;
