import { AgentProvider, AssignNumberParams } from '../../../../../api/agentProviders/types';
import { AssignNumberModalState } from '../types';
import config from '../../../../../utils/config';

export const trimAssignNumberParams = (params: AssignNumberModalState): AssignNumberParams => ({
  agentProviderId: typeof params.agentProviderId === 'string'
    ? parseInt(params.agentProviderId, 10)
    : params.agentProviderId,
  areaCode: params.createTwilioNumber ? params.areaCode : undefined,
  orchestratorProviderId: typeof params.orchestratorProviderId === 'string'
    ? parseInt(params.orchestratorProviderId, 10)
    : params.orchestratorProviderId,
  originationNumber: `+${params.originationNumber?.trim()}`,
  promptParameters: params.promptParameters?.map((item) => ({
    key: item.key.trim(),
    name: item.name.trim(),
    type: item.type,
    placeholder: item.placeholder?.trim(),
    options: item.options?.map((option) => option.trim()),
  })),
  createTwilioNumber: params.createTwilioNumber,
});

const getDefaultOriginationNumber = () => {
  if (config.MODE === 'development') {
    return '15082334329';
  }

  return '19787344165';
};

export const getAssignNumberModalInitialState = (
  initialAgent?: AgentProvider | null,
): AssignNumberModalState => ({
  agentProviderId: initialAgent?.agentProviderId ? parseInt(initialAgent?.agentProviderId, 10) : 0,
  areaCode: undefined,
  orchestratorProviderId: initialAgent?.orchestratorProvider?.orchestratorProviderId ?? 0,
  originationNumber: getDefaultOriginationNumber(),
  promptParameters: undefined,
  createTwilioNumber: initialAgent?.orchestratorProvider.direction === 0,
});
