import { Flex } from 'antd';

import EditReferenceDataAction from '../editReferenceDataAction';
import DeleteReferenceDataAction from '../deleteReferenceDataAction';
import { ReferenceData } from '../../../../api/referenceData/types';

interface ActionsCellProps {
  referenceData: ReferenceData;
}

const ActionsCell = ({ referenceData }: ActionsCellProps) => (
  <Flex gap={8}>
    <EditReferenceDataAction referenceData={referenceData} />
    <DeleteReferenceDataAction referenceData={referenceData} />
  </Flex>
);

export default ActionsCell;
