import { ChangeEvent, useCallback } from 'react';
import { Form, Input } from 'antd';

import { FieldProps } from '../../types';

const DeploymentPathField = ({
  state,
  disabled,
  error,
  onUpdate,
}: FieldProps) => {
  const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    onUpdate('deploymentPath', event.target.value);
  }, [onUpdate]);

  return (
    <Form.Item
      className="field-with-info-tooltip"
      label="Deployment path"
      tooltip='Proposed format is following v%version%-%customer_name%, for example: "v0.1-Nash"'
      validateStatus={error ? 'error' : undefined}
      help={error}
    >
      <Input
        placeholder="v0.1-test"
        value={state.deploymentPath}
        onChange={onChange}
        disabled={disabled}
      />
    </Form.Item>
  );
};

export default DeploymentPathField;
