import { useState } from 'react';
import { TableColumnsType, TableProps } from 'antd';

import { columnsInitialState } from '../constants/columnsInitialState';
import { ReferenceDataField } from '../../../api/referenceDataFields/types';

const sticky: TableProps<ReferenceDataField>['sticky'] = { offsetHeader: 0 };

const scroll: TableProps<ReferenceDataField>['scroll'] = { x: 'max-content' };

const useTableConfiguration = () => {
  const [columns, setColumns] = useState<TableColumnsType<ReferenceDataField>>(columnsInitialState);

  return {
    columns,
    sticky,
    scroll,
    setColumns,
  };
};

export default useTableConfiguration;
