import type { FilterValue, SorterResult, TableCurrentDataSource } from 'antd/es/table/interface';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useState,
} from 'react';
import { TableColumnsType, TablePaginationConfig, TableProps } from 'antd';

import { Campaign } from '../../../api/campaigns/types';
import { idColumnKey } from '../constants/columnsInitialState';
import { SortOrder } from '../../../constants';

const defaultSortBy: keyof Campaign = idColumnKey;
const defaultSortDirection = SortOrder.descend;

interface UseSortingArgs {
  setColumns: Dispatch<SetStateAction<TableColumnsType<Campaign>>>;
}

const useSorting = ({ setColumns }: UseSortingArgs) => {
  const [sortBy, setSortBy] = useState<keyof Campaign>(defaultSortBy);
  const [sortDirection, setSortDirection] = useState<SortOrder>(defaultSortDirection);

  const onSortChange: TableProps<Campaign>['onChange'] = useCallback((
    _: TablePaginationConfig,
    _1: Record<string, FilterValue | null>,
    sorter: SorterResult<Campaign> | SorterResult<Campaign>[],
    extra: TableCurrentDataSource<Campaign>,
  ) => {
    if (extra.action !== 'sort' || !sorter || Array.isArray(sorter)) {
      return;
    }

    const columnKey = sorter.columnKey as keyof Campaign ?? defaultSortBy;
    const sortOrder = sorter.order ?? 'ascend';

    setSortBy(columnKey);
    setSortDirection(SortOrder[sortOrder]);

    setColumns((columns) => (
      columns.map((column) => ({
        ...column,
        sortOrder: column.key === columnKey ? sortOrder : undefined,
      }))
    ));
  }, [setColumns]);

  return {
    sortBy,
    sortDirection,
    onSortChange,
  };
};

export default useSorting;
