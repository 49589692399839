import { useCallback } from 'react';
import {
  Flex,
  Radio,
  Typography,
} from 'antd';

import { RadioChangeEvent } from 'antd/es/radio/interface';
import { FilterProps } from '../../types';

import './index.scss';

const options = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: 'active',
    label: 'Active',
  },
  {
    value: 'archived',
    label: 'Archived',
  },
];

const StatusFilter = ({ filters, onFiltersUpdate }: FilterProps) => {
  const onChange = useCallback((event?: RadioChangeEvent) => {
    onFiltersUpdate('agentStatus', event?.target.value);
  }, [onFiltersUpdate]);

  return (
    <Flex vertical gap={8} className="min-w-48">
      <Typography.Text>Status</Typography.Text>
      <Radio.Group
        className="agent-status-filter"
        value={filters.agentStatus}
        onChange={onChange}
      >
        {options.map((option) => (
          <Radio.Button
            key={option.value}
            value={option.value}
          >
            {option.label}
          </Radio.Button>
        ))}
      </Radio.Group>
    </Flex>
  );
};

export default StatusFilter;
