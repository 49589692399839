import { useCallback } from 'react';
import { Button } from 'antd';
import { FileSearchOutlined } from '@ant-design/icons';

import { AgentProvider } from '../../../../api/agentProviders/types';
import CreateAgentProvider from '../../../agentProviders/providers/createAgentProvider';

interface EditAgentActionProps {
  agent: AgentProvider;
}

const PreviewAgentButton = ({ agent }: EditAgentActionProps) => {
  const createAgent = CreateAgentProvider.useCreateAgent();

  const open = useCallback(() => {
    createAgent.openOnEdit(agent, true);
  }, [agent, createAgent]);

  return (
    <Button
      type="primary"
      icon={<FileSearchOutlined />}
      size="middle"
      onClick={open}
    >
      Preview agent
    </Button>
  );
};

export default PreviewAgentButton;
