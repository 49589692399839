import { useCallback } from 'react';
import { Form, Select } from 'antd';

import { FieldProps } from '../../types';

const BackchannelWordsField = ({
  state,
  disabled,
  onUpdate,
}: FieldProps) => {
  const onChange = useCallback((value?: string[]) => {
    onUpdate('backchannelWords', value);
  }, [onUpdate]);

  return (
    <Form.Item
      className="field-with-info-tooltip"
      label="Backchannel words"
      tooltip="Only applicable when enable_backchannel is true. A list of words that the agent would use as backchannel. If not set, default backchannel words will apply. Check out backchannel default words for more details. Note that certain voices do not work too well with certain words, so it's recommended to expeirment before adding any words."
    >
      <Select
        mode="tags"
        value={state.backchannelWords}
        onChange={onChange}
        disabled={disabled}
        open={false}
      />
    </Form.Item>
  );
};

export default BackchannelWordsField;
