import { CreatePersonaParams, Persona } from '../../../api/personas/types';
import { CreatePersonaModalState } from '../types';

export const trimCreatePersonaParams = (params: CreatePersonaModalState): CreatePersonaParams => ({
  personaName: params.personaName?.trim(),
  personaDescription: params.personaDescription?.trim(),
  templateId: params.templateId,
  placeholderValues: params.placeholderValuesRaw ? JSON.stringify(params.placeholderValuesRaw) : '{}',
  questionIds: params.questionIds,
});

interface GetCreateModalInitialStateArgs {
  initialPersona?: Persona | null;
}

export const getCreateModalInitialState = ({
  initialPersona,
}: GetCreateModalInitialStateArgs): CreatePersonaModalState => {
  if (!initialPersona) {
    return {
      personaName: '',
      personaDescription: '',
      templateId: null,
      campaignId: '',
      placeholderValuesRaw: {},
      questionIds: [],
      templateText: '',
    };
  }

  return {
    personaName: initialPersona.personaName,
    personaDescription: initialPersona.personaDescription,
    campaignId: initialPersona.template?.campaignId ?? '',
    templateId: initialPersona.templateId,
    templateText: initialPersona.template?.template ?? '',
    placeholderValuesRaw: initialPersona.placeholderValues ?? {},
    questionIds: initialPersona.questionIds.question_ids,
  };
};
