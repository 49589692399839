import { TableColumnsType } from 'antd';

import ActionsCell from '../components/actionsCell';
import { Persona } from '../../../api/personas/types';
import LongTextCell from '../../../components/longTextCell';

export const idColumnKey = 'personaId';

export const columnsInitialState: TableColumnsType<Persona> = [
  {
    title: 'ID',
    dataIndex: idColumnKey,
    key: idColumnKey,
    fixed: 'left',
    sorter: true,
    sortOrder: 'descend',
    width: 80,
  },
  {
    title: 'Persona name',
    dataIndex: 'personaName',
    key: 'personaName',
    sorter: true,
    width: 334,
  },
  {
    title: 'Persona description',
    dataIndex: 'personaDescription',
    key: 'personaDescription',
    sorter: true,
    width: 200,
    render: ((value: Persona['personaDescription']) => (
      <LongTextCell
        value={value}
        title="Persona description"
      />
    )),
  },
  {
    title: 'Template name',
    dataIndex: 'templateName',
    key: 'templateName',
    sorter: true,
    width: 230,
    render: (_, record) => record.template?.templateName,
  },
  {
    title: 'Agent ID',
    dataIndex: 'agentId',
    key: 'agentId',
    width: 140,
    render: (_, record) => {
      const { agentProvider } = record;

      if (!agentProvider) {
        return '';
      }

      if (!agentProvider.active) {
        return `${agentProvider.agentProviderId} (Archived)`;
      }

      return agentProvider.agentProviderId;
    },
  },
  {
    title: 'Campaign name',
    dataIndex: 'campaignName',
    key: 'campaignName',
    width: 230,
    render: (_, record) => record.campaign?.displayName,
  },
  {
    title: 'Placeholder values',
    dataIndex: 'placeholderValues',
    key: 'placeholderValues',
    sorter: true,
    width: 200,
    render: ((value: Persona['placeholderValues']) => (
      <LongTextCell
        value={value ? JSON.stringify(value, null, 2) : undefined}
        title="Placeholder values"
      />
    )),
  },
  {
    title: 'Question IDs',
    dataIndex: 'questionIds',
    key: 'questionIds',
    sorter: true,
    width: 200,
    render: ((value: Persona['questionIds']) => (
      <LongTextCell
        value={value ? JSON.stringify(value, null, 2) : undefined}
        title="Question IDs"
      />
    )),
  },
  {
    title: 'Actions',
    width: 104,
    fixed: 'right',
    render: ((_, record) => <ActionsCell persona={record} />),
  },
];
