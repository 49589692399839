import { useCallback } from 'react';
import { Button, Tooltip } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import CreateTtsConfigProvider from '../../providers/createTtsConfigProvider';
import { TtsConfig } from '../../../../api/ttsConfigs/types';

interface EditTtsConfigActionProps {
  ttsConfig: TtsConfig;
}

const EditTtsConfigAction = ({ ttsConfig }: EditTtsConfigActionProps) => {
  const createTtsConfig = CreateTtsConfigProvider.useCreateTtsConfig();

  const open = useCallback(() => {
    createTtsConfig.openOnEdit(ttsConfig);
  }, [createTtsConfig, ttsConfig]);

  return (
    <Tooltip title="Edit TTS config">
      <Button
        type="primary"
        ghost
        icon={<EditOutlined />}
        size="middle"
        onClick={open}
      />
    </Tooltip>
  );
};

export default EditTtsConfigAction;
