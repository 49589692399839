import { ChangeEvent, useCallback } from 'react';
import { Input } from 'antd';

interface SearchProps {
  updateSearch: (search: string) => void;
}

const Search = ({ updateSearch }: SearchProps) => {
  const onSearchChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    updateSearch(event.target.value);
  }, [updateSearch]);

  return (
    <Input.Search
      className="w-1/3"
      placeholder="Type to search"
      allowClear
      onChange={onSearchChange}
    />
  );
};

export default Search;
