import { useCallback, useMemo } from 'react';
import { Form } from 'antd';
import { Content, MenuItem } from 'vanilla-jsoneditor';

import JsonEditor from '../../../../../../components/jsonEditor';
import { FieldProps } from '../../types';

const VoiceAgentConfigField = ({
  state,
  disabled,
  error,
  onUpdate,
}: FieldProps) => {
  const onChange = useCallback((content: Content) => {
    if ('json' in content) {
      onUpdate('config', content.json as Record<string, unknown>);
    }
  }, [onUpdate]);

  const onRenderMenu = useCallback((items: MenuItem[]) => (
    items.slice(4, items.length)
  ), []);

  const content = useMemo(() => ({ json: state.config }), [state.config]);

  return (
    <Form.Item
      required
      className="field-with-info-tooltip"
      label="Voice agent config"
      tooltip="Accepts only valid json object"
      validateStatus={error ? 'error' : undefined}
      help={error}
    >
      <JsonEditor
        content={content}
        onChange={onChange}
        readOnly={disabled}
        onRenderMenu={onRenderMenu}
      />
    </Form.Item>
  );
};

export default VoiceAgentConfigField;
