import { useCallback } from 'react';
import {
  Button,
  Flex,
  Form,
  Input,
  Select,
  Tooltip,
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import { FieldProps } from '../../types';
import { PromptParameters } from '../../../../../../api/common/types';
import { PromptParametersErrors } from '../../../../../../utils/validators';

import './index.scss';

const typeOptions = [
  { label: 'Text', value: 'text' },
  { label: 'Select', value: 'select' },
];

type Props = Omit<FieldProps, 'error'> & {
  error?: PromptParametersErrors;
};

const PromptParamsField = ({
  state,
  disabled,
  onUpdate,
  error,
}: Props) => {
  const onChange = useCallback((value: PromptParameters[] | null) => {
    onUpdate('promptParameters', value ?? undefined);
  }, [onUpdate]);

  const onAddNewRow = useCallback(() => {
    onChange([
      ...state.promptParameters ?? [],
      {
        key: '',
        name: '',
        type: 'text',
        placeholder: '',
      },
    ]);
  }, [onChange, state.promptParameters]);

  const onRemoveRow = useCallback((index: number) => {
    onChange(state.promptParameters?.filter((_, i) => index !== i) ?? []);
  }, [onChange, state.promptParameters]);

  const onValueChange = useCallback((
    index: number,
    key: keyof PromptParameters,
    value: PromptParameters[keyof PromptParameters],
  ) => {
    onChange(state.promptParameters?.map((item, i) => {
      if (index === i) {
        if (key === 'type') {
          if (value === 'text') {
            return {
              key: item.key,
              name: item.name,
              type: 'text',
              placeholder: '',
            };
          }

          return {
            key: item.key,
            name: item.name,
            type: 'select',
            options: [],
          };
        }

        return {
          ...item,
          [key]: value,
        };
      }

      return item;
    }) ?? []);
  }, [onChange, state.promptParameters]);

  return (
    <Form.Item label="Prompt parameter">
      {state.promptParameters?.map((row, index) => (
        <Flex
          gap={16}
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          className="prompt-parameters-row"
        >
          <Flex gap={8} wrap="wrap" className="flex-grow">
            <Form.Item
              label="Key"
              required
              className="mb-0 w-1/3"
              validateStatus={error?.[index]?.key ? 'error' : undefined}
              help={error?.[index]?.key}
            >
              <Input
                placeholder={row.type === 'text' ? 'clientName' : 'days'}
                value={row.key}
                disabled={disabled}
                onChange={(event) => onValueChange(index, 'key', event.target.value)}
              />
            </Form.Item>
            <Form.Item
              label="Display name"
              required
              className="mb-0 w-1/3"
              validateStatus={error?.[index]?.name ? 'error' : undefined}
              help={error?.[index]?.name}
            >
              <Input
                placeholder={row.type === 'text' ? 'Client name' : 'Days'}
                value={row.name}
                disabled={disabled}
                onChange={(event) => onValueChange(index, 'name', event.target.value)}
              />
            </Form.Item>
            <Form.Item label="Type" required className="mb-0 flex-grow">
              <Select
                value={row.type}
                options={typeOptions}
                disabled={disabled}
                onChange={(value) => onValueChange(index, 'type', value)}
              />
            </Form.Item>
            {row.type === 'text' && (
              <Form.Item label="Placeholder" className="mb-0 w-1/3">
                <Input
                  placeholder="Faline"
                  value={row.placeholder}
                  disabled={disabled}
                  onChange={(event) => onValueChange(index, 'placeholder', event.target.value)}
                />
              </Form.Item>
            )}
            {row.type === 'select' && (
              <Form.Item
                label="Options"
                required
                className="mb-0 w-full"
                validateStatus={error?.[index]?.options ? 'error' : undefined}
                help={error?.[index]?.options}
              >
                <Select
                  placeholder="Monday Tuesday Wendesday"
                  mode="tags"
                  value={row.options}
                  onChange={(value) => onValueChange(index, 'options', value)}
                  disabled={disabled}
                  open={false}
                />
              </Form.Item>
            )}
          </Flex>
          <Tooltip title="Remove row">
            <Button
              className="prompt-parameters-remove-row-button"
              type="primary"
              ghost
              icon={<DeleteOutlined />}
              onClick={() => onRemoveRow(index)}
              disabled={disabled}
            />
          </Tooltip>
        </Flex>
      ))}
      <Button
        className="prompt-parameters-add-row-button"
        type="link"
        onClick={onAddNewRow}
        disabled={disabled}
      >
        Add new row
      </Button>
    </Form.Item>
  );
};

export default PromptParamsField;
