import { Descriptions, Flex } from 'antd';
import { AgentProvider } from '../../../../api/agentProviders/types';

import DeleteAgentButton from '../deleteAgentButton';
import PreviewAgentButton from '../previewAgentButton';
import AssignNumberButton from '../assignNumberButton';
import UnassignNumberButton from '../unassignNumberButton';
import { useAgentDetailsColumns } from '../../hooks/useAgentDetailsColumns';

import './styles.scss';

interface AgentDetailsProps {
  agent: AgentProvider;
}

const AgentDetails = ({ agent }: AgentDetailsProps) => {
  const { columns } = useAgentDetailsColumns({ agent });

  return (
    <>
      <Flex
        className="mb-4"
        gap={12}
      >
        <PreviewAgentButton agent={agent} />
        {agent.orchestratorNumberAssignment ? (
          <UnassignNumberButton agent={agent} />
        ) : (
          <AssignNumberButton agent={agent} />
        )}
        <DeleteAgentButton agent={agent} />
      </Flex>
      <Descriptions
        className="agent-details-description"
        column={2}
        items={columns}
      />
    </>
  );
};

export default AgentDetails;
