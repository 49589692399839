import { Campaign } from '../campaigns/types';
import { TtsConfig } from '../ttsConfigs/types';
import { AsrConfig } from '../asrConfigs/types';
import { VoiceAgentConfig } from '../voiceAgentConfigs/types';

export interface Integration {
  integrationId: string;
  config: {
    IntegrationName: string,
    IntegrationParams: Record<string, unknown>
  }[];
}

export interface OrchestratorProvider {
  orchestratorProviderId: number;
  campaignId: number;
  campaign?: Campaign;
  providerType: string;
  config: {
    AgentId: string;
    VoiceId: string;
    AgentName: string;
    LlmWebsocket: string;
    backchannel_frequency?: number;
    backchannel_words?: Array<string>;
    boosted_keywords?: Array<string>;
    enable_backchannel?: boolean;
    pronunciation_dictionary?: string;
    interruption_sensitivity?: number;
    reminder_trigger_ms?: number;
    reminder_max_count?: number;
    responsiveness?: number;
    voice_speed?: number;
    voice_temperature?: number;
  };
  asrConfigId?: number;
  ttsConfigId?: number;
  voiceAgentConfigId?: number;
  deploymentPath?: string;
  replicas?: number;
  active: boolean;
  direction: number;
  ttsConfig?: TtsConfig;
  asrConfig?: AsrConfig;
  voiceAgentConfig?: VoiceAgentConfig;
}

export interface UniqueFilterResponse {
  name: string;
}

export interface PromptParameters {
  key: string;
  name: string;
  type: 'text' | 'select';
  placeholder?: string;
  options?: string[];
}

export interface OrchestratorNumberAssignment {
  orchestratorNumberAssignmentId: number;
  agentParams?: PromptParameters[];
  orchestratorNumber: string;
  originationNumber: string;
  orchestratorProviderId: string;
  agentProviderId: string;
  deploy: boolean;
}

export enum ModelProviderEnvironment {
  dev = 'dev',
  prod = 'prod',
}

export enum ModelProviderType {
  chat = 'chat',
  embedding = 'embedding',
}

export interface ModelProvider {
  id: number;
  alias: string;
  modelName: string;
  modelKind: string;
  modelType: ModelProviderType;
  modelVersion: string;
  tpmQuota: number;
  resourceCloudName: string;
  provider: string;
  region: string;
  domainName: string;
  key: string;
  endpoint: string;
  environment: { env: ModelProviderEnvironment };
  apiKey: string;
}

export enum PlaceholderTypes {
  str = 'str',
  float = 'float',
  bool = 'bool',
}

export interface Placeholder {
  placeholderId: number;
  placeholderName: string;
  placeholderDefaultValue: string;
  promptParam: boolean;
  placeholderType: PlaceholderTypes;
  placeholderChoices: string;
}
