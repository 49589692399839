import { useCallback } from 'react';
import { Button, Tooltip } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

import { AgentProvider } from '../../../../api/agentProviders/types';
import DuplicateAgentProvider from '../../providers/duplicateAgentProvider';

interface DeleteAgentActionProps {
  agent: AgentProvider;
}

const DuplicateAgentAction = ({ agent }: DeleteAgentActionProps) => {
  const duplicateAgent = DuplicateAgentProvider.useDuplicateAgent();

  const isDisabled = !agent.active;
  const humanAgent = !!agent.personaId;

  let tooltip = 'Exact agent duplication';

  if (isDisabled) {
    tooltip = 'The agent marked as archived';
  }

  if (humanAgent) {
    tooltip = 'Human agent cannot be duplicated';
  }

  const open = useCallback(() => {
    duplicateAgent.open(agent);
  }, [agent, duplicateAgent]);

  return (
    <Tooltip title={tooltip}>
      <Button
        type="primary"
        ghost
        icon={<CopyOutlined />}
        size="middle"
        onClick={open}
        disabled={isDisabled || humanAgent}
      />
    </Tooltip>
  );
};

export default DuplicateAgentAction;
