import { useCallback } from 'react';
import { Button } from 'antd';

import CreateCustomerProvider from '../../providers/createCustomerProvider';

const CreateCustomerButton = () => {
  const createCustomer = CreateCustomerProvider.useCreateCustomer();

  const open = useCallback(() => {
    createCustomer.open();
  }, [createCustomer]);

  return (
    <Button
      type="primary"
      onClick={open}
    >
      Create customer
    </Button>
  );
};

export default CreateCustomerButton;
