import { useCallback, useState } from 'react';
import {
  Button,
  Popconfirm,
  Tooltip,
  Typography,
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import DeleteQuestionProvider from '../../providers/deleteQuestionProvider';
import { Question } from '../../../../api/questions/types';

interface DeleteQuestionActionProps {
  question: Question;
}

const DeleteQuestionAction = ({ question }: DeleteQuestionActionProps) => {
  const { loading, onDeleteQuestion } = DeleteQuestionProvider.useDeleteQuestion();

  const [open, setOpen] = useState(false);

  const showConfirmation = useCallback(() => setOpen(true), []);

  const onConfirm = useCallback(async () => {
    await onDeleteQuestion(question.questionId);

    setOpen(false);
  }, [question.questionId, onDeleteQuestion]);

  const onCancel = useCallback(() => setOpen(false), []);

  return (
    <Popconfirm
      title={`Delete the question #${question.questionId}`}
      description={(
        <Typography.Text>
          <Typography.Text strong>
            The question will be removed from all related personas
          </Typography.Text>
          <br />
          Are you sure to delete the question?
        </Typography.Text>
      )}
      open={open}
      onConfirm={onConfirm}
      okButtonProps={{ loading }}
      onCancel={onCancel}
    >
      <Tooltip title="Delete question">
        <Button
          type="primary"
          ghost
          icon={<DeleteOutlined />}
          onClick={showConfirmation}
        />
      </Tooltip>
    </Popconfirm>
  );
};

export default DeleteQuestionAction;
