import axios from 'axios';
import { Page } from '@marrlab-app-shared/types';

import {
  CreateCustomerParams,
  Customer,
  GetCustomersParams,
} from './types';
import config from '../../utils/config';

export const getCustomers = async (params: GetCustomersParams) => (
  axios.get<Page<Customer>>(`${config.CALLS_HISTORY_API_BASE_URL}/api/customers/`, { params })
);

export const createCustomer = async (params: CreateCustomerParams) => (
  axios.post<Customer>(`${config.CALLS_HISTORY_API_BASE_URL}/api/customers`, params)
);

export const editCustomer = async (id: number, params: CreateCustomerParams) => (
  axios.put<Customer>(`${config.CALLS_HISTORY_API_BASE_URL}/api/customers/${id}`, params)
);

export const deleteCustomer = async (id: number) => (
  axios.delete<void>(`${config.CALLS_HISTORY_API_BASE_URL}/api/customers/${id}`)
);
