import { useState } from 'react';
import { TableColumnsType, TableProps } from 'antd';

import { Customer } from '../../../api/customers/types';
import { columnsInitialState } from '../constants/columnsInitialState';

const sticky: TableProps<Customer>['sticky'] = { offsetHeader: 0 };

const scroll: TableProps<Customer>['scroll'] = { x: 'max-content' };

const useTableConfiguration = () => {
  const [columns, setColumns] = useState<TableColumnsType<Customer>>(columnsInitialState);

  return {
    columns,
    sticky,
    scroll,
    setColumns,
  };
};

export default useTableConfiguration;
