import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { AxiosError } from 'axios';
import { message, Typography } from 'antd';
import SuccessNotificationProvider from '@marrlab-app-shared/components/successNotification';

import CreateCustomerModal from '../../components/createCustomerModal';
import { CreateCustomerParams, Customer } from '../../../../api/customers/types';
import { createCustomer, editCustomer } from '../../../../api/customers';

interface CreateCustomerContextType {
  open: () => void;
  openOnEdit: (customer: Customer) => void;
}

const initialState: CreateCustomerContextType = {
  open: () => {
  },
  openOnEdit: () => {
  },
};

const CreateCustomerContext = createContext<CreateCustomerContextType>(initialState);

interface CreateCustomerContextProps extends PropsWithChildren {
  fetchCustomers: () => Promise<void>;
}

const CreateCustomerProvider = ({ children, fetchCustomers }: CreateCustomerContextProps) => {
  const successNotification = SuccessNotificationProvider.useSuccessNotification();

  const [isOpen, setIsOpen] = useState(false);
  const [customerToEdit, setCustomerToEdit] = useState<Customer | null>(null);

  const close = useCallback(() => {
    setIsOpen(false);
    setCustomerToEdit(null);
  }, []);

  const open = useCallback(() => {
    setIsOpen(true);
  }, []);

  const openOnEdit = useCallback((customer: Customer) => {
    setCustomerToEdit(customer);
    setIsOpen(true);
  }, []);

  const onConfirm = useCallback(async (params: CreateCustomerParams) => {
    try {
      const response = customerToEdit
        ? await editCustomer(customerToEdit.customerId, params)
        : await createCustomer(params);

      successNotification.open({
        key: response.data.customerId.toString(),
        title: customerToEdit ? 'Changes saved' : 'Customer created successfully',
        message: (
          <>
            <Typography.Text strong>Customer ID: </Typography.Text>
            <Typography.Text>{response.data.customerId}</Typography.Text>
            <br />
            <Typography.Text strong>Customer name: </Typography.Text>
            <Typography.Text>{response.data.name}</Typography.Text>
          </>
        ),
      });

      await fetchCustomers();

      close();
    } catch (error) {
      if (error instanceof AxiosError) {
        message.open({ content: error.response?.data?.message, type: 'error', duration: 10 });
      }
    }
  }, [close, customerToEdit, fetchCustomers, successNotification]);

  const value: CreateCustomerContextType = useMemo(
    () => ({
      open,
      openOnEdit,
    }),
    [open, openOnEdit],
  );

  return (
    <CreateCustomerContext.Provider value={value}>
      {children}
      <CreateCustomerModal
        isOpen={isOpen}
        initialCustomer={customerToEdit}
        onCancel={close}
        onConfirm={onConfirm}
      />
    </CreateCustomerContext.Provider>
  );
};

CreateCustomerProvider.useCreateCustomer = () => useContext(CreateCustomerContext);

export default CreateCustomerProvider;
