import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { AxiosError } from 'axios';
import { message } from 'antd';

import { deleteReferenceData } from '../../../../api/referenceData';

interface DeleteReferenceDataContextType {
  loading: boolean;
  onDeleteReferenceData: (id: number) => Promise<void>;
}

const initialState: DeleteReferenceDataContextType = {
  loading: false,
  onDeleteReferenceData: () => Promise.reject(),
};

const DeleteReferenceDataContext = createContext<DeleteReferenceDataContextType>(initialState);

interface DeleteReferenceDataProviderProps extends PropsWithChildren {
  fetchReferenceData: () => Promise<void>;
}

const DeleteReferenceDataProvider = ({
  children,
  fetchReferenceData,
}: DeleteReferenceDataProviderProps) => {
  const [loading, setLoading] = useState(false);

  const onDeleteReferenceData = useCallback(async (id: number) => {
    setLoading(true);

    try {
      await deleteReferenceData(id);

      await fetchReferenceData();
    } catch (error) {
      if (error instanceof AxiosError) {
        message.open({ content: error.response?.data?.message, type: 'error', duration: 10 });
      }
    }

    setLoading(false);
  }, [fetchReferenceData]);

  const value: DeleteReferenceDataContextType = useMemo(
    () => ({
      loading,
      onDeleteReferenceData,
    }),
    [loading, onDeleteReferenceData],
  );

  return (
    <DeleteReferenceDataContext.Provider value={value}>
      {children}
    </DeleteReferenceDataContext.Provider>
  );
};

DeleteReferenceDataProvider.useDeleteReferenceData = () => useContext(DeleteReferenceDataContext);

export default DeleteReferenceDataProvider;
