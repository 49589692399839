import { useCallback, useState } from 'react';
import { AxiosError } from 'axios';
import { message } from 'antd';

import { trimCreateTtsConfigParams } from '../mappers';
import { CreateTtsConfigParams } from '../../../../../api/ttsConfigs/types';
import { CreateTtsConfigModalState } from '../types';

interface UseCreateTtsConfigModalLogicArgs {
  state: CreateTtsConfigModalState;
  isValid: (state: CreateTtsConfigModalState) => boolean;
  onConfirm: (params: CreateTtsConfigParams) => Promise<void>;
}

const useCreateTtsConfigModalLogic = ({
  state,
  isValid,
  onConfirm: onConfirmBase,
}: UseCreateTtsConfigModalLogicArgs) => {
  const [saving, setSaving] = useState(false);

  const onConfirm = useCallback(async () => {
    if (!isValid(state)) {
      return;
    }

    setSaving(true);

    try {
      await onConfirmBase(trimCreateTtsConfigParams(state));
    } catch (error) {
      console.error(error);

      if (error instanceof AxiosError) {
        message.open({ content: error.response?.data?.message, type: 'error', duration: 10 });
      }
    }

    setSaving(false);
  }, [isValid, state, onConfirmBase]);

  return {
    saving,
    onConfirm,
  };
};

export default useCreateTtsConfigModalLogic;
