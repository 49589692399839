import { useCallback, useEffect, useState } from 'react';

import { isEmptyString, isValidJson } from '../../../../../utils/validators';
import { CreateVoiceAgentConfigModalState } from '../types';

type Errors = Partial<Record<keyof CreateVoiceAgentConfigModalState, string>>;

interface UseCreateVoiceAgentConfigModalValidation {
  isOpen: boolean;
}

const initialState: Errors = {};

const useCreateVoiceAgentConfigModalValidation = ({
  isOpen,
}: UseCreateVoiceAgentConfigModalValidation) => {
  const [errors, setErrors] = useState<Errors>(initialState);

  const isValid = useCallback((state: CreateVoiceAgentConfigModalState) => {
    const newState = {
      shortName: isEmptyString(state.shortName),
      config: isValidJson(state.config),
    };

    setErrors(newState);

    return Object.values(newState).every((field) => !field);
  }, []);

  const clearErrorForField = useCallback((propName: string) => {
    setErrors((state) => ({
      ...state,
      [propName]: '',
    }));
  }, []);

  useEffect(() => {
    if (!isOpen) {
      setErrors(initialState);
    }
  }, [isOpen]);

  return {
    errors,
    isValid,
    clearErrorForField,
  };
};

export default useCreateVoiceAgentConfigModalValidation;
