import { useCallback } from 'react';
import usePaginatedDataFetchWithSearch, {
  GetSearchableDataParams,
} from '@marrlab-app-shared/hooks/data/usePaginatedDataFetchWithSearch';

import { getReferenceDataFields } from '../../../../../api/referenceDataFields';

interface UseReferenceDataFieldsArgs {
  campaignId?: string;
}

const useReferenceDataFields = ({ campaignId }: UseReferenceDataFieldsArgs) => {
  const request = useCallback(({ search }: GetSearchableDataParams) => {
    if (campaignId) {
      return getReferenceDataFields({ search, campaign: campaignId });
    }

    throw new Error('No campaignId provided');
  }, [campaignId]);

  const { loading, data } = usePaginatedDataFetchWithSearch({
    request,
  });

  return {
    loading,
    data: data ?? [],
  };
};

export default useReferenceDataFields;
