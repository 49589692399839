import {
  useCallback,
  useEffect,
  useState,
} from 'react';

import { getCreateModalInitialState } from '../mappers';
import { CreateReferenceDataFieldParams, ReferenceDataField } from '../../../../../api/referenceDataFields/types';

interface UseCreateReferenceDataFieldModalStateArgs {
  clearErrorForField: (propName: string) => void;
  initialReferenceDataField: ReferenceDataField | null;
  isOpen: boolean;
}

const useCreateReferenceDataFieldModalState = ({
  isOpen,
  initialReferenceDataField,
  clearErrorForField,
}: UseCreateReferenceDataFieldModalStateArgs) => {
  const [state, setState] = useState<CreateReferenceDataFieldParams>(getCreateModalInitialState());

  const onPropertyUpdate = useCallback((
    propName: keyof CreateReferenceDataFieldParams,
    value: CreateReferenceDataFieldParams[keyof CreateReferenceDataFieldParams],
  ) => {
    setState((oldState) => ({ ...oldState, [propName]: value }));
    clearErrorForField(propName);
  }, [clearErrorForField]);

  useEffect(() => {
    setState(getCreateModalInitialState(isOpen ? initialReferenceDataField : null));
  }, [initialReferenceDataField, isOpen]);

  const isEdit = !!initialReferenceDataField;

  return {
    isEdit,
    state,
    onPropertyUpdate,
  };
};

export default useCreateReferenceDataFieldModalState;
