import { useCallback, useState } from 'react';
import {
  Button,
  Popconfirm,
  Tooltip,
  Typography,
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import { AgentProvider } from '../../../../api/agentProviders/types';
import DeleteAgentProvider from '../../providers/deleteAgentProvider';

interface DeleteAgentActionProps {
  agent: AgentProvider;
}

const DeleteAgentAction = ({ agent }: DeleteAgentActionProps) => {
  const { loading, onDeleteAgent } = DeleteAgentProvider.useDeleteAgent();

  const [open, setOpen] = useState(false);

  const isInactive = !agent.active;
  const humanAgent = !!agent.personaId;
  const hasNumberAssigned = !!agent.orchestratorNumberAssignment;
  const isDisabled = isInactive || hasNumberAssigned;

  let tooltip = 'Archive agent';

  if (isInactive) {
    tooltip = 'The agent marked as archived';
  }

  if (hasNumberAssigned) {
    tooltip = 'To archive the agent, unassign number from the agent first';
  }

  const showConfirmation = useCallback(() => setOpen(true), []);

  const onConfirm = useCallback(async () => {
    await onDeleteAgent(agent.agentProviderId);

    setOpen(false);
  }, [agent.agentProviderId, onDeleteAgent]);

  const onCancel = useCallback(() => setOpen(false), []);

  return (
    <Popconfirm
      title={`Archived the agent #${agent.agentProviderId}`}
      description={humanAgent ? (
        <Typography.Text>
          <Typography.Text strong>
            This agent associated to the persona #$
            {agent.personaId}
          </Typography.Text>
          <br />
          Are you sure to archive the agent?
        </Typography.Text>
      ) : 'Are you sure to archive the agent?'}
      open={open}
      onConfirm={onConfirm}
      okButtonProps={{ loading }}
      onCancel={onCancel}
      disabled={isDisabled}
    >
      <Tooltip title={tooltip}>
        <Button
          type="primary"
          ghost
          icon={<DeleteOutlined />}
          onClick={showConfirmation}
          disabled={isDisabled}
        />
      </Tooltip>
    </Popconfirm>
  );
};

export default DeleteAgentAction;
