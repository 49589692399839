import { useCallback } from 'react';
import { Form, InputNumber } from 'antd';

import { FieldProps } from '../../types';

const ReminderMaxCountField = ({
  state,
  disabled,
  onUpdate,
}: FieldProps) => {
  const onChange = useCallback((value: number | null) => {
    onUpdate('reminderMaxCount', value ?? undefined);
  }, [onUpdate]);

  return (
    <Form.Item
      className="field-with-info-tooltip"
      label="Reminder max count"
      tooltip="If set, controls how many times agent would remind user when user is unresponsive. Must be a non negative integer. If unset, default value of 1 will apply (remind once). Set to 0 to disable agent from reminding."
    >
      <InputNumber
        placeholder="1"
        value={state.reminderMaxCount}
        onChange={onChange}
        disabled={disabled}
        addonAfter="ms"
        min={0}
        step={1}
      />
    </Form.Item>
  );
};

export default ReminderMaxCountField;
