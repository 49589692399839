import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import { message, Typography } from 'antd';
import SuccessNotificationProvider from '@marrlab-app-shared/components/successNotification';

import CreateReferenceDataModal from '../../components/createReferenceDataModal';
import { CreateReferenceDataParams, ReferenceData } from '../../../../api/referenceData/types';
import { createReferenceData, editReferenceData } from '../../../../api/referenceData';
import { Persona } from '../../../../api/personas/types';

interface CreateReferenceDataContextType {
  open: () => void;
  openOnEdit: (referenceData: ReferenceData) => void;
}

export type CreateReferenceDataModalState = Record<string, CreateReferenceDataParams>;

const initialState: CreateReferenceDataContextType = {
  open: () => {
  },
  openOnEdit: () => {
  },
};

const CreateReferenceDataContext = createContext<CreateReferenceDataContextType>(initialState);

interface CreateReferenceDataContextProps extends PropsWithChildren {
  persona: Persona | null;
  fetchReferenceData?: () => Promise<void>;
}

interface NotificationData {
  id: string;
  personaId: number;
  referenceFieldId: string;
  referenceDataId: number;
  referenceData: string;
}

const getDataForNotification = (
  response: AxiosResponse<ReferenceData, unknown> | AxiosResponse<ReferenceData, unknown>[],
): NotificationData => {
  if (Array.isArray(response)) {
    return {
      id: response.map((singleResponse) => singleResponse.data.id).join(', '),
      personaId: response[0].data.personaId,
      referenceDataId: response[0].data.referenceDataId,
      referenceFieldId: response.map((singleResponse) => singleResponse.data.referenceFieldId).join(', '),
      referenceData: response.map((singleResponse) => singleResponse.data.referenceData).join(', '),
    };
  }

  return {
    ...response.data,
    referenceFieldId: response.data.referenceFieldId.toString(),
    id: response.data.id.toString(),
  };
};

const CreateReferenceDataProvider = ({
  children,
  persona,
  fetchReferenceData,
}: CreateReferenceDataContextProps) => {
  const successNotification = SuccessNotificationProvider.useSuccessNotification();

  const [isOpen, setIsOpen] = useState(false);
  const [referenceDataToEdit, setReferenceDataToEdit] = useState<ReferenceData | null>(null);

  const close = useCallback(() => {
    setIsOpen(false);
    setReferenceDataToEdit(null);
  }, []);

  const open = useCallback(() => {
    setIsOpen(true);
  }, []);

  const openOnEdit = useCallback((referenceData: ReferenceData) => {
    setReferenceDataToEdit(referenceData);
    setIsOpen(true);
  }, []);

  const onConfirm = useCallback(async (params: CreateReferenceDataModalState) => {
    try {
      const response = referenceDataToEdit
        ? await editReferenceData(referenceDataToEdit.id, Object.values(params)[0])
        : await Promise.all(Object.values(params).map((sectionParams) => (
          createReferenceData(sectionParams)
        )));

      const notificationData = getDataForNotification(response);

      successNotification.open({
        key: notificationData.id.toString(),
        title: referenceDataToEdit ? 'Changes saved' : 'Reference data created successfully',
        message: (
          <>
            <Typography.Text strong>ID: </Typography.Text>
            <Typography.Text>{notificationData.id}</Typography.Text>
            <br />
            <Typography.Text strong>Persona ID: </Typography.Text>
            <Typography.Text>{notificationData.personaId}</Typography.Text>
            <br />
            <Typography.Text strong>Reference data field ID: </Typography.Text>
            <Typography.Text>{notificationData.referenceFieldId}</Typography.Text>
            <br />
            <Typography.Text strong>Reference data ID: </Typography.Text>
            <Typography.Text>{notificationData.referenceDataId}</Typography.Text>
            <br />
            <Typography.Text strong>Reference data: </Typography.Text>
            <Typography.Text>{notificationData.referenceData}</Typography.Text>
          </>
        ),
      });

      if (fetchReferenceData) {
        await fetchReferenceData();
      }

      close();
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        message.open({ content: error.response?.data?.message, type: 'error', duration: 10 });
      }
    }
  }, [referenceDataToEdit, successNotification, fetchReferenceData, close]);

  const value: CreateReferenceDataContextType = useMemo(
    () => ({
      open,
      openOnEdit,
    }),
    [open, openOnEdit],
  );

  return (
    <CreateReferenceDataContext.Provider value={value}>
      {children}
      <CreateReferenceDataModal
        persona={persona}
        isOpen={isOpen}
        initialReferenceData={referenceDataToEdit}
        onCancel={close}
        onConfirm={onConfirm}
      />
    </CreateReferenceDataContext.Provider>
  );
};

CreateReferenceDataProvider.useCreateReferenceData = () => useContext(CreateReferenceDataContext);

export default CreateReferenceDataProvider;
