import { useCallback } from 'react';
import { Button } from 'antd';

import CreateAsrConfigProvider from '../../providers/createAsrConfigProvider';

const CreateAsrConfigButton = () => {
  const createAsrConfig = CreateAsrConfigProvider.useCreateAsrConfig();

  const open = useCallback(() => {
    createAsrConfig.open();
  }, [createAsrConfig]);

  return (
    <Button
      type="primary"
      onClick={open}
    >
      Create ASR config
    </Button>
  );
};

export default CreateAsrConfigButton;
