import { useCallback } from 'react';
import { Form, InputNumber } from 'antd';

import { FieldProps } from '../../types';

const VoiceSpeedField = ({
  state,
  disabled,
  onUpdate,
}: FieldProps) => {
  const onChange = useCallback((value: number | null) => {
    onUpdate('voiceSpeed', value ?? undefined);
  }, [onUpdate]);

  return (
    <Form.Item
      className="field-with-info-tooltip"
      label="Voice speed"
      tooltip="Controls speed of voice. Value ranging from [0.5,2]. Lower value means slower speech, while higher value means faster speech rate. If unset, default value 1 will apply."
    >
      <InputNumber
        placeholder="1.00"
        value={state.voiceSpeed}
        onChange={onChange}
        disabled={disabled}
        min={0.5}
        max={2}
        precision={2}
        step={0.1}
      />
    </Form.Item>
  );
};

export default VoiceSpeedField;
