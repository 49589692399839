import axios from 'axios';
import { Page } from '@marrlab-app-shared/types';

import {
  Campaign,
  CreateCampaignParams,
  GetCampaignsParams,
} from './types';
import config from '../../utils/config';

export const getCampaigns = async (params: GetCampaignsParams) => (
  axios.get<Page<Campaign>>(`${config.CALLS_HISTORY_API_BASE_URL}/api/campaigns/`, { params })
);

export const getCampaignsWithTemplates = async (params: GetCampaignsParams) => (
  getCampaigns({
    ...params,
    template: true,
  })
);

export const getCampaignById = async (campaignId: number) => (
  axios.get<Campaign>(`${config.CALLS_HISTORY_API_BASE_URL}/api/campaigns/${campaignId}`)
);

export const createCampaign = async (params: CreateCampaignParams) => (
  axios.post<Campaign>(`${config.CALLS_HISTORY_API_BASE_URL}/api/campaigns`, params)
);

export const editCampaign = async (id: number, params: CreateCampaignParams) => (
  axios.put<Campaign>(`${config.CALLS_HISTORY_API_BASE_URL}/api/campaigns/${id}`, params)
);

export const deleteCampaign = async (id: number) => (
  axios.delete<void>(`${config.CALLS_HISTORY_API_BASE_URL}/api/campaigns/${id}`)
);
