import axios from 'axios';
import { Page } from '@marrlab-app-shared/types';

import {
  CreateHumanTemplateParams,
  GetHumanTemplateParams,
  HumanTemplate,
} from './types';
import config from '../../utils/config';
import { Placeholder } from '../common/types';

export const getHumanTemplate = async (params: GetHumanTemplateParams) => (
  axios.get<Page<HumanTemplate>>(`${config.CALLS_HISTORY_API_BASE_URL}/api/human-templates/`, { params })
);

export const getPlaceholdersByHumanTemplateId = async (templateId: number) => (
  axios.get<Placeholder[]>(`${config.CALLS_HISTORY_API_BASE_URL}/api/human-templates/${templateId}/placeholders`)
);

export const getHumanTemplateById = async (humanTemplateId: number) => (
  axios.get<HumanTemplate>(`${config.CALLS_HISTORY_API_BASE_URL}/api/human-templates/${humanTemplateId}`)
);

export const createHumanTemplate = async (params: CreateHumanTemplateParams) => (
  axios.post<HumanTemplate>(`${config.CALLS_HISTORY_API_BASE_URL}/api/human-templates`, params)
);

export const editHumanTemplate = async (id: string, params: CreateHumanTemplateParams) => (
  axios.put<HumanTemplate>(`${config.CALLS_HISTORY_API_BASE_URL}/api/human-templates/${id}`, params)
);
