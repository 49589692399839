import { useCallback, useEffect, useState } from 'react';

import { AssignNumberModalState } from '../types';
import {
  isEmptyNumber,
  isEmptyString,
  PromptParametersErrors,
  validatePromptParams,
} from '../../../../../utils/validators';

type Errors = Partial<Record<keyof Omit<AssignNumberModalState, 'promptParameters'>, string>> & {
  promptParameters?: PromptParametersErrors
};

interface UseAssignNumberModalValidation {
  isOpen: boolean;
}

const initialState: Errors = {};

const useAssignNumberModalValidation = ({ isOpen }: UseAssignNumberModalValidation) => {
  const [errors, setErrors] = useState<Errors>(initialState);

  const isValid = useCallback((state: AssignNumberModalState) => {
    const newState: Errors = {
      agentProviderId: isEmptyNumber(state.agentProviderId),
      orchestratorProviderId: isEmptyNumber(state.orchestratorProviderId),
      originationNumber: isEmptyString(state.originationNumber),
      promptParameters: validatePromptParams(state.promptParameters),
      areaCode: state.createTwilioNumber ? isEmptyNumber(state.areaCode) : '',
    };

    setErrors(newState);

    return Object.values(newState).every((field) => !field);
  }, []);

  const clearErrorForField = useCallback((propName: string) => {
    setErrors((state) => ({
      ...state,
      [propName]: '',
    }));
  }, []);

  useEffect(() => {
    if (!isOpen) {
      setErrors(initialState);
    }
  }, [isOpen]);

  return {
    errors,
    isValid,
    clearErrorForField,
  };
};

export default useAssignNumberModalValidation;
