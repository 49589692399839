import { Modal, Form } from 'antd';
import ModalFooter from '@marrlab-app-shared/components/modalFooter';

import NameField from './components/nameField';
import ShortNameField from './components/shortNameField';

import useCreateCustomerModalLogic from './hooks/useCreateCustomerModalLogic';
import useCreateCustomerModalState from './hooks/useCreateCustomerModalState';
import useCreateCustomerModalValidation from './hooks/useCreateCustomerModalValidation';
import { CreateCustomerParams, Customer } from '../../../../api/customers/types';

interface CreateCustomerModalProps {
  isOpen: boolean;
  initialCustomer: Customer | null;
  onConfirm: (params: CreateCustomerParams) => Promise<void>;
  onCancel: () => void;
}

const CreateCustomerModal = ({
  isOpen,
  initialCustomer,
  onConfirm: onConfirmBase,
  onCancel,
}: CreateCustomerModalProps) => {
  const {
    errors,
    isValid,
    clearErrorForField,
  } = useCreateCustomerModalValidation({ isOpen });

  const {
    isEdit,
    state,
    onPropertyUpdate,
  } = useCreateCustomerModalState({
    isOpen,
    initialCustomer,
    clearErrorForField,
  });

  const { saving, onConfirm } = useCreateCustomerModalLogic({
    state,
    isValid,
    onConfirm: onConfirmBase,
  });

  return (
    <Modal
      title={isEdit ? `Edit customer #${initialCustomer?.customerId}` : 'Create new customer'}
      open={isOpen}
      onOk={onConfirm}
      onCancel={onCancel}
      width="600px"
      footer={(
        <ModalFooter
          disabled={saving}
          saveButtonLabel={isEdit ? 'Save' : 'Create'}
          onCancel={onCancel}
          onConfirm={onConfirm}
        />
      )}
    >
      <Form
        layout="vertical"
        className="py-4"
      >
        <NameField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={saving}
          error={errors.name}
        />
        <ShortNameField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={saving || isEdit}
          error={errors.shortName}
        />
      </Form>
    </Modal>
  );
};

export default CreateCustomerModal;
