import { useCallback, useEffect, useState } from 'react';
import { message } from 'antd';
import { AxiosError } from 'axios';
import { Page } from '@marrlab-app-shared/types';

import { TtsConfig, GetTtsConfigParams } from '../../../api/ttsConfigs/types';
import { getTtsConfigs } from '../../../api/ttsConfigs';

const useTtsConfigsData = ({
  skip,
  take,
  sortBy,
  order,
  search,
}: GetTtsConfigParams) => {
  const [data, setData] = useState<Page<TtsConfig> | null>(null);
  const [loading, setLoading] = useState(false);

  const fetchTtsConfigs = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getTtsConfigs({
        skip,
        take,
        sortBy,
        order,
        search,
      });

      setData(response.data);
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        message.open({ content: error.message, type: 'error', duration: 10 });
      }
    }

    setLoading(false);
  }, [order, search, skip, sortBy, take]);

  useEffect(() => {
    fetchTtsConfigs();
  }, [fetchTtsConfigs]);

  return {
    data,
    loading,
    fetchTtsConfigs,
  };
};

export default useTtsConfigsData;
