import {
  useCallback,
  useEffect,
  useState,
} from 'react';

import { getCreateModalInitialState } from '../mappers';
import { AsrConfig } from '../../../../../api/asrConfigs/types';
import { CreateAsrConfigModalState } from '../types';

interface UseCreateAsrConfigModalStateArgs {
  clearErrorForField: (propName: string) => void;
  initialAsrConfig: AsrConfig | null;
  isOpen: boolean;
}

const useCreateAsrConfigModalState = ({
  isOpen,
  initialAsrConfig,
  clearErrorForField,
}: UseCreateAsrConfigModalStateArgs) => {
  const [state, setState] = useState<CreateAsrConfigModalState>(getCreateModalInitialState());

  const onPropertyUpdate = useCallback((
    propName: keyof CreateAsrConfigModalState,
    value: CreateAsrConfigModalState[keyof CreateAsrConfigModalState],
  ) => {
    setState((oldState) => ({ ...oldState, [propName]: value }));
    clearErrorForField(propName);
  }, [clearErrorForField]);

  useEffect(() => {
    setState(getCreateModalInitialState(isOpen ? initialAsrConfig : null));
  }, [initialAsrConfig, isOpen]);

  const isEdit = !!initialAsrConfig;

  return {
    isEdit,
    state,
    onPropertyUpdate,
  };
};

export default useCreateAsrConfigModalState;
