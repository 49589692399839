import {
  useCallback,
  useEffect,
  useState,
} from 'react';

import { getCreateModalInitialState } from '../mappers';
import { Persona } from '../../../api/personas/types';
import { CreatePersonaModalState } from '../types';
import usePlaceholdersList from './usePlaceholdersList';
import useCreatePersonaModalValidation from './useCreatePersonaModalValidation';

interface UseCreatePersonaModalStateArgs {
  initialPersona: Persona | null;
}

const useCreatePersonaModalState = ({
  initialPersona,
}: UseCreatePersonaModalStateArgs) => {
  const [state, setState] = useState<CreatePersonaModalState>(
    getCreateModalInitialState({}),
  );

  const {
    loading,
    placeholders,
    clearPlaceholder,
  } = usePlaceholdersList({ templateId: state.templateId });

  const {
    errors,
    isValid,
    clearErrorForField,
  } = useCreatePersonaModalValidation({
    placeholders,
  });

  const onPropertyUpdate = useCallback((
    propName: keyof CreatePersonaModalState,
    value: CreatePersonaModalState[keyof CreatePersonaModalState],
  ) => {
    setState((oldState) => ({ ...oldState, [propName]: value }));

    if (propName === 'campaignId') {
      setState((oldState) => ({ ...oldState, templateId: null }));
      setState((oldState) => ({ ...oldState, templateText: '' }));
      setState((oldState) => ({ ...oldState, questionIds: [] }));
      setState((oldState) => ({ ...oldState, placeholderValuesRaw: {} }));
      clearPlaceholder();
    }

    if (propName === 'templateId') {
      setState((oldState) => ({ ...oldState, placeholderValuesRaw: {} }));
      clearPlaceholder();
    }

    clearErrorForField(propName);
  }, [clearErrorForField, clearPlaceholder]);

  useEffect(() => {
    setState(getCreateModalInitialState({
      initialPersona: initialPersona ?? null,
    }));
  }, [initialPersona]);

  const isEdit = !!initialPersona;

  return {
    loading,
    isEdit,
    state,
    errors,
    placeholders,
    isValid,
    onPropertyUpdate,
  };
};

export default useCreatePersonaModalState;
