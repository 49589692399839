import { Flex } from 'antd';

import DeleteAgentAction from '../deleteAgentAction';
import DuplicateAgentAction from '../duplicateAgentAction';
import EditAgentAction from '../editAgentAction';
import AssignNumberModal from '../assignNumberAction';
import UnassignNumberAction from '../unassignNumberAction';
import DeployAgentAction from '../deployAgentAction';
import UndeployAgentAction from '../undeployAgentAction';
import { AgentProvider } from '../../../../api/agentProviders/types';

interface ActionsCellProps {
  agent: AgentProvider;
}

const ActionsCell = ({ agent }: ActionsCellProps) => (
  <Flex gap={8} justify="end">
    <EditAgentAction agent={agent} />
    {agent.orchestratorNumberAssignment ? (
      <UnassignNumberAction agent={agent} />
    ) : (
      <AssignNumberModal agent={agent} />
    )}
    {agent.orchestratorNumberAssignment?.deploy ? (
      <UndeployAgentAction agent={agent} />
    ) : (
      <DeployAgentAction agent={agent} />
    )}
    <DuplicateAgentAction agent={agent} />
    <DeleteAgentAction agent={agent} />
  </Flex>
);

export default ActionsCell;
