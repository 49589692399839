import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { AxiosError } from 'axios';
import { message } from 'antd';

import { deleteAgent } from '../../../../api/agentProviders';

interface DeleteAgentContextType {
  loading: boolean;
  onDeleteAgent: (agentId: string) => Promise<void>;
}

const initialState: DeleteAgentContextType = {
  loading: false,
  onDeleteAgent: () => Promise.reject(),
};

const DeleteAgentContext = createContext<DeleteAgentContextType>(initialState);

interface DeleteAgentProviderProps extends PropsWithChildren {
  fetchAgentProviders: () => Promise<void>;
}

const DeleteAgentProvider = ({ children, fetchAgentProviders }: DeleteAgentProviderProps) => {
  const [loading, setLoading] = useState(false);

  const onDeleteAgent = useCallback(async (agentProviderId: string) => {
    setLoading(true);

    try {
      await deleteAgent(agentProviderId);

      await fetchAgentProviders();
    } catch (error) {
      if (error instanceof AxiosError) {
        message.open({ content: error.response?.data?.message, type: 'error', duration: 10 });
      }
    }

    setLoading(false);
  }, [fetchAgentProviders]);

  const value: DeleteAgentContextType = useMemo(
    () => ({
      loading,
      onDeleteAgent,
    }),
    [loading, onDeleteAgent],
  );

  return (
    <DeleteAgentContext.Provider value={value}>
      {children}
    </DeleteAgentContext.Provider>
  );
};

DeleteAgentProvider.useDeleteAgent = () => useContext(DeleteAgentContext);

export default DeleteAgentProvider;
