import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
import { wrapCreateBrowserRouter } from '@sentry/react';
import ClerkProvider from '@marrlab-app-shared/components/clerkProvider';

import MainPage from './pages/main';
import NotFoundPage from './pages/notFound';
import SignInPage from './pages/signIn';
import Customers from './pages/customers';
import PageLayout from './components/pageLayout';
import Navigation from './components/navigation';
import Campaigns from './pages/campaigns';
import HumanTemplates from './pages/humanTemplates';
import Questions from './pages/questions';
import ReferenceDataFields from './pages/referenceDataFields';
import Personas from './pages/personas';
import PersonaManagement from './pages/personaManagement';
import AsrConfig from './pages/asrConfig';
import TtsConfig from './pages/ttsConfig';
import VoiceAgentConfig from './pages/voiceAgentConfig';
import UnauthorizedPageLayout from './components/unauthorizedPageLayout';

import config from './utils/config';

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

const testingToolRoutes = config.TESTING_TOOLS_ENABLED ? [
  {
    path: '/human-templates',
    element: <HumanTemplates />,
  },
  {
    path: '/questions',
    element: <Questions />,
  },
  {
    path: '/reference-data-fields',
    element: <ReferenceDataFields />,
  },
  {
    path: '/personas',
    element: <Personas />,
  },
  {
    path: '/personas/:personaId',
    element: <PersonaManagement />,
  },
] : [];

const redirectOrigins = config.MODE === 'development'
  ? [
    'https://vox-configuration-dev.marrlabs.io',
    'https://call-operator-dev.marrlabs.io',
    'https://review-app-dev.marrlabs.io',
  ] : [
    'https://call-operator.marrlabs.io',
    'https://vox-configuration.marrlabs.io',
    'https://review-app.marrlabs.io',
  ];

const router = sentryCreateBrowserRouter([{
  element: <ClerkProvider
    publishableKey={config.CLERK_PUBLISHABLE_KEY}
    allowedRedirectOrigins={redirectOrigins}
  />,
  children: [
    {
      element: <PageLayout />,
      children: [
        {
          element: <Navigation />,
          children: [
            {
              index: true,
              element: <MainPage />,
            },
            {
              path: '/customers',
              element: <Customers />,
            },
            {
              path: '/campaigns',
              element: <Campaigns />,
            },
            {
              path: '/asr-configs',
              element: <AsrConfig />,
            },
            {
              path: '/tts-configs',
              element: <TtsConfig />,
            },
            {
              path: '/voice-agent-configs',
              element: <VoiceAgentConfig />,
            },
            ...testingToolRoutes,
            {
              path: '*',
              element: <NotFoundPage />,
            },
          ],
        },
      ],
    },
    {
      path: 'sign-in',
      element: <UnauthorizedPageLayout />,
      children: [
        {
          index: true,
          element: <SignInPage />,
        },
        {
          path: 'factor-one',
          element: <SignInPage />,
        },
      ],
    },
  ],
}]);

const Router = () => (
  <RouterProvider router={router} />
);

export default Router;
