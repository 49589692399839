import {
  Button,
  Card,
  Flex,
  Pagination,
  Table,
  Typography,
} from 'antd';
import { CloseOutlined, ReloadOutlined } from '@ant-design/icons';
import useSearchDebounce from '@marrlab-app-shared/hooks/state/useSearchDebounce';
import FiltersPanel from '@marrlab-app-shared/components/filtersPanel';

import Search from './components/search';
import CreateReferenceDataFieldProvider from './providers/createReferenceDataFieldProvider';
import DeleteReferenceDataFieldProvider from './providers/deleteReferenceDataFieldProvider';
import CreateReferenceDataFieldButton from './components/createReferenceDataFieldButton';
import CampaignFilter from './components/campaignFilter';

import usePagination from '../../hooks/table/usePagination';
import useReferenceDataFieldData from './hooks/useReferenceDataFieldData';
import useSorting from './hooks/useSorting';
import useTableConfiguration from './hooks/useTableConfiguration';
import useFilters from './hooks/useFilters';
import { idColumnKey } from './constants/columnsInitialState';

import './index.scss';

const ReferenceDataFields = () => {
  const {
    columns,
    sticky,
    scroll,
    setColumns,
  } = useTableConfiguration();
  const {
    skip,
    page,
    pageSize,
    resetPage,
    onPageChange,
    showTotal,
  } = usePagination();
  const { search, updateSearch } = useSearchDebounce({ onDebounce: resetPage });
  const { sortBy, sortDirection, onSortChange } = useSorting({ setColumns });
  const { filters, clearFilters, onFiltersUpdate } = useFilters({ resetPage });
  const { data, loading, fetchReferenceDataFields } = useReferenceDataFieldData({
    skip,
    take: pageSize,
    sortBy,
    order: sortDirection,
    search,
    ...filters,
  });

  return (
    <DeleteReferenceDataFieldProvider fetchReferenceDataFields={fetchReferenceDataFields}>
      <CreateReferenceDataFieldProvider fetchReferenceDataFields={fetchReferenceDataFields}>
        <Card
          title={(
            <Typography.Title
              level={4}
              className="reference-data-fields-title"
            >
              Reference data fields
            </Typography.Title>
          )}
          className="bg-white rounded shadow-lg"
        >
          <FiltersPanel
            defaultOpen
            search={(
              <Flex
                gap={16}
                justify="flex-start"
              >
                <Search updateSearch={updateSearch} />
                <Button
                  icon={<ReloadOutlined />}
                  onClick={fetchReferenceDataFields}
                >
                  Reload
                </Button>
                <CreateReferenceDataFieldButton />
              </Flex>
            )}
          >
            <Flex gap={16} vertical>
              <Flex gap={16} wrap="wrap" justify="space-between">
                <CampaignFilter filters={filters} onFiltersUpdate={onFiltersUpdate} />
                <Button
                  danger
                  onClick={clearFilters}
                  icon={<CloseOutlined />}
                >
                  Clear filters
                </Button>
              </Flex>
            </Flex>
          </FiltersPanel>
          <div className="table-wrapper">
            <Table
              rowKey={idColumnKey}
              dataSource={data?.data}
              columns={columns}
              loading={loading}
              scroll={scroll}
              pagination={false}
              sticky={sticky}
              onChange={onSortChange}
            />
          </div>
          <Flex justify="flex-end">
            <Pagination
              hideOnSinglePage
              className="mt-4"
              total={data?.total}
              showTotal={showTotal}
              pageSize={pageSize}
              current={page}
              showSizeChanger
              onChange={onPageChange}
              disabled={loading}
            />
          </Flex>
        </Card>
      </CreateReferenceDataFieldProvider>
    </DeleteReferenceDataFieldProvider>
  );
};

export default ReferenceDataFields;
