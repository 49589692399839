import { useCallback, useState } from 'react';
import {
  Button,
  Popconfirm,
  Tooltip,
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import DeleteReferenceDataProvider from '../../providers/deleteReferenceDataProvider';
import { ReferenceData } from '../../../../api/referenceData/types';

interface DeleteReferenceDataActionProps {
  referenceData: ReferenceData;
}

const DeleteReferenceDataAction = ({ referenceData }: DeleteReferenceDataActionProps) => {
  const { loading, onDeleteReferenceData } = DeleteReferenceDataProvider.useDeleteReferenceData();

  const [open, setOpen] = useState(false);

  const showConfirmation = useCallback(() => setOpen(true), []);

  const onConfirm = useCallback(async () => {
    await onDeleteReferenceData(referenceData.id);

    setOpen(false);
  }, [referenceData.id, onDeleteReferenceData]);

  const onCancel = useCallback(() => setOpen(false), []);

  return (
    <Popconfirm
      title={`Delete the reference data #${referenceData.id}`}
      description="Are you sure to delete the reference data?"
      open={open}
      onConfirm={onConfirm}
      okButtonProps={{ loading }}
      onCancel={onCancel}
    >
      <Tooltip title="Delete reference data">
        <Button
          type="primary"
          ghost
          icon={<DeleteOutlined />}
          onClick={showConfirmation}
        />
      </Tooltip>
    </Popconfirm>
  );
};

export default DeleteReferenceDataAction;
