import { Modal, Form } from 'antd';
import ModalFooter from '@marrlab-app-shared/components/modalFooter';

import NameField from './components/nameField';
import CampaignField from './components/campaignField';
import ChoicesField from './components/choicesField';
import TypeField from './components/typeField';

import useCreateReferenceDataFieldModalLogic from './hooks/useCreateReferenceDataFieldModalLogic';
import useCreateReferenceDataFieldModalState from './hooks/useCreateReferenceDataFieldModalState';
import useCreateReferenceDataFieldModalValidation from './hooks/useCreateReferenceDataFieldModalValidation';
import { CreateReferenceDataFieldParams, ReferenceDataField } from '../../../../api/referenceDataFields/types';

interface CreateReferenceDataFieldModalProps {
  isOpen: boolean;
  initialReferenceDataField: ReferenceDataField | null;
  onConfirm: (params: CreateReferenceDataFieldParams) => Promise<void>;
  onCancel: () => void;
}

const CreateReferenceDataFieldModal = ({
  isOpen,
  initialReferenceDataField,
  onConfirm: onConfirmBase,
  onCancel,
}: CreateReferenceDataFieldModalProps) => {
  const {
    errors,
    isValid,
    clearErrorForField,
  } = useCreateReferenceDataFieldModalValidation({ isOpen });

  const {
    isEdit,
    state,
    onPropertyUpdate,
  } = useCreateReferenceDataFieldModalState({
    isOpen,
    initialReferenceDataField,
    clearErrorForField,
  });

  const { saving, onConfirm } = useCreateReferenceDataFieldModalLogic({
    state,
    isValid,
    onConfirm: onConfirmBase,
  });

  return (
    <Modal
      title={isEdit
        ? `Edit reference data field #${initialReferenceDataField?.referenceFieldId}`
        : 'Create reference data field'}
      open={isOpen}
      onOk={onConfirm}
      onCancel={onCancel}
      width="600px"
      footer={(
        <ModalFooter
          disabled={saving}
          saveButtonLabel={isEdit ? 'Save' : 'Create'}
          onCancel={onCancel}
          onConfirm={onConfirm}
        />
      )}
    >
      <Form
        layout="vertical"
        className="py-4"
      >
        <NameField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={saving}
          error={errors.name}
        />
        <CampaignField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={saving}
          error={errors.campaignId}
        />
        <TypeField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={saving}
          error={errors.type}
        />
        <ChoicesField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={saving}
        />
      </Form>
    </Modal>
  );
};

export default CreateReferenceDataFieldModal;
