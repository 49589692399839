import { useCallback } from 'react';
import {
  Flex,
  Select,
  Typography,
} from 'antd';

import { FilterProps } from '../../types';

const options = [
  {
    value: true,
    label: 'Exist',
  },
  {
    value: false,
    label: 'Empty',
  },
];

const FunctionToolsFilter = ({ filters, onFiltersUpdate }: FilterProps) => {
  const onChange = useCallback((value?: boolean) => {
    onFiltersUpdate('functionTools', value);
  }, [onFiltersUpdate]);

  return (
    <Flex vertical gap={8} className="min-w-48">
      <Typography.Text>Function tools</Typography.Text>
      <Select
        placeholder="All"
        value={filters.functionTools}
        onChange={onChange}
        options={options}
        allowClear
      />
    </Flex>
  );
};

export default FunctionToolsFilter;
