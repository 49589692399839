import { useCallback, useEffect, useState } from 'react';
import { message } from 'antd';
import { AxiosError } from 'axios';
import { Page } from '@marrlab-app-shared/types';

import { ReferenceDataField, GetReferenceDataFieldsParams } from '../../../api/referenceDataFields/types';
import { getReferenceDataFields } from '../../../api/referenceDataFields';

const useReferenceDataFieldData = ({
  skip,
  take,
  sortBy,
  order,
  search,
  campaign,
}: GetReferenceDataFieldsParams) => {
  const [data, setData] = useState<Page<ReferenceDataField> | null>(null);
  const [loading, setLoading] = useState(false);

  const fetchReferenceDataFields = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getReferenceDataFields({
        skip,
        take,
        sortBy,
        order,
        search,
        campaign,
      });

      setData(response.data);
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        message.open({ content: error.message, type: 'error', duration: 10 });
      }
    }

    setLoading(false);
  }, [campaign, order, search, skip, sortBy, take]);

  useEffect(() => {
    fetchReferenceDataFields();
  }, [fetchReferenceDataFields]);

  return {
    data,
    loading,
    fetchReferenceDataFields,
  };
};

export default useReferenceDataFieldData;
