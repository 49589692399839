import axios from 'axios';
import { Page } from '@marrlab-app-shared/types';

import {
  AsrConfig,
  CreateAsrConfigParams,
  GetAsrConfigParams,
} from './types';
import config from '../../utils/config';

export const getAsrConfigs = async (params: GetAsrConfigParams) => (
  axios.get<Page<AsrConfig>>(`${config.CALLS_HISTORY_API_BASE_URL}/api/asr-config/`, { params })
);

export const createAsrConfig = async (params: CreateAsrConfigParams) => (
  axios.post<AsrConfig>(`${config.CALLS_HISTORY_API_BASE_URL}/api/asr-config`, params)
);

export const editAsrConfig = async (id: number, params: CreateAsrConfigParams) => (
  axios.put<AsrConfig>(`${config.CALLS_HISTORY_API_BASE_URL}/api/asr-config/${id}`, params)
);
