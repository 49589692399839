import {
  useCallback,
  useEffect,
  useState,
} from 'react';

import { getHumanTemplateModalInitialState } from '../mappers';
import { CreateHumanTemplateParams, HumanTemplate } from '../../../../../api/humanTemplates/types';

interface UseCreateHumanTemplateModalStateArgs {
  clearErrorForField: (propName: string) => void;
  initialHumanTemplate: HumanTemplate | null;
  isOpen: boolean;
}

const useCreateHumanTemplateModalState = ({
  isOpen,
  initialHumanTemplate,
  clearErrorForField,
}: UseCreateHumanTemplateModalStateArgs) => {
  const [state, setState] = useState<CreateHumanTemplateParams>(
    getHumanTemplateModalInitialState(),
  );

  const onPropertyUpdate = useCallback((
    propName: keyof CreateHumanTemplateParams,
    value: CreateHumanTemplateParams[keyof CreateHumanTemplateParams],
  ) => {
    setState((oldState) => ({ ...oldState, [propName]: value }));
    clearErrorForField(propName);
  }, [clearErrorForField]);

  useEffect(() => {
    setState(getHumanTemplateModalInitialState(isOpen ? initialHumanTemplate : null));
  }, [initialHumanTemplate, isOpen]);

  const isEdit = !!initialHumanTemplate;

  return {
    isEdit,
    state,
    onPropertyUpdate,
  };
};

export default useCreateHumanTemplateModalState;
