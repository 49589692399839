import { useCallback, useState } from 'react';
import { Button, Popconfirm, Tooltip } from 'antd';
import { BulbOutlined } from '@ant-design/icons';

import { AgentProvider, OrchestratorProviderTypes } from '../../../../api/agentProviders/types';
import DeployAgentProvider from '../../providers/deployAgentProvider';

interface DeployAgentActionProps {
  agent: AgentProvider;
}

const DeployAgentAction = ({ agent }: DeployAgentActionProps) => {
  const { loading, onDeployAgent } = DeployAgentProvider.useDeployAgent();

  const [open, setOpen] = useState(false);

  const isInactive = !agent.active;
  const hasNumberAssigned = !!agent.orchestratorNumberAssignment;
  const isVocode = agent.orchestratorProvider?.providerType === OrchestratorProviderTypes.vocode;

  const isDisabled = isInactive || !hasNumberAssigned || !isVocode;

  let tooltip = 'Deploy agent';

  if (isInactive) {
    tooltip = 'The agent marked as archived';
  }

  if (!hasNumberAssigned) {
    tooltip = 'Agent cannot be deploy without number assigned';
  }

  if (!isVocode) {
    tooltip = 'Retell agents cannot be deployed';
  }

  const showConfirmation = useCallback(() => setOpen(true), []);

  const onConfirm = useCallback(async () => {
    if (agent.orchestratorNumberAssignment?.orchestratorNumberAssignmentId) {
      const { orchestratorNumberAssignmentId } = agent.orchestratorNumberAssignment;

      await onDeployAgent({
        orchestratorNumberAssignmentId,
        deploy: true,
      });
    }

    setOpen(false);
  }, [agent.orchestratorNumberAssignment, onDeployAgent]);

  const onCancel = useCallback(() => setOpen(false), []);

  return (
    <Popconfirm
      title={`Deploy the agent #${agent.agentProviderId}`}
      description="Are you sure you want to deploy the agent?"
      open={open}
      onConfirm={onConfirm}
      okButtonProps={{ loading }}
      onCancel={onCancel}
      disabled={isDisabled}
    >
      <Tooltip title={tooltip}>
        <Button
          type="primary"
          ghost
          icon={<BulbOutlined />}
          size="middle"
          onClick={showConfirmation}
          disabled={isDisabled}
        />
      </Tooltip>
    </Popconfirm>
  );
};

export default DeployAgentAction;
