import { Flex } from 'antd';

import EditCampaignAction from '../editCampaignAction';
import DeleteCampaignAction from '../deleteCampaignAction';
import { Campaign } from '../../../../api/campaigns/types';

interface ActionsCellProps {
  campaign: Campaign;
}

const ActionsCell = ({ campaign }: ActionsCellProps) => (
  <Flex gap={8}>
    <EditCampaignAction campaign={campaign} />
    <DeleteCampaignAction campaign={campaign} />
  </Flex>
);

export default ActionsCell;
