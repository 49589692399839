import { useCallback } from 'react';
import { Form, Select } from 'antd';

import { FieldProps } from '../../types';

const options = [
  {
    value: '2024-02-15-preview',
    label: '2024-02-15-preview',
  },
  {
    value: '2024-03-01-preview',
    label: '2024-03-01-preview',
  },
  {
    value: '2024-02-01',
    label: '2024-02-01',
  },
];

const ApiVersionField = ({
  state,
  disabled,
  onUpdate,
  error,
}: FieldProps) => {
  const onChange = useCallback((value?: string) => {
    onUpdate('apiVersion', value);
  }, [onUpdate]);

  return (
    <Form.Item
      label="API version"
      required
      validateStatus={error ? 'error' : undefined}
      help={error}
    >
      <Select
        value={state.apiVersion}
        onChange={onChange}
        options={options}
        disabled={disabled}
      />
    </Form.Item>
  );
};

export default ApiVersionField;
