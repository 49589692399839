import { useCallback, useState } from 'react';

import {
  isEmptyArray,
  isEmptyNumber,
  isEmptyString,
} from '../../../utils/validators';
import { CreatePersonaModalState, PlaceholderValuesRawError } from '../types';
import { validatePlaceholderValues } from '../utils/validators';
import { Placeholder } from '../../../api/common/types';

type Errors = Partial<Record<keyof Omit<CreatePersonaModalState, 'placeholderValuesRaw'>, string>> & {
  placeholderValuesRaw?: PlaceholderValuesRawError;
};

interface UseCreatePersonaModalValidation {
  placeholders: Placeholder[];
}

const initialState: Errors = {};

const useCreatePersonaModalValidation = ({
  placeholders,
}: UseCreatePersonaModalValidation) => {
  const [errors, setErrors] = useState<Errors>(initialState);

  const isValid = useCallback((state: CreatePersonaModalState) => {
    const newState: Errors = {
      personaName: isEmptyString(state.personaName),
      campaignId: isEmptyString(state.campaignId.toString()),
      templateId: isEmptyNumber(state.templateId ?? undefined),
      questionIds: isEmptyArray(state.questionIds),
      placeholderValuesRaw: validatePlaceholderValues(placeholders, state.placeholderValuesRaw),
    };

    setErrors(newState);

    return Object.values(newState).every((field) => !field);
  }, [placeholders]);

  const clearErrorForField = useCallback((propName: string) => {
    setErrors((state) => ({
      ...state,
      [propName]: '',
    }));
  }, []);

  return {
    errors,
    isValid,
    clearErrorForField,
  };
};

export default useCreatePersonaModalValidation;
