import { CreateTtsConfigParams, TtsConfig } from '../../../../../api/ttsConfigs/types';
import { CreateTtsConfigModalState } from '../types';

export const trimCreateTtsConfigParams = (
  params: CreateTtsConfigModalState,
): CreateTtsConfigParams => ({
  shortName: params.shortName?.trim(),
  config: params.config ? JSON.stringify(params.config) : undefined,
});

export const getCreateModalInitialState = (
  initialTtsConfig?: TtsConfig | null,
): CreateTtsConfigModalState => {
  if (!initialTtsConfig) {
    return {
      shortName: '',
      config: {},
    };
  }

  return {
    shortName: initialTtsConfig.shortName,
    config: initialTtsConfig.config,
  };
};
