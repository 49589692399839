import { Flex } from 'antd';

import EditVoiceAgentConfigAction from '../editVoiceAgentConfigAction';
import { VoiceAgentConfig } from '../../../../api/voiceAgentConfigs/types';

interface ActionsCellProps {
  voiceAgentConfig: VoiceAgentConfig;
}

const ActionsCell = ({ voiceAgentConfig }: ActionsCellProps) => (
  <Flex justify="flex-end">
    <EditVoiceAgentConfigAction voiceAgentConfig={voiceAgentConfig} />
  </Flex>
);

export default ActionsCell;
