import { Flex } from 'antd';

import EditAsrConfigAction from '../editAsrConfigAction';
import { AsrConfig } from '../../../../api/asrConfigs/types';

interface ActionsCellProps {
  asrConfig: AsrConfig;
}

const ActionsCell = ({ asrConfig }: ActionsCellProps) => (
  <Flex justify="flex-end">
    <EditAsrConfigAction asrConfig={asrConfig} />
  </Flex>
);

export default ActionsCell;
