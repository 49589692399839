import { useCallback, useEffect, useState } from 'react';
import { isEmptyString } from '../../../../../utils/validators';

interface Errors {
  agentName: string;
}

interface UseDuplicateAgentModalValidationArgs {
  isOpen: boolean;
}

const initialState: Errors = {
  agentName: '',
};

const useDuplicateAgentModalValidation = ({ isOpen }: UseDuplicateAgentModalValidationArgs) => {
  const [errors, setErrors] = useState<Errors>(initialState);

  const isValid = useCallback((agentName: string) => {
    const newState = {
      agentName: isEmptyString(agentName),
    };

    setErrors(newState);

    return Object.values(newState).every((field) => !field);
  }, []);

  const clearErrorForField = useCallback((propName: string) => {
    setErrors((state) => ({
      ...state,
      [propName]: '',
    }));
  }, []);

  useEffect(() => {
    if (!isOpen) {
      setErrors(initialState);
    }
  }, [isOpen]);

  return {
    errors,
    isValid,
    clearErrorForField,
  };
};

export default useDuplicateAgentModalValidation;
