import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import config from '../config';

const configureSentry = () => {
  if (window.location.hostname === 'localhost') {
    return;
  }

  Sentry.init({
    dsn: 'https://bcc6ee25403ef4532859d5ed1779f178@o4507233939947520.ingest.us.sentry.io/4507234137079808',
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: config.MODE,
    initialScope: {
      tags: {
        source: 'frontend',
        app: 'vox-configuration',
      },
    },
  });
};

export default configureSentry;
