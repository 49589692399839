import { useCallback, useState } from 'react';
import { Button, Popconfirm, Tooltip } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import { Customer } from '../../../../api/customers/types';
import DeleteCustomerProvider from '../../providers/deleteCustomerProvider';

interface DeleteCustomerActionProps {
  customer: Customer;
}

const DeleteCustomerAction = ({ customer }: DeleteCustomerActionProps) => {
  const { loading, onDeleteCustomer } = DeleteCustomerProvider.useDeleteCustomer();

  const [open, setOpen] = useState(false);

  const isInactive = !customer.active;

  let tooltip = 'Archive customer';

  if (isInactive) {
    tooltip = 'The customer marked as archived';
  }

  const showConfirmation = useCallback(() => setOpen(true), []);

  const onConfirm = useCallback(async () => {
    await onDeleteCustomer(customer.customerId);

    setOpen(false);
  }, [customer.customerId, onDeleteCustomer]);

  const onCancel = useCallback(() => setOpen(false), []);

  return (
    <Popconfirm
      title={`Archive the customer #${customer.customerId}`}
      description="Are you sure to archive the customer?"
      open={open}
      onConfirm={onConfirm}
      okButtonProps={{ loading }}
      onCancel={onCancel}
      disabled={isInactive}
    >
      <Tooltip title={tooltip}>
        <Button
          type="primary"
          ghost
          icon={<DeleteOutlined />}
          onClick={showConfirmation}
          disabled={isInactive}
        />
      </Tooltip>
    </Popconfirm>
  );
};

export default DeleteCustomerAction;
