import { CreateCustomerParams, Customer } from '../../../../../api/customers/types';

export const trimCreateCustomerParams = (params: CreateCustomerParams): CreateCustomerParams => ({
  name: params.name?.trim(),
  shortName: params.shortName?.trim(),
});

export const getCreateModalInitialState = (
  initialCustomer?: Customer | null,
): CreateCustomerParams => {
  if (!initialCustomer) {
    return {
      name: '',
      shortName: '',
    };
  }

  return {
    name: initialCustomer.name,
    shortName: initialCustomer.shortName,
  };
};
