import { useCallback } from 'react';
import { Form, InputNumber } from 'antd';

import { FieldProps } from '../../types';

const ReminderTriggerField = ({
  state,
  disabled,
  onUpdate,
}: FieldProps) => {
  const onChange = useCallback((value: number | null) => {
    onUpdate('reminderTrigger', value ?? undefined);
  }, [onUpdate]);

  return (
    <Form.Item
      className="field-with-info-tooltip"
      label="Reminder trigger"
      tooltip="If set (in milliseconds), will trigger a reminder to the agent to speak if the user has been silent for the specified duration after some agent speech. Must be a positive number. If unset, default value of 10000 ms (10 s) will apply."
    >
      <InputNumber
        placeholder="10000"
        value={state.reminderTrigger}
        onChange={onChange}
        disabled={disabled}
        addonAfter="ms"
        min={0}
        step={1000}
      />
    </Form.Item>
  );
};

export default ReminderTriggerField;
