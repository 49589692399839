import { Flex, Spin } from 'antd';

import NoAgentState from './components/noAgentState';
import CreateAgentProvider from '../agentProviders/providers/createAgentProvider';
import ManagePersonaProvider from '../../pages/personaManagement/providers/managePersonaProvider';
import DeleteAgentProvider from '../agentProviders/providers/deleteAgentProvider';
import AgentDetails from './components/agentDetails';
import AssignNumberProvider from '../agentProviders/providers/assignNumberProvider';
import UnassignNumberProvider from '../agentProviders/providers/unassignNumberProvider';

import { useAgentData } from './hooks/useAgentData';

const HumanAgentSection = () => {
  const { persona } = ManagePersonaProvider.useManagePersona();
  const { data, loading, refetch } = useAgentData({ personaId: persona?.personaId });

  return (
    <AssignNumberProvider fetchAgentProviders={refetch}>
      <UnassignNumberProvider fetchAgentProviders={refetch}>
        <DeleteAgentProvider fetchAgentProviders={refetch}>
          <CreateAgentProvider fetchAgentProviders={refetch}>
            {loading && (
              <Flex
                justify="center"
                align="center"
                className="h-20"
              >
                <Spin />
              </Flex>
            )}
            {!loading && !data && (
              <NoAgentState
                persona={persona}
              />
            )}
            {!loading && data && (
              <AgentDetails
                agent={data}
              />
            )}
          </CreateAgentProvider>
        </DeleteAgentProvider>
      </UnassignNumberProvider>
    </AssignNumberProvider>
  );
};

export default HumanAgentSection;
