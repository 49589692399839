import {
  Button,
  Card,
  Flex,
  Pagination,
  Table,
  Typography,
} from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import useSearchDebounce from '@marrlab-app-shared/hooks/state/useSearchDebounce';

import Search from './components/search';
import CreateVoiceAgentConfigProvider from './providers/createVoiceAgentConfigProvider';
import CreateVoiceAgentConfigButton from './components/createVoiceAgentConfigButton';

import usePagination from '../../hooks/table/usePagination';
import useVoiceAgentConfigsData from './hooks/useVoiceAgentConfigsData';
import useSorting from './hooks/useSorting';
import useTableConfiguration from './hooks/useTableConfiguration';
import { idColumnKey } from './constants/columnsInitialState';

import './index.scss';

const VoiceAgentConfigs = () => {
  const {
    columns,
    sticky,
    scroll,
    setColumns,
  } = useTableConfiguration();
  const {
    skip,
    page,
    pageSize,
    resetPage,
    onPageChange,
    showTotal,
  } = usePagination();
  const { search, updateSearch } = useSearchDebounce({ onDebounce: resetPage });
  const { sortBy, sortDirection, onSortChange } = useSorting({ setColumns });
  const { data, loading, fetchVoiceAgentConfigs } = useVoiceAgentConfigsData({
    skip,
    take: pageSize,
    sortBy,
    order: sortDirection,
    search,
  });

  return (
    <CreateVoiceAgentConfigProvider fetchVoiceAgentConfigs={fetchVoiceAgentConfigs}>
      <Card
        title={(
          <Typography.Title
            level={4}
            className="voice-agent-configs-title"
          >
            Voice agent configs
          </Typography.Title>
        )}
        className="bg-white rounded shadow-lg"
      >
        <Flex className="mb-4" gap={16}>
          <Search updateSearch={updateSearch} />
          <Button
            className="mr-auto"
            icon={<ReloadOutlined />}
            onClick={fetchVoiceAgentConfigs}
          >
            Reload
          </Button>
          <CreateVoiceAgentConfigButton />
        </Flex>
        <div className="table-wrapper">
          <Table
            rowKey={idColumnKey}
            dataSource={data?.data}
            columns={columns}
            loading={loading}
            scroll={scroll}
            pagination={false}
            sticky={sticky}
            onChange={onSortChange}
          />
        </div>
        <Flex justify="flex-end">
          <Pagination
            hideOnSinglePage
            className="mt-4"
            total={data?.total}
            showTotal={showTotal}
            pageSize={pageSize}
            current={page}
            showSizeChanger
            onChange={onPageChange}
            disabled={loading}
          />
        </Flex>
      </Card>
    </CreateVoiceAgentConfigProvider>
  );
};

export default VoiceAgentConfigs;
