import { useCallback } from 'react';
import {
  Flex,
  Radio,
  Typography,
} from 'antd';

import { RadioChangeEvent } from 'antd/es/radio/interface';
import { FilterProps } from '../../types';

import './index.scss';

const options = [
  {
    value: undefined,
    label: 'All',
  },
  {
    value: false,
    label: 'Not deployed',
  },
  {
    value: true,
    label: 'Deployed',
  },
];

const DeployedFilter = ({ filters, onFiltersUpdate }: FilterProps) => {
  const onChange = useCallback((event?: RadioChangeEvent) => {
    onFiltersUpdate('deploy', event?.target.value);
  }, [onFiltersUpdate]);

  return (
    <Flex vertical gap={8} className="min-w-48">
      <Typography.Text>Deployed</Typography.Text>
      <Radio.Group
        className="deployed-filter"
        value={filters.deploy}
        onChange={onChange}
      >
        {options.map((option) => (
          <Radio.Button
            key={option.label}
            value={option.value}
          >
            {option.label}
          </Radio.Button>
        ))}
      </Radio.Group>
    </Flex>
  );
};

export default DeployedFilter;
