import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { AxiosError } from 'axios';
import { message, Typography } from 'antd';
import SuccessNotificationProvider from '@marrlab-app-shared/components/successNotification';

import CreateQuestionModal from '../../components/createQuestionModal';
import { CreateQuestionParams, Question } from '../../../../api/questions/types';
import { createQuestion, editQuestion } from '../../../../api/questions';
import { CreateQuestionDialogRelatedData } from '../../types';

interface CreateQuestionContextType {
  open: (relatedData?: CreateQuestionDialogRelatedData) => void;
  openOnEdit: (question: Question) => void;
}

const initialState: CreateQuestionContextType = {
  open: () => {
  },
  openOnEdit: () => {
  },
};

const CreateQuestionContext = createContext<CreateQuestionContextType>(initialState);

interface CreateQuestionContextProps extends PropsWithChildren {
  fetchQuestions?: () => Promise<void>;
  onConfirm?: (questionId: number) => void;
}

const CreateQuestionProvider = ({
  children,
  fetchQuestions,
  onConfirm: onConfirmBase,
}: CreateQuestionContextProps) => {
  const successNotification = SuccessNotificationProvider.useSuccessNotification();

  const [isOpen, setIsOpen] = useState(false);
  const [questionToEdit, setQuestionToEdit] = useState<Question | null>(null);
  const [relatedData, setRelatedData] = useState<CreateQuestionDialogRelatedData | null>(null);

  const close = useCallback(() => {
    setIsOpen(false);
    setQuestionToEdit(null);
    setRelatedData(null);
  }, []);

  const open = useCallback((relatedDataLocal?: CreateQuestionDialogRelatedData) => {
    setIsOpen(true);
    setRelatedData(relatedDataLocal ?? null);
  }, []);

  const openOnEdit = useCallback((question: Question) => {
    setQuestionToEdit(question);
    setIsOpen(true);
  }, []);

  const onConfirm = useCallback(async (params: CreateQuestionParams) => {
    try {
      const response = questionToEdit
        ? await editQuestion(questionToEdit.questionId, params)
        : await createQuestion(params);

      successNotification.open({
        key: response.data.questionId.toString(),
        title: questionToEdit ? 'Changes saved' : 'Question created successfully',
        message: (
          <>
            <Typography.Text strong>Question ID: </Typography.Text>
            <Typography.Text>{response.data.questionId}</Typography.Text>
            <br />
            <Typography.Text strong>Question: </Typography.Text>
            <Typography.Text>{response.data.question}</Typography.Text>
          </>
        ),
      });

      if (fetchQuestions) {
        await fetchQuestions();
      }

      if (onConfirmBase) {
        onConfirmBase(response.data.questionId);
      }

      close();
    } catch (error) {
      if (error instanceof AxiosError) {
        message.open({ content: error.response?.data?.message, type: 'error', duration: 10 });
      }
    }
  }, [questionToEdit, successNotification, fetchQuestions, onConfirmBase, close]);

  const value: CreateQuestionContextType = useMemo(
    () => ({
      open,
      openOnEdit,
    }),
    [open, openOnEdit],
  );

  return (
    <CreateQuestionContext.Provider value={value}>
      {children}
      <CreateQuestionModal
        isOpen={isOpen}
        initialQuestion={questionToEdit}
        relatedData={relatedData}
        onCancel={close}
        onConfirm={onConfirm}
      />
    </CreateQuestionContext.Provider>
  );
};

CreateQuestionProvider.useCreateQuestion = () => useContext(CreateQuestionContext);

export default CreateQuestionProvider;
