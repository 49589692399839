import { TableColumnsType } from 'antd';

import ActionsCell from '../components/actionsCell';
import { Question, QuestionFailValue } from '../../../api/questions/types';
import LongTextCell from '../../../components/longTextCell';

export const idColumnKey = 'questionId';

export const columnsInitialState: TableColumnsType<Question> = [
  {
    title: 'ID',
    dataIndex: idColumnKey,
    key: idColumnKey,
    fixed: 'left',
    sorter: true,
    sortOrder: 'descend',
    width: 80,
  },
  {
    title: 'Question text',
    dataIndex: 'question',
    key: 'question',
    sorter: true,
    width: 204,
    render: ((value: Question['question']) => (
      <LongTextCell
        value={value}
        title="Question text"
        width="w-lg"
      />
    )),
  },
  {
    title: 'Campaign name',
    dataIndex: 'campaignName',
    key: 'campaignName',
    width: 200,
    render: (_, record) => record.campaign.displayName,
  },
  {
    title: 'Fail value',
    dataIndex: 'failValue',
    key: 'failValue',
    sorter: true,
    width: 115,
    render: (value: Question['failValue']) => {
      if (value === QuestionFailValue.yes) {
        return 'Yes';
      }

      if (value === QuestionFailValue.no) {
        return 'No';
      }

      return 'Null';
    },
  },
  {
    title: 'Is info only',
    dataIndex: 'isInfoOnly',
    key: 'isInfoOnly',
    sorter: true,
    width: 125,
    render: (value: Question['isInfoOnly']) => (value ? 'Yes' : 'No'),
  },
  {
    title: 'Actions',
    width: 104,
    fixed: 'right',
    render: ((_, record) => <ActionsCell question={record} />),
  },
];
