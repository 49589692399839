export const extractPlaceholdersFromTemplate = (template: string): string[] => {
  const placeholderRegExp = /<(.*?)>/gi;

  const rawPlaceholders = [...template.matchAll(placeholderRegExp)].map((placeholder) => (
    placeholder[1].trim()
  ));

  return [...new Set(rawPlaceholders)];
};

export default extractPlaceholdersFromTemplate;
