import { ChangeEvent, useCallback } from 'react';
import { Form, Input } from 'antd';

import { FieldProps } from '../../types';

const LlmPromptField = ({
  state,
  disabled,
  onUpdate,
}: FieldProps) => {
  const onChange = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
    onUpdate('llmPrompt', event.target.value);
  }, [onUpdate]);

  return (
    <Form.Item label="LLM prompt">
      <Input.TextArea
        value={state.llmPrompt}
        onChange={onChange}
        disabled={disabled}
        autoSize={{ minRows: 2, maxRows: 8 }}
      />
    </Form.Item>
  );
};

export default LlmPromptField;
