import { ReferenceData } from '../../../../../api/referenceData/types';
import { Persona } from '../../../../../api/personas/types';
import { ReferenceDataField } from '../../../../../api/referenceDataFields/types';
import { CreateReferenceDataModalState } from '../types';

export const trimCreateReferenceDataParams = (
  params: CreateReferenceDataModalState,
): CreateReferenceDataModalState => (
  Object.values(params).reduce((acc, fieldState) => {
    acc[fieldState.referenceFieldId] = {
      personaId: fieldState.personaId,
      referenceFieldId: fieldState.referenceFieldId,
      referenceDataId: fieldState.referenceDataId,
      referenceData: fieldState.referenceData?.trim(),
    };

    return acc;
  }, {} as CreateReferenceDataModalState)
);

interface GetCreateModalInitialStateArgs {
  initialReferenceData?: ReferenceData | null,
  initialReferenceDataId?: number | null,
  persona?: Persona | null,
  referenceDataFields?: ReferenceDataField[] | null,
}

export const getCreateModalInitialState = ({
  initialReferenceData,
  initialReferenceDataId,
  referenceDataFields,
  persona,
}: GetCreateModalInitialStateArgs): CreateReferenceDataModalState => {
  if (!initialReferenceData) {
    if (!referenceDataFields) {
      return {};
    }

    return referenceDataFields.reduce((acc, field) => {
      acc[field.referenceFieldId] = {
        personaId: persona?.personaId ?? null,
        referenceFieldId: field.referenceFieldId,
        referenceDataId: initialReferenceDataId ? initialReferenceDataId + 1 : 1,
        referenceData: '',
      };

      return acc;
    }, {} as CreateReferenceDataModalState);
  }

  return {
    [initialReferenceData.referenceFieldId]: {
      personaId: initialReferenceData.personaId,
      referenceFieldId: initialReferenceData.referenceFieldId,
      referenceDataId: initialReferenceData.referenceDataId,
      referenceData: initialReferenceData.referenceData,
    },
  };
};
