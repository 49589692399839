import axios from 'axios';
import { Page } from '@marrlab-app-shared/types';

import {
  CreateReferenceDataParams,
  ReferenceData,
  GetReferenceDataParams,
} from './types';
import config from '../../utils/config';

export const getReferenceData = async (
  personaId: number,
  params: GetReferenceDataParams,
) => (
  axios.get<Page<ReferenceData>>(`${config.CALLS_HISTORY_API_BASE_URL}/api/reference-data/persona/${personaId}`, { params })
);

export const createReferenceData = async (params: CreateReferenceDataParams) => (
  axios.post<ReferenceData>(`${config.CALLS_HISTORY_API_BASE_URL}/api/reference-data`, params)
);

export const editReferenceData = async (id: number, params: CreateReferenceDataParams) => (
  axios.put<ReferenceData>(`${config.CALLS_HISTORY_API_BASE_URL}/api/reference-data/${id}`, params)
);

export const deleteReferenceData = async (id: number) => (
  axios.delete<void>(`${config.CALLS_HISTORY_API_BASE_URL}/api/reference-data/${id}`)
);
