import {
  useCallback,
  useEffect,
  useState,
} from 'react';

import { AgentProvider } from '../../../../../api/agentProviders/types';
import { getAssignNumberModalInitialState } from '../mappers';
import { SCROLLABLE_CONTAINER_ID } from '../constants';
import { AssignNumberModalState } from '../types';

interface UseAssignNumberModalStateArgs {
  clearErrorForField: (propName: string) => void;
  initialAgent: AgentProvider | null;
  isOpen: boolean;
}

const useAssignNumberModalState = ({
  isOpen,
  initialAgent,
  clearErrorForField,
}: UseAssignNumberModalStateArgs) => {
  const [state, setState] = useState<AssignNumberModalState>(getAssignNumberModalInitialState());

  const onPropertyUpdate = useCallback((
    propName: keyof AssignNumberModalState,
    value: AssignNumberModalState[keyof AssignNumberModalState],
  ) => {
    setState((oldState) => ({ ...oldState, [propName]: value }));
    clearErrorForField(propName);
  }, [clearErrorForField]);

  useEffect(() => {
    setState(getAssignNumberModalInitialState(isOpen ? initialAgent : null));

    if (!isOpen) {
      const scrollableContainer = document.getElementById(SCROLLABLE_CONTAINER_ID);
      scrollableContainer?.scrollTo({ top: 0 });
    }
  }, [initialAgent, isOpen]);

  return {
    state,
    onPropertyUpdate,
  };
};

export default useAssignNumberModalState;
