import { useCallback, useState } from 'react';
import { CampaignsFilters } from '../types';

interface UseFiltersProps {
  resetPage: () => void;
}

const initialFiltersState: CampaignsFilters = {
  status: 'active',
};

const useFilters = ({ resetPage }: UseFiltersProps) => {
  const [filters, setFilters] = useState<CampaignsFilters>(initialFiltersState);

  const onFiltersUpdate = useCallback((
    filterName: keyof CampaignsFilters,
    value: CampaignsFilters[keyof CampaignsFilters],
  ) => {
    resetPage();

    setFilters((state) => ({
      ...state,
      [filterName]: value,
    }));
  }, [resetPage]);

  const clearFilters = useCallback(() => {
    resetPage();
    setFilters(initialFiltersState);
  }, [resetPage]);

  return {
    filters,
    onFiltersUpdate,
    clearFilters,
  };
};

export default useFilters;
