import { CreateQuestionParams, Question } from '../../../../../api/questions/types';
import { CreateQuestionDialogRelatedData } from '../../../types';

export const trimCreateQuestionParams = (params: CreateQuestionParams): CreateQuestionParams => ({
  question: params.question?.trim(),
  isInfoOnly: params.isInfoOnly,
  failValue: params.failValue,
  campaignId: params.campaignId,
});

export const getCreateModalInitialState = (
  initialQuestion?: Question | null,
  relatedData?: CreateQuestionDialogRelatedData | null,
): CreateQuestionParams => {
  if (!initialQuestion) {
    return {
      question: '',
      failValue: null,
      isInfoOnly: false,
      campaignId: relatedData?.campaignId ?? '',
    };
  }

  return {
    question: initialQuestion.question,
    failValue: initialQuestion.failValue,
    isInfoOnly: initialQuestion.isInfoOnly,
    campaignId: initialQuestion.campaignId,
  };
};
