import { Form } from 'antd';
import { useCallback, useMemo } from 'react';
import ModalFooter from '@marrlab-app-shared/components/modalFooter';

import PersonaNameField from './components/personaNameField';
import TemplateField from './components/templateField';
import QuestionsField from './components/questionsField';
import PlaceholdersFields from './components/placeholdersFields';
import CreateQuestionProvider from '../questions/providers/createQuestionProvider';
import CampaignField from './components/campaignField';
import ManagePersonaProvider from '../../pages/personaManagement/providers/managePersonaProvider';
import PersonaDescriptionField from './components/personaDescriptionField';
import ExpandableText from '../../components/expandablePrompt';

import useCreatePersonaModalLogic from './hooks/useCreatePersonaModalLogic';
import useCreatePersonaModalState from './hooks/useCreatePersonaModalState';
import useNavigate from '../../hooks/navigation/useNavigate';
import { fillTemplateWithPlaceholders } from '../humanAgentSection/utils/persona';

import './index.scss';

const PersonaManagement = () => {
  const { navigateToPersonas } = useNavigate();
  const {
    persona: initialPersona,
    isNew,
  } = ManagePersonaProvider.useManagePersona();

  const {
    isEdit,
    loading,
    state,
    errors,
    placeholders,
    isValid,
    onPropertyUpdate,
  } = useCreatePersonaModalState({
    initialPersona,
  });

  const { saving, onConfirm } = useCreatePersonaModalLogic({
    state,
    isValid,
    initialPersona,
  });

  const llmPromptPreview = useMemo(() => (
    fillTemplateWithPlaceholders({
      template: state.templateText,
      placeholderValues: state.placeholderValuesRaw,
      placeholders,
    })
  ), [placeholders, state.placeholderValuesRaw, state.templateText]);

  const onCreateQuestionConfirm = useCallback((questionId: number) => {
    onPropertyUpdate('questionIds', [...state.questionIds, questionId]);
  }, [onPropertyUpdate, state.questionIds]);

  return (
    <CreateQuestionProvider onConfirm={onCreateQuestionConfirm}>
      <Form layout="vertical">
        <PersonaNameField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={saving}
          error={errors.personaName}
        />
        <PersonaDescriptionField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={saving}
        />
        <CampaignField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={saving || !isNew}
          error={errors.campaignId}
        />
        <TemplateField
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={saving || !isNew}
          error={errors.templateId}
        />
        <QuestionsField
          state={state}
          campaignId={state.campaignId}
          onUpdate={onPropertyUpdate}
          disabled={saving}
          error={errors.questionIds}
        />
        <PlaceholdersFields
          loading={loading}
          placeholders={placeholders}
          state={state}
          onUpdate={onPropertyUpdate}
          disabled={saving || !isNew}
          error={errors.placeholderValuesRaw}
        />
        <Form.Item label="LLM prompt preview">
          <ExpandableText>
            {llmPromptPreview}
          </ExpandableText>
        </Form.Item>
      </Form>
      <ModalFooter
        disabled={loading || saving}
        saveButtonLabel={isEdit ? 'Save' : 'Create'}
        onCancel={navigateToPersonas}
        onConfirm={onConfirm}
      />
    </CreateQuestionProvider>
  );
};

export default PersonaManagement;
