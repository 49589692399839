import { useCallback, useMemo } from 'react';
import {
  Empty,
  Flex,
  Select,
  Spin,
  Typography,
} from 'antd';
import useDataFetchWithSearch from '@marrlab-app-shared/hooks/data/useDataFetchWithSearch';

import { FilterProps } from '../../types';
import { getProviderTypesRequest } from '../../../../api/agentProviders';
import { emptyStringFilterValue } from '../../constants/columnsInitialState';

const ProviderTypeFilter = ({ filters, onFiltersUpdate }: FilterProps) => {
  const { updateSearch, loading, data } = useDataFetchWithSearch({
    request: getProviderTypesRequest,
  });

  const onChange = useCallback((value?: string) => {
    onFiltersUpdate('providerType', value);
  }, [onFiltersUpdate]);

  const options = useMemo(() => {
    const items = data?.map((item) => ({
      value: item.name,
      label: item.name,
    })) ?? [];

    items.push({
      value: emptyStringFilterValue,
      label: 'Empty',
    });

    return items;
  }, [data]);

  return (
    <Flex vertical gap={8} className="min-w-48">
      <Typography.Text>Provider type</Typography.Text>
      <Select
        placeholder="Any provider type"
        value={filters.providerType}
        onChange={onChange}
        onSearch={updateSearch}
        loading={loading}
        options={options}
        allowClear
        showSearch
        filterOption={false}
        notFoundContent={loading ? (
          <Flex justify="center" align="center" className="h-20">
            <Spin />
          </Flex>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      />
    </Flex>
  );
};

export default ProviderTypeFilter;
