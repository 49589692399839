import { Typography } from 'antd';

interface StatusCellProps {
  value?: boolean;
}

const StatusCell = ({ value }: StatusCellProps) => (
  <Typography.Text>
    {value ? 'Active' : 'Archived'}
  </Typography.Text>
);

export default StatusCell;
