import { useState } from 'react';
import { TableColumnsType, TableProps } from 'antd';

import { AgentProvider } from '../../../api/agentProviders/types';
import { columnsInitialState } from '../constants/columnsInitialState';
import { mapColumnsSettingsToColumns } from '../components/columnSettingsModal/utils/mappers';
import { getColumnsSettingsFromStorage } from '../utils/columnsSettingsPersistence';

const sticky: TableProps<AgentProvider>['sticky'] = { offsetHeader: 0 };

const scroll: TableProps<AgentProvider>['scroll'] = { x: 'max-content' };

const useTableConfiguration = () => {
  const [columns, setColumns] = useState<TableColumnsType<AgentProvider>>(() => (
    mapColumnsSettingsToColumns({
      columns: columnsInitialState,
      columnsSettings: getColumnsSettingsFromStorage(),
    })
  ));

  return {
    columns,
    sticky,
    scroll,
    setColumns,
  };
};

export default useTableConfiguration;
