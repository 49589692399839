import { useState } from 'react';
import { TableColumnsType, TableProps } from 'antd';

import { VoiceAgentConfig } from '../../../api/voiceAgentConfigs/types';
import { columnsInitialState } from '../constants/columnsInitialState';

const sticky: TableProps<VoiceAgentConfig>['sticky'] = { offsetHeader: 0 };

const scroll: TableProps<VoiceAgentConfig>['scroll'] = { x: 'max-content' };

const useTableConfiguration = () => {
  const [columns, setColumns] = useState<TableColumnsType<VoiceAgentConfig>>(columnsInitialState);

  return {
    columns,
    sticky,
    scroll,
    setColumns,
  };
};

export default useTableConfiguration;
