import { useCallback, useEffect, useState } from 'react';

import { CreateQuestionParams } from '../../../../../api/questions/types';
import { isEmptyString } from '../../../../../utils/validators';

type Errors = Partial<Record<keyof CreateQuestionParams, string>>;

interface UseCreateQuestionModalValidation {
  isOpen: boolean;
}

const initialState: Errors = {};

const useCreateQuestionModalValidation = ({ isOpen }: UseCreateQuestionModalValidation) => {
  const [errors, setErrors] = useState<Errors>(initialState);

  const isValid = useCallback((state: CreateQuestionParams) => {
    const newState = {
      question: isEmptyString(state.question),
      campaignId: isEmptyString(state.campaignId?.toString()),
    };

    setErrors(newState);

    return Object.values(newState).every((field) => !field);
  }, []);

  const clearErrorForField = useCallback((propName: string) => {
    setErrors((state) => ({
      ...state,
      [propName]: '',
    }));
  }, []);

  useEffect(() => {
    if (!isOpen) {
      setErrors(initialState);
    }
  }, [isOpen]);

  return {
    errors,
    isValid,
    clearErrorForField,
  };
};

export default useCreateQuestionModalValidation;
